import { TranslationsT } from './de'

const en: TranslationsT = {
    common: {
        next: 'Next',
        back: 'Back',
        cancel: 'Cancel',
        submit: 'Submit',
        go: 'Go',
        or: 'Or',
        logout: 'Logout',
        loading: 'Loading...',
        error: 'Something went wrong',
        copied: 'Copied!',
        language: 'Language',
        requestTypes: {
            all: 'All',
            warranty: 'Warranty',
            repair: 'Repair',
        },
    },
    admin: {
        tatajuba: {
            title: 'Service Desk',
        },
        newCompanyRepresentative: {
            title: 'Invite new company',
            companyNameLabel: 'Company name',
            companyRepresentativeNameLabel: 'Employee name',
            companyRepresentativeEmailLabel: 'Employee email',
            buttonLabel: 'Invite',
        },
        unverifiedUsers: {
            title: 'Unverified users',
            table: {
                company: 'Company',
                representative: 'Representative',
                email: 'E-Mail',
                link: 'Invitation link',
            },
        },
        companies: {
            title: 'Companies',
            table: {
                id: 'Id',
                company: 'Company',
                representative: 'Representative',
                representativeEmail: 'E-Mail',
                apps: 'Apps',
                productCount: 'Product count',
            },
        },
    },
    company: {
        onboarding: {
            form: {
                welcome: 'Welcome!',
                lead: 'We are glad to have you on board.',
                subLead: 'Please activate your account:',
                email: {
                    label: 'E-Mail',
                    placeholder: 'E-Mail',
                    helperText:
                        'Please use the e-mail address to which you received this invitation link.',
                },
                password: {
                    label: 'Password',
                    placeholder: 'Set a new, secure password',
                },
                requirements: {
                    title: 'Password requirements:',
                    length: 'At least 8 characters',
                    casing: 'Lower and upper case characters',
                    number: 'At least one number',
                    special: 'At least one special character',
                },
                action: 'Create account',
            },
            introduction: {
                title: 'Welcome to the Inspector Platform. Your journey towards enhanced product quality and enriched customer experiences starts here. We are glad to have you on board and look forward to supporting your success.',
                action: 'Let’s go',
            },
        },
        common: {
            filterbar: {
                search: {
                    label: 'Search',
                    placeholder:
                        "Search for e-mail, description or date (e.g. '11. november' oder 'january')",
                },
                unseen: {
                    showOnlyUnseen: 'Show only new Inspections',
                    showAll: 'Show all Inspections',
                },
                share: 'Share insights',
            },
        },
        inspector: {
            home: {
                products: {
                    title: 'Products',
                    action: 'Add new product',
                    productList: {
                        openFeatureTest: 'Feature Test',
                        openRainTest: 'Rain Test',
                        copyInspectorLink: {
                            copyDesktop: 'Copy Inspector-Link',
                            copyMobile: 'Inspector-Link',
                            copied: 'Inspector-Link copied',
                        },
                        deleteProduct: {
                            headline: 'Are you sure?',
                            confirmText:
                                'Do you really want to delete the product <strong>"{{productName}}"</strong> <strong>irrevocably</strong>? All data will be deleted and cannot be restored.',
                            cancel: 'Cancel',
                            delete: 'Delete',
                        },
                    },
                    newProduct: {
                        title: 'Add new product',
                        form: {
                            name: {
                                label: 'Product name',
                                required: 'Product name is required',
                            },
                            cancelButton: {
                                label: 'Cancel',
                            },
                            saveButton: {
                                label: 'Create product',
                                saving: 'Saving...',
                                validation: {
                                    nameRequired: 'Product name is required',
                                    imageRequired: 'Product image is required',
                                },
                            },
                            photoStep: {
                                title: 'Product image',
                                subtitle:
                                    'Take a photo of your product with your camera or upload one from your gallery.',
                                takePhotoButton: 'Take photo',
                                uploadPhotoButton: 'Upload photo',
                            },
                            infoStep: {
                                title: 'Product information',
                                subtitle: 'Please enter the name of your product.',
                                name: {
                                    label: 'Product name',
                                    placeholder: 'Product name',
                                },
                                brand: {
                                    label: 'Brand',
                                    placeholder: 'Brand',
                                },
                                gender: {
                                    label: 'Gender',
                                    men: 'Men',
                                    women: 'Women',
                                    diverse: 'Diverse',
                                },
                                age: {
                                    label: 'Age',
                                    placeholder: 'Age',
                                },
                                laminate: {
                                    label: 'Laminate',
                                    two: '2 Layer',
                                    three: '3 Layer',
                                    twoLiner: '2 + Liner',
                                    otter: 'Otter',
                                },
                            },
                            summaryStep: {
                                title: 'Summary',
                                subtitle:
                                    'Please check the information you have entered and confirm the creation of the product.',
                                loading:
                                    'The image is being uploaded and optimized. This may take a few seconds.',
                                unhappy: 'Not happy?',
                                useOriginalBtn: 'Use original image instead',
                                useOptimizedBtn: 'Use optimized image',
                                nextBtn: 'Create',
                            },
                        },
                    },
                },
                share: {
                    exportProductList: 'export product list',
                    printProductCards: 'print product cards',
                    orderCompanyCard: 'order company card',
                    copyCompanyLink: 'copy company overview link',
                    copyCompanySupportLink: 'copy company support link',
                    companyLinkCopied: 'link copied',
                },
                onboarding: {
                    title: 'Products',
                    action: 'Create product',
                },
            },
            productDashboard: {
                backToOverview: 'Back to overview',
                configuration: {
                    gender: 'Gender',
                    age: 'Age',
                    laminate: 'Laminate',
                },
                keyFacts: {
                    pageViews: 'Page views',
                    problems: 'Problems',
                    happySubmissionFeedback: 'Nothing to report',
                    conversion: 'Conversion',
                },
                noDescription: 'No description available',
                aiFilter: 'AI filters',
                originFilter: 'Origin',
                detail: {
                    defectDescription: 'Defect description',
                    contextDescription: 'Product usage',
                    contact: 'Contact e-mail for follow up:',
                },
                noSubmissions: {
                    title: 'No inspections yet',
                    subTitle:
                        'As soon as your customers have carried out an inspection, they will be displayed here.',
                    copyInspectionLink: 'Copy inspector link',
                    copied: 'Link copied',
                },
            },
        },
        report: {
            title: 'Inspections',
        },
        userManagement: {
            users: {
                title: 'Users',
                action: 'add new user',
                userList: {
                    subtitle: 'Products: {{ productCount }} | Inspections: {{ inspectionCount }}',
                },
            },
        },
        support: {
            supportRequests: {
                title: 'Support requests',
                kanban: {
                    open: 'Open',
                    inProgress: 'In Progress',
                    done: 'Done',
                },
                noSupportRequestsFallback: 'You don’t have any support requests yet. Good job!',
            },
        },
        companySettings: {
            account: {
                title: 'Account',
                newEmployeeForm: {
                    name: 'Name',
                    email: 'E-Mail',
                    button: 'Invite',
                },
                logoForm: {
                    title: 'Upload company logo',
                    helperText: 'Ideally a logo without distance to the border',
                    loading: 'File is being uploaded...',
                    success: 'Logo successfully uploaded',
                    failed: 'Something went wrong',
                },
            },
            settings: {
                title: 'Settings',
                basicSettings: {
                    photoOrigin: {
                        title: 'Photo origin',
                        lead: 'Should users only be able to take photos with the camera or also upload them from the gallery?',
                        cameraAndGallery: 'Camera and gallery possible',
                        cameraOnly: 'Only camera possible',
                    },
                    emailMandatory: {
                        title: 'E-Mail as mandatory field',
                        lead: 'Should users be required to provide their e-mail address or should this be optional?',
                        mandatory: 'E-Mail is a mandatory field',
                        optional: 'E-Mail is optional',
                    },
                    emailNotifications: {
                        title: 'E-Mail notifications',
                        lead: 'Do you want to receive e-mail notifications about new inspections?',
                        enabled: 'E-Mail notifications are enabled',
                        disabled: 'E-Mail notifications are disabled',
                    },
                },
                customerSettingsOnlyWarning:
                    'These settings are only available for customer version of the inspector app.',
                customerSettings: {
                    postInspectionRedirect: {
                        title: 'Redirect after inspection',
                        lead: 'Where should the user be redirected after an inspection?',
                        label: 'Redirect after inspection',
                        companyPage: 'Company page',
                        restart: 'Start screen',
                        companyPageValidation: {
                            required: 'Company page is required.',
                            invalid: 'Company page is invalid.',
                        },
                    },
                    allowHappySubmissions: {
                        title: 'Allow "Nothing to report"',
                        lead: 'Should users be able to save an inspection without a comment?',
                        enabled: '"Nothing to report" possible',
                        disabled: '"Nothing to report" not possible',
                    },
                    allowRemindMeLater: {
                        title: 'Allow "Remind me later"',
                        lead: 'Should users be able to request an e-mail reminder to submit an inspection later?',
                        enabled: '"Remind me later" possible',
                        disabled: '"Remind me later" not possible',
                    },
                    allowContactRequests: {
                        title: 'Allow contact requests',
                        lead: 'Should users be able to request contact from your company?',
                        enabled: 'Contact requests possible',
                        disabled: 'Contact requests not possible',
                    },
                    showDashboardAnalytics: {
                        title: 'Show dashboard analytics',
                        lead: 'Do you want to see analytics about your inspections in the dashboard?',
                        enabled: 'Dashboard analytics are enabled',
                        disabled: 'Dashboard analytics are disabled',
                    },
                },
                save: 'Save',
                saving: 'Saving...',
                success: 'Settings successfully saved',
                error: 'There was an error saving the settings',
            },
        },
    },
    publicCompany: {
        profile: {
            title: 'Product inspection',
            lead: 'Thank you for helping us making longer lasting products. Please choose the product you want to inspect below:',
            help: 'Help',
        },
        introCarousel: {
            title: 'Intro to Inspector',
            s1: {
                title: 'Choose product',
                lead: 'Choose the product you want to inspect.',
            },
            s2: {
                title: 'Mark problem area',
                lead: 'Mark the area where you found a defect or have a suggestion for improvement.',
            },
            s3: {
                title: 'Take photo',
                lead: 'Take a photo of the problem area.',
            },
            s4: {
                title: 'Describe problem',
                lead: 'Describe the defect or suggestion for improvement.',
            },
            s5: {
                title: 'Tell the story',
                lead: 'Tell us the story of your product and the problem.',
            },
        },
        support: {
            title: 'Repair request',
            lead: 'If something is wrong with your product, fill out this request so we can get in touch with you and find a solution together.',
        },
    },
    inspector: {
        desktop: {
            title: 'Please open the INSPECTION on your mobile device',
            description: 'Scan the QR code with your mobile device',
        },
        mobile: {
            navigation: {
                from: 'from',
                submit: 'Submit',
            },
            intro: {
                title: 'Product Inspection',
                description:
                    'If you have an improvement suggestion or defect on your product, conduct an inspection.',
                action: 'Start inspection',
                nothingToReport: {
                    action: 'nothing to report',
                },
            },
            pinpoint: {
                title: 'Mark problem area',
                description:
                    'Click on the corresponding area in the image to mark a <strong>suggestion for improvement</strong> or <strong>defect</strong>',
            },
            photo: {
                title: 'Take a photo of the problem',
                description: 'Take a <strong>photo</strong> of the area you just marked.',
                preview: 'Preview',
                action: 'Take photo',
                reAction: 'Take new photo',
                itemNotAvailable: 'Item not available at the moment',
                cancelled: {
                    description:
                        'You stopped recording. If it was because you saw black screen, it might be due to permissions. You can see how you can customize these at the following link.',
                    showMe: 'Show me how',
                    dismiss: 'close',
                },
                remindMeLater: {
                    title: 'Inspect product later',
                    lead: 'You don’t have the product with you? No problem. We‘ll send you a reminder mail to inspect it at a later point.',
                    emailLabel: 'E-Mail',
                    datetimeLabel: 'Time of reminder',
                    outro: 'Your e-mail address will only be used for this reminder.',
                    submit: 'Remind me later',
                    cancel: 'Cancel',
                },
            },
            description: {
                title: 'Describe the problem',
                description: 'Describe the <strong>defect</strong> of the product.',
                label: 'Description',
                placeholder: 'Problem description',
            },
            contextDescription: {
                title: 'More information',
                description:
                    'Describe the <strong>history</strong> of your <strong>product</strong> and <strong>problem</strong>.',
                label: 'Description',
                placeholder: 'Product / problem history',
            },
            contact: {
                title: 'Provide contact information',
                description:
                    'Please provide your E-Mail so we can contact you for further <strong>questions about your inspection</strong>',
                label: 'E-Mail',
                placeholder: 'E-Mail',
                requestContact: 'Please contact me with a solution for this issue',
                photoUploadLabel: 'Photo-Upload',
            },
            outro: {
                title: 'Thank you!',
            },
        },
    },
    support: {
        desktop: {
            title: 'Please open the repair request on your mobile device',
            description: 'Scan the QR code with your mobile device',
        },
        mobile: {
            navigation: {
                from: 'from',
                submit: 'Submit',
            },
            intro: {
                title: 'Repair Request',
                description: 'If you have an defect on your product, file a repair request.',
                action: 'Start request',
                nothingToReport: {
                    action: 'nothing to report',
                },
            },
            pinpoint: {
                title: 'Mark problem area',
                description:
                    'Click on the corresponding area in the image to mark the <strong>defect</strong>',
            },
            photo: {
                title: 'Take a photo of the problem',
                description: 'Take a <strong>photo</strong> of the area you just marked.',
                preview: 'Preview',
                action: 'Take photo',
                reAction: 'Take new photo',
                itemNotAvailable: 'Item not available at the moment',
                cancelled: {
                    description:
                        'You stopped recording. If it was because you saw black screen, it might be due to permissions. You can see how you can customize these at the following link.',
                    showMe: 'Show me how',
                    dismiss: 'close',
                },
                remindMeLater: {
                    title: 'Inspect product later',
                    lead: 'You don’t have the product with you? No problem. We‘ll send you a reminder mail to inspect it at a later point.',
                    emailLabel: 'E-Mail',
                    datetimeLabel: 'Time of reminder',
                    outro: 'Your e-mail address will only be used for this reminder.',
                    submit: 'Remind me later',
                    cancel: 'Cancel',
                },
            },
            description: {
                title: 'Describe the problem',
                description: 'Describe the <strong>defect</strong> of your product.',
                label: 'Description',
                placeholder: 'Problem description',
            },
            orderReference: {
                title: 'Enter your order reference',
                description: 'Please enter your <strong>order reference</strong>.',
                label: 'Order reference',
                placeholder: 'Order reference',
            },
            contact: {
                title: 'Provide contact information',
                description:
                    'Please provide your E-Mail so we can can get back to you <strong>about your repair request</strong>.',
                hint: 'Use the e-mail address you have used to purchase the product if possible.',
                label: 'E-Mail',
                placeholder: 'E-Mail',
                requestContact: 'Please contact me with a solution for this issue',
                photoUploadLabel: 'Photo-Upload',
            },
            outro: {
                title: 'Thank you!',
            },
        },
    },
    serviceDesk: {
        hub: {
            title: 'Self-Service Hub',
            services: {
                repair: {
                    title: 'Submit a Repair Order',
                    subtitle: 'Let your product be repaired',
                },
                warranty: {
                    title: 'Submit a Warranty Claim',
                    subtitle: 'Report a warranty claim for your product',
                },
                alteration: {
                    title: 'Submit an Alteration Order',
                    subtitle: 'Let your product be altered or extended',
                },
            },
        },
        repair: {
            productStep: {
                title: 'Which product do you need help with?',
                productNotFoundLabel: 'Product not found?',
                productNotFoundAction: 'Request a repair offer for a different product',
            },
            pinpointStep: {
                progressResetWarning: 'If you go back, all your entered data will be reset',
                backToOverview: 'Back to Start',
            },
            purchaseInformationStep: {
                title: 'Purchase Information',
            },
            costApprovalStep: {
                title: 'Your Repair',
                minCost: {
                    title: 'Minimum cost approval',
                    lead: 'For a smooth repair, we need your <strong>cost approval starting from {{ minCost }} €</strong>. Up to this amount, we will carry out the repair without further inquiry. For higher costs, we will send you a personal offer.',
                },
                includedServices: {
                    title: 'Repairs within the minimum cost approval:',
                    lead: 'These repairs are included in the minimum cost approval and would be carried out without inquiry if necessary.',
                },
                excludedServices: {
                    title: 'The fastest way to repair',
                    lead: 'You can increase the cost approval so that we can also carry out the following repairs directly, without further inquiry, if necessary.',
                },
                total: 'Your cost approval:',
                plusShipping: 'plus {{ shippingCosts }} € shipping costs',
                help: {
                    title: 'Cost approval',
                    content:
                        'A cost approval allows us to carry out the repair as quickly and smoothly as possible without further consultation. If the costs exceed your cost approval, we will contact you with a new offer before additional costs arise. If the costs are below your cost approval, we will only charge you the actual costs.',
                },
            },
            requestRepairOfferStep: {
                title: 'Summary',
                contactFields: {
                    title: 'Contact Details',
                },
                submitAction: 'Request Repair Offer',
            },
            submitRepairOrderStep: {
                title: 'Order Summary',
                repairCosts: {
                    title: 'Repair Costs',
                    costApproval: 'Cost Approval',
                    shippingCosts: 'Shipping Costs',
                    shippingCostsSubline: 'For round-trip shipping',
                    totalCosts: 'Maximum Costs',
                    totalCostsSubline:
                        '(for repairs that are carried out without further consultation)',
                },
                submitAction: 'Confirm Purchase',
            },
            solutionStep: {
                linktree: {
                    title: 'Repair Options',
                    diyTitle: 'Explore our DIY Solutions',
                    diySubtitle: 'Try fixing the issue yourself with our guides',
                    servicesTitle: 'Commission repair',
                    servicesSubtitle: 'Give a maximum cost approval for the upcoming repair',
                    contactFormTitle: 'Request a repair offer',
                    contactFormSubtitle: 'We will get back to you with an offer.',
                    storeFinderTitle: 'Service experts near you',
                    storeFinderSubtitle: 'Get on-site support from one of our service experts.',
                },
                waitingForPhotoUpload: 'Wait for the photo upload to complete...',
            },
            costEstimationStep: {
                title: 'Cost estimation',
                costRange: {
                    lead: 'The costs can vary depending on the extent of the repair and the condition of your product in the following range:',
                    lead_other:
                        'The costs can vary depending on the extent of the repair and the condition of your product in the following range:',
                },
                singleRepair: {
                    lead: 'Based on your information, the following repair costs are expected:',
                },
                costApproval: {
                    title: 'Cost Approval',
                    lead: 'Choose up to which price you want to approve the repair without asking us back, so we can process your request as quickly as possible:',
                },
                approvalCheckbox:
                    'I agree to repair costs of up to {{ maxPrice }} € and the repair terms.',
                diyAction: 'I prefer a DIY solution',
                repairAction: 'I want to repair my item',
                customerSupportAction: 'I want to contact the customer support',
            },
            contactStep: {
                title: 'Your Contact Details',
                formTitle: 'Enter your details',
                requestContactForm: {
                    submit: 'Request Repair Offer',
                },
                submitOrderForm: {
                    submit: 'Submit Repair Order',
                },
            },
            outroStep: {
                title: 'Thank you!',
                subtitle: 'We will respond to you with an offer soon.',
                subtitle2: 'Please bring your defect Kite to our Shop.',
                links: {
                    backToStore: 'Back to the Shop',
                },
            },
        },
        warranty: {
            productStep: {
                title: 'For which product do you want to report a warranty claim?',
                productNotFoundLabel: 'Product not found?',
                productNotFoundAction: 'Submit warranty request for a different product',
            },
            purchaseConfirmationStep: {
                linktree: {
                    title: 'Provide proof of purchase',
                    photoTitle: 'Upload photo of the receipt',
                    photoSubtitle: 'Attach a photo of the receipt',
                    manualTitle: 'Enter order number manually',
                    manualSubtitle: 'You can find this in your online order or on your receipt',
                    notAvailable: {
                        delimiter: 'Do you not have a proof of purchase?',
                        button: 'Proceed without proof of purchase',
                    },
                },
                photo: {
                    title: 'Upload photo of the receipt',
                },
                form: {
                    title: 'Enter proof of purchase',
                    orderReferenceLabel: 'Order number',
                    articleNumberLabel: 'Article number',
                    articleNumberWhereToFindTitle: 'Where can I find the article number?',
                },
            },
            purchaseInformationStep: {
                title: 'Purchase Information',
            },
            serialNumberStep: {
                title: 'Upload photo of the serial number',
            },
            proofOfPurchaseStep: {
                title: 'Upload photo of the receipt',
            },
            contactStep: {
                title: 'Your Contact Details',
                form: {
                    title: 'Enter your details',
                    submit: 'Report warranty case',
                },
            },
            solutionStep: {
                waitingForPhotoUpload: 'The photos are still being uploaded...',
            },
            outroStep: {
                title: 'Thank you!',
                subtitle: 'We will get in touch with you soon.',
            },
            requestWarrantyStep: {
                title: 'Summary',
                contactFields: {
                    title: 'Contact Details',
                },
                submitAction: 'Report warranty case',
            },
        },
        alteration: {
            productStep: {
                title: 'For which product do you want to request an alteration?',
                productNotFoundLabel: 'Product not found?',
                productNotFoundAction: 'Alteration request for a different product',
            },
            alterationSelectionStep: {
                title: 'Select Alteration(s)',
                total: 'Total',
            },
            contactStep: {
                title: 'Your Contact Details',
                form: {
                    title: 'Enter your details',
                    submit: 'Request Alteration',
                },
            },
            submitAlterationOrderStep: {
                title: 'Summary',
                alterationCosts: {
                    title: 'Alteration Costs',
                    totalCosts: 'Total Costs',
                },
                submitAction: 'Request Alteration',
            },
        },
        shared: {
            retailerValidationStep: {
                title: 'Enter retailer code',
            },
            productStep: {
                form: {
                    productReferenceLabel: 'Enter your item reference',
                },
                categories: {
                    all: 'All',
                    apparel: 'Apparel',
                    upperwear: 'Upperwear',
                    tshirts: 'T-Shirts',
                    tshirt: 'T-Shirt',
                    pullovers: 'Pullovers',
                    pullover: 'Pullover',
                    hoodie: 'Hoodie',
                    longsleeve: 'Longsleeve',
                    tanktop: 'Tanktop',
                    top: 'Top',
                    jackets: 'Jackets',
                    jacket: 'Jacket',
                    bikini: 'Bikini',
                    pants: 'Pants',
                    pant: 'Pants',
                    shorts: 'Shorts',
                    leggings: 'Leggings',
                    skiingPants: 'Skiing Pants',
                    skiingPant: 'Skiing Pants',
                    accessories: 'Accessories',
                    beanie: 'Beanie',
                    gloves: 'Gloves',
                    shoes: 'Shoes',
                    hikingShoes: 'Hiking Shoe',
                    snowBoots: 'Snow Boot',
                    sportShoes: 'Sport Shoe',
                    sneakers: 'Sneaker',
                    flipflop: 'Flip Flops',
                    hardware: 'Hardware',
                    camping: 'Camping',
                    tents: 'Tents',
                    tent: 'Tent',
                    campingChairs: 'Camping Chairs',
                    campingChair: 'Camping Chair',
                    mats: 'Mats and Mattresses',
                    mat: 'Mat or Mattress',
                    sleepingBags: 'Sleeping Bags',
                    sleepingBag: 'Sleeping Bag',
                    hiking: 'Hiking',
                    backpacks: 'Backpacks',
                    backpack: 'Backpack',
                    hikingPoles: 'Hiking Poles',
                    hikingPole: 'Hiking Pole',
                    watersport: 'Watersport',
                    sup: 'SUP',
                    penguin: {
                        menShellPants: 'Men Shell Pants',
                        womenShellPants: 'Women Shell Pants',
                        menShellJacket: 'Men Shell Jacket',
                        womenShellJacket: 'Women Shell Jacket',
                    },
                    bags: 'Bags',
                    shoulderBag: 'Shoulder Bag',
                    messengerBag: 'Messenger Bag',
                    laptopBag: 'Laptop Bag',
                    toteBag: 'Tote Bag',
                    freitagTravelBag: 'Freitag Travel Bag',
                    victorinox: {
                        toolsAndKnives: 'Tools & Knives',
                        watch: 'Watches',
                        pocketKnife: 'Pocket Knife',
                        multiTool: 'Swiss Tools',
                        luggage: 'Luggage',
                        knife: 'Kitchen Knife',
                        card: 'Swiss Cards',
                    },
                    ortlieb: {
                        bikeBag: 'Bike Bag',
                        travelBag: 'Travel Bag',
                        luggageRack: 'Luggage Rack',
                        backpack: 'Backpack',
                        handlebarBag: 'Handlebar Bag',
                        frameBag: 'Frame Bag',
                        saddleBag: 'Saddle Bag',
                        bikeBagBackFront: 'Bike Bag Back / Front',
                        luggageRackBag: 'Luggage Rack Bag',
                        bagWithRollClosure: 'with Roll Closure',
                        bagWithLid: 'with Lid',
                        officeBags: 'Office Bags',
                        bagWithQuickSeal: 'with Quickseal',
                        bagWithVelcroClosure: 'with Velcro Closure',
                        bagWithHookClosure: 'with Hook Closure',
                        bagWithBuckleClosure: 'with Buckle Closure',
                        bagWithMagneticClosure: 'with Magnetic Closure',
                        bagWithZipClosure: 'with Zip Closure',
                        seatlock: 'with Seatlock',
                        trunkBag: 'Trunkbag',
                        trunkBagRc: 'Trunkbag RC',
                        rackPack: 'Rackpack',
                        ultimate: 'Ultimate',
                        barista: 'Barista',
                        uptown: 'Uptown',
                        handlebarPackQR: 'Handlebar Pack QR',
                        handlebarPack: 'Handlebar Pack',
                        accessoryPack: 'Accessory Pack',
                        eGlow: 'E-Glow',
                        framepackToptube: 'Framepack Toptube',
                        framepack: 'Framepack',
                        framepackRc: 'Framepack RC',
                        framepackToptubeRc: 'Framepack Toptube RC',
                        duffles: 'Duffles',
                        bagWithRolls: 'Bag with Rolls',
                        bagWithTelescopePole: 'Bag with Telescope Pole',
                        bagWithoutTelescopePole: 'Bag without Telescope Pole',
                        packsack: 'Packsack',
                        vario: 'Vario',
                        quickRack: 'Quick Rack',
                        rackThree: 'Rack Three',
                        others: 'Others',
                        motoBag: 'Motorbike Bag',
                    },
                    duotone: {
                        kite: 'Kite',
                        boards: 'Boards',
                        kiteBar: 'Kite Bar',
                    },
                    zenitram: {
                        kite: 'Kite',
                        bar: 'Bar',
                        wing: 'Wing',
                        kiteBar: 'Kite Bar',
                        kiteWing: 'Kite Wing',
                    },
                },
            },
            pinpointStep: {
                title: 'Mark problem area',
                views: {
                    front: 'Front',
                    frontOpen: 'Open',
                    back: 'Back',
                    backA: 'Back A',
                    backB: 'Back B',
                    bottom: 'Top',
                    top: 'Bottom',
                    side: 'Side',
                    inside: 'Inside',
                },
            },
            photoStep: {
                title: 'Upload a Photo of the Problem',
                mobilePhotoInput: {
                    label: 'Take a Photo',
                    description: 'For best results, upload a clear, high-quality Photo',
                    takePhotoButton: 'Take photo',
                    uploadPhotoButton: 'Upload photo',
                },
                photoDragDrop: {
                    label: 'Upload a Photo from the Computer',
                    description:
                        'For best results, upload clear, high-quality Photos of the Problem',
                    dropLabel: 'Drop the Photo here',
                    button: 'Select Photo',
                },
                qrPhotoUpload: {
                    title: 'Upload a photo from your Phone',
                    text: 'Just scan the QR Code with your Phone and upload a Photo',
                },
            },
            descriptionStep: {
                title: 'Describe the problem',
                label: 'Description of the problem',
                placeholder: '',
            },
            repairTypeStep: {
                title: 'Type of Repair',
                invisible: 'Invisible',
                visible: 'Visible',
                funky: 'Funky',
            },
            diyStep: {
                title: 'DIY Guides',
                nothingFoundSubtitle: 'Sadly, we could not find a matching repair guide.',
                guideBy_one: 'Guide by:',
                guideBy_other: 'Guides by:',
                checkoutGuideAction: 'Checkout Guide',
                notMatching: 'No suitable guide included?',
                checkoutRepairOfferAction: 'Let it be repaired instead.',
            },
            contactStep: {
                form: {
                    title: 'Enter your details',
                    nameLabel: 'Name',
                    firstnameLabel: 'Firstname',
                    lastnameLabel: 'Lastname',
                    emailLabel: 'E-Mail',
                    phoneLabel: 'Phone',
                    maxRepairCostLabel: 'Maximum repair costs',
                    addressLabel: 'Address',
                    zipCodeLabel: 'Zip Code',
                    cityLabel: 'City',
                    countryLabel: 'Country',
                    commentLabel: 'Comment',
                    purchaseLocationLabel: 'Purchase Location',
                    purchaseDateLabel: 'Purchase Date',
                    orderReferenceLabel: 'Order Reference',
                    acceptTermsLabel: 'I agree to a repair up to the specified price limit',
                    addMoreDefects: 'Add more requests',
                    submit: 'Request Repair Offer',
                },
            },
            forms: {
                nameLabel: 'Name',
                firstnameLabel: 'Firstname',
                lastnameLabel: 'Lastname',
                emailLabel: 'E-Mail',
                phoneLabel: 'Phone',
                maxRepairCostLabel: 'Maximum repair costs',
                addressLabel: 'Address',
                zipCodeLabel: 'Zip Code',
                streetNoLabel: 'Street and House Number',
                zipAndCityLabel: 'Zip Code and City',
                cityLabel: 'City',
                countryLabel: 'Country',
                commentLabel: 'Comment',
                purchaseLocationLabel: 'Purchase Location',
                purchaseDateLabel: 'Purchase Date',
                orderReferenceLabel: 'Order Reference',
                articleNumberLabel: 'Article number',
                serialnumberLabel: 'Serialnumber',
                serialNumberWhereToFindTitle: 'Where can I find the serial number?',
                uploadFromComputer: 'Upload from your Computer',
                uploadFromPhone: 'Scan to take a photo with your phone',
                productNameLabel: 'Product Name',
                productSizeLabel: 'Product Size',
                productColorLabel: 'Product Color',
                replacementProductLabel: 'Replacement Product (optional)',
                validation: {
                    email: 'Please enter a valid email address',
                    required: 'This field must not be empty',
                    zipCode: 'Invalid zip code',
                    retailerCode: 'Invalid retailer code',
                },
                selectPlaceholder: 'Please select',
                retailerCode: 'Retailer Code',
                processNumber: 'Process Number',
            },
        },
        mobilePhotoUpload: {
            retakeAction: 'Foto erneut aufnehmen',
        },
        ufixit: {
            intro: {
                toolsNeededLink: 'Tools needed for this Guide',
                toolsNeededTitle: 'Tools needed',
                sparePartsNeededLink: 'Spare Parts needed for this Guide',
                sparePartsNeededTitle: 'Spare Parts needed',
                startAction: 'Start Repairing',
            },
            common: {
                backToRequest: 'Back to your Request',
                step: 'Step',
            },
            outro: {
                enjoy: 'Enjoy your newly repaired product!',
                didYouManage: 'Did you manage to repair your item?',
                furtherAssistance: 'No, I need further assistance',
                yesIAmHappy: 'Yes, I am happy!',
                findNearestStore: 'Find your nearest store',
            },
        },
        brands: {
            ortlieb: {
                repair: {
                    replacementProductHelperText:
                        'If a repair of your product is not possible, we offer to dispose of the product properly and send you a new ORTLIEB product with a 25% discount. If you would like to take advantage of this goodwill offer, you can specify the desired replacement product here.',
                    submitRepairOrder: {
                        submissionInfoTexts: {
                            termsOfService:
                                'By submitting the order you agree with the <externalLink href="https://www.ortlieb.com/de_en/general-terms-and-conditions">general terms and conditions</externalLink>, <dialogLink data-key="repairTerms">the repair conditions</dialogLink> and the <externalLink href="https://www.ortlieb.com/de_en/privacy-policy">privacy policy</externalLink>.',
                        },
                        checkboxes: {
                            cancellationPeriodLabel:
                                'I expressly demand and simultaneously agree that ORTLIEB Sportartikel GmbH begins the service commissioned before the statutory withdrawal period expires. I am aware that my right of withdrawal expires upon full performance of the contract.',
                        },
                        dialogs: {
                            repairTerms: {
                                title: 'Repair regulations',
                                content: `<list>
                                <item>The sending of a product to us is the order to carry out the repairs that we deem necessary, unless a statement from the sender indicates otherwise.</item>
                                <item>Please note that we cannot process your submission without a sufficient description of the fault.</item>
                                <item>If we do not receive feedback from you within 2 weeks after sending the offer, we are entitled to return the product to you, charging a handling fee of 5.00 euros plus shipping fees for both the outbound and return shipping of 5.00 euros each.</item>
                                <item>Repair orders for older products are only accepted subject to reservation.</item>
                                <item>In the event of damage to your product during a repair conducted by us, we will of course replace it with a product from our inventory.</item>
                                <item>Please note that during periods of high repair volume, there may be a longer processing time for your order. We ask for your understanding!</item>
                                <item>All products sent in for repair must be sent in a cleaned condition, as optimal repairs can only be performed on clean products. For soiled products, we will charge a cleaning fee of 5.00 euros (only the area to be repaired will be cleaned).</item>
                                <item>To get an overview of possible costs, simply check our repair price list. Here you will find an overview of the most common repairs and their prices.</item>
                                <item>ORTLIEB Sportartikel GmbH begins the commissioned service before the expiration of the statutory right of withdrawal, provided the customer expressly requests this. The right of withdrawal expires upon full performance of the contract.</item>
                                <item>Repairs up to a maximum amount of {{ form.costApproval }} € will be carried out without prior consultation. If the estimated effort exceeds this amount, an individual offer will be made. If this offer is rejected, only a handling fee of € 5 will be charged.</item>
                                <item>A shipping fee of 5.00 euros for both outbound and return shipping, as well as a possible cleaning fee of 5.00 euros for soiled products, is accepted.</item>
                                </list>`,
                            },
                        },
                    },
                },
                warranty: {
                    replacementProductHelperText:
                        'If the repair of your product is not possible, you will receive a new product upon acceptance of the 5-year ORTLIEB warranty. In the event that we no longer have this product in our range or in stock, you can indicate here which equivalent replacement product should be sent to you instead. If you do not provide any information here, we will contact you.',
                },
                shared: {
                    outroStep: {
                        subtitle:
                            'You have received an email with the shipping labels. Please send us your product so we can process the order.',
                        links: {
                            backToStore: 'Back to the ORTLIEB Online Shop',
                        },
                    },
                },
            },
            duotone: {
                warranty: {
                    requestWarrantyClaim: {
                        checkboxes: {
                            privacyPolicyLabel:
                                'I agree to the <externalLink href="https://www.duotonesports.com/en/privacy-policy">data privacy regulations</externalLink>',
                        },
                    },
                },
            },
        },
    },
    analysator: {
        keyfacts: {
            pageViews: 'Page Views',
            uniqueVisitors: 'Unique Visitors',
            submissions: 'Submissions',
        },
    },
}

export default en
