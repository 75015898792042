import defaultProductCategoryTree from '../products'
import { FieldType, type FrontendWhitelabel } from './types'

const brakeableWhitelabel: FrontendWhitelabel = {
    id: 'va5z1d',
    productCategoryTree: defaultProductCategoryTree,
    config: {
        repair: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Comment',
                            label: 'serviceDesk.shared.contactStep.form.commentLabel',
                            type: FieldType.TextField,
                            params: { required: false },
                            fieldProps: {
                                multiline: true,
                                rows: 2,
                            },
                        },
                    ],
                ],
            },
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [
                    {
                        href: '//www.brakeable.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: [
                            'marc@brakeable.com',
                            'stefan@brakeable.com',
                            'kim@brakeable.com',
                        ],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
            solutions: {
                diy: {
                    activate: true,
                },
                storeFinder: {
                    activate: false,
                    iframeUrl: undefined,
                },
                requestRepairOffer: {
                    activate: false,
                    activateAsFallback: false,
                },
                submitRepairOrder: {
                    activate: true,
                    minCost: 20,
                    shippingCost: 10,
                    submissionInfoTexts: [],
                    submissionCheckboxes: [],
                    submissionDialogs: [],
                },
            },
        },
        warranty: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Comment',
                            label: 'serviceDesk.shared.contactStep.form.commentLabel',
                            type: FieldType.TextField,
                            params: { required: false },
                            fieldProps: {
                                multiline: true,
                                rows: 2,
                            },
                        },
                    ],
                ],
            },
            activateWarrantyEstimation: false,
            outro: {
                lead: 'serviceDesk.warranty.outroStep.subtitle',
                links: [
                    {
                        href: '//www.brakeable.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: [
                            'marc@brakeable.com',
                            'stefan@brakeable.com',
                            'kim@brakeable.com',
                        ],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
            submitWarrantyOrder: {
                submissionCheckboxes: [],
                submissionDialogs: [],
                submissionInfoTexts: [],
            },
        },
    },
}

export default brakeableWhitelabel
