import React from 'react'
import InspectorStepTitle from '../../../../components/typography/inspector-step-title'
import { useTranslation } from 'react-i18next'
import T from '../../../../components/typography/t'
import {
    RepairGuideType,
    VideoRepairGuide,
    type RepairGuide,
} from '../../../../../shared/data/repair-guides/types'
//import whitelabels from '../../../../../shared/data/whitelabel'
import {
    Button,
    styled,
    Typography,
    useTheme,
    Stack,
    Box,
    Dialog,
    DialogContent,
} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import LabelledDelimiter from '../components/labelled-delimiter'
import routeBuilder from '../../../../routes'

const BrandLogo = styled('img')`
    max-width: 120px;
    max-height: 60px;
`

const GuideList = styled('ul')`
    gap: ${({ theme }) => theme.spacing(1)};
    display: flex;
    flex-direction: column;
`

const GuideListEntry = styled('li')`
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    display: flex;
    flex-direction: row;
    align-items: stretch;
`

const GuideImage = styled('div')<{ $src: string }>`
    background-size: cover;
    background-position: center;
    width: 20%;
    background-image: url('${({ $src }) => $src}');
    border-radius: 8px 0 0 8px;
    flex-shrink: 0;
`

const GuideContent = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1em;
    gap: 0.5em;
`

const Guides: React.FC<{
    guides: RepairGuide[]
    setOpenedRepairGuideVideo: (repairGuide: RepairGuide) => void
    company: string
}> = ({ guides, setOpenedRepairGuideVideo, company }) => {
    const { i18n } = useTranslation()
    const language = i18n.language.slice(0, 2)

    const getRepairGuideTitle = (repairGuide: RepairGuide) => {
        if (repairGuide.translations && repairGuide.translations[language]) {
            return repairGuide.translations[language]
        }
        return repairGuide.title
    }
    return (
        <>
            {guides.map((repairGuide) => (
                <GuideListEntry key={repairGuide.id}>
                    <GuideImage $src={repairGuide.image} />
                    <GuideContent>
                        <Typography>{getRepairGuideTitle(repairGuide)}</Typography>
                        <GuideButton
                            setOpenedRepairGuideVideo={setOpenedRepairGuideVideo}
                            repairGuide={repairGuide}
                            company={company}
                        />
                    </GuideContent>
                </GuideListEntry>
            ))}
        </>
    )
}

const GuideButton: React.FC<{
    setOpenedRepairGuideVideo: (repairGuide: RepairGuide) => void
    repairGuide: RepairGuide
    company: string
}> = ({ setOpenedRepairGuideVideo, repairGuide, company }) => {
    if (repairGuide.type === RepairGuideType.VIDEO) {
        return (
            <Button
                variant="outlined"
                sx={{ alignSelf: 'flex-end' }}
                endIcon={<ArrowForwardIcon />}
                onClick={() => {
                    setOpenedRepairGuideVideo(repairGuide)
                }}
            >
                <T i18nKey="serviceDesk.shared.diyStep.checkoutGuideAction" />
            </Button>
        )
    }
    return (
        <Button
            component="a"
            variant="outlined"
            sx={{ alignSelf: 'flex-end' }}
            endIcon={<ArrowForwardIcon />}
            href={routeBuilder.ufixit(company, repairGuide.id)}
            target="_blank"
        >
            <T i18nKey="serviceDesk.shared.diyStep.checkoutGuideAction" />
        </Button>
    )
}

const DIYStep = ({
    repairGuides,
    onServicesClick,
    company,
}: {
    repairGuides: RepairGuide[]
    company: string
    onServicesClick?: () => void
}) => {
    const [openedRepairGuideVideo, setOpenedRepairGuideVideo] = React.useState<
        RepairGuide | undefined
    >(undefined)
    const selectedBrands = repairGuides.map((repairGuide) => ({
        id: repairGuide.author.id,
        name: repairGuide.author.name,
        logo: repairGuide.author.logo,
    }))

    const repairGuidesByBrand = repairGuides.reduce((acc, repairGuide) => {
        if (!acc[repairGuide.author.id]) {
            acc[repairGuide.author.id] = {
                author: repairGuide.author,
                guides: [],
            }
        }
        acc[repairGuide.author.id].guides.push(repairGuide)
        return acc
    }, {} as { [brandId: string]: { author: { id: string; name: string; logo: string }; guides: RepairGuide[] } })

    const ownGuides = company ? repairGuidesByBrand[company] : undefined
    const otherBrandedGuides = Object.keys(repairGuidesByBrand)
        .filter((k) => k !== company)
        .map((k) => repairGuidesByBrand[k])

    return (
        <>
            <InspectorStepTitle
                i18nKey="serviceDesk.shared.diyStep.title"
                Icon={undefined}
                mb={4}
            />
            <Box sx={{ width: '100%' }}>
                {ownGuides && (
                    <>
                        {otherBrandedGuides.length > 0 && (
                            <Stack direction="row" gap={1} alignItems="center" mb={1}>
                                <T
                                    i18nKey="serviceDesk.shared.diyStep.guideBy"
                                    count={ownGuides.guides.length}
                                    fontSize=".85em"
                                />
                                <BrandLogo src={ownGuides.author.logo}></BrandLogo>
                            </Stack>
                        )}
                        <GuideList
                            sx={{
                                mb: otherBrandedGuides.length > 0 ? 4 : 0,
                            }}
                        >
                            <Guides
                                guides={ownGuides.guides}
                                setOpenedRepairGuideVideo={setOpenedRepairGuideVideo}
                                company={company}
                            />
                        </GuideList>
                    </>
                )}
                {otherBrandedGuides.map(({ author: brand, guides }, index) => (
                    <React.Fragment key={brand.id}>
                        <Stack direction="row" gap={1} alignItems="center" mb={1}>
                            <T
                                i18nKey="serviceDesk.shared.diyStep.guideBy"
                                count={guides.length}
                                fontSize=".85em"
                            />
                            <BrandLogo src={brand.logo}></BrandLogo>
                        </Stack>
                        <GuideList
                            sx={{
                                mb: index < otherBrandedGuides.length - 1 ? 2 : 0,
                            }}
                        >
                            <Guides
                                guides={guides}
                                setOpenedRepairGuideVideo={setOpenedRepairGuideVideo}
                                company={`${brand.name}-${brand.id}`}
                            />
                        </GuideList>
                    </React.Fragment>
                ))}
            </Box>
            {onServicesClick && (
                <>
                    <LabelledDelimiter sx={{ mt: 4 }}>
                        <T i18nKey="serviceDesk.shared.diyStep.notMatching" component="p" />
                    </LabelledDelimiter>
                    <Button
                        color="primary"
                        sx={{ mt: 4, alignSelf: 'center' }}
                        onClick={onServicesClick}
                    >
                        <T i18nKey="serviceDesk.shared.diyStep.checkoutRepairOfferAction" />
                    </Button>
                </>
            )}
            <Dialog
                open={!!openedRepairGuideVideo}
                onClose={() => setOpenedRepairGuideVideo(undefined)}
            >
                <DialogContent sx={{ width: '650px', maxWidth: '100%' }}>
                    <iframe
                        width="100%"
                        style={{ aspectRatio: '16/9' }}
                        src={(openedRepairGuideVideo as VideoRepairGuide)?.videoUrl}
                    ></iframe>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DIYStep
