import React from 'react'
import { useQuery, getAllBrands } from 'wasp/client/operations'
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    IconButton,
    Link,
    Stack,
    Theme,
    ThemeProvider,
    Typography,
    createTheme,
    styled,
} from '@mui/material'
import ExploreIcon from '@mui/icons-material/Explore'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import StoreIcon from '@mui/icons-material/Store'
import HubIcon from '@mui/icons-material/Hub'
import IFrameIcon from '@mui/icons-material/FilterFrames'
import routeBuilder from '../../../../routes'
import CardTitle from '../../../../components/typography/card-title'
import T from '../../../../components/typography/t'
import HeaderBar from '../../../company/inspector/products/components/header-bar'
import { WhitelabelStatus } from '../../../../../shared/data/whitelabel/types'
import { OrderType } from '../../../../../shared/types/service-desk/order-type'
import LoadingSkeleton from '../../../../components/loading/skeleton'
import GetAllBrandsViewModel, {
    GetAllBrandsEntryViewModel,
} from '../../../../../shared/types/brands/get-all-brands-view-model'

const TatajubaRow = ({ entry }: { entry: GetAllBrandsEntryViewModel }) => {
    return (
        <ThemeProvider
            theme={(theme: Theme) =>
                createTheme({
                    ...theme,
                    palette: {
                        ...theme.palette,
                        primary: {
                            main: entry.theme.primary,
                            contrastText: entry.theme.primaryContrastText,
                        },
                    },
                })
            }
        >
            <Stack
                direction="row"
                sx={{ width: '100%', borderBottom: '1px solid #e0e0e0' }}
                gap={1}
                mt={2}
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h6" color="primary">
                    {entry.name}
                </Typography>

                <Stack direction="row" gap={1} alignItems="center">
                    <IconButton
                        color="primary"
                        href={routeBuilder.serviceDesk(entry.id, entry.name)}
                    >
                        <HubIcon />
                    </IconButton>
                    {entry.b2bActive && (
                        <IconButton
                            color="primary"
                            href={routeBuilder.serviceDesk(
                                entry.id,
                                entry.name,
                                undefined,
                                OrderType.B2B
                            )}
                        >
                            <StoreIcon />
                        </IconButton>
                    )}
                    <Divider orientation="vertical" flexItem />
                    <IconButton href={routeBuilder.explorer(entry.id, entry.name)} color="primary">
                        <ExploreIcon />
                    </IconButton>
                    <IconButton
                        href={routeBuilder.analysator(entry.id, entry.name)}
                        color="primary"
                    >
                        <AutoGraphIcon />
                    </IconButton>
                </Stack>
            </Stack>
        </ThemeProvider>
    )
}

const TatajubaSectionTitle = styled(Typography)(
    ({ theme }) => `
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
    padding: ${theme.spacing(1)};
    border-radius: ${theme.shape.borderRadius}px;
`
)

const Tatajuba = ({ whitelabels }: { whitelabels: GetAllBrandsViewModel }) => {
    return (
        <>
            <Card>
                <CardContent>
                    <TatajubaSectionTitle variant="h6">Live 🔥</TatajubaSectionTitle>
                    {whitelabels
                        .filter((entry) => entry.status === WhitelabelStatus.Live)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((entry, index) => (
                            <TatajubaRow key={index} entry={entry} />
                        ))}

                    <TatajubaSectionTitle variant="h6" mt={8}>
                        External Demo Access 📺
                    </TatajubaSectionTitle>
                    {whitelabels
                        .filter((entry) => entry.status === WhitelabelStatus.ExternalDemoAccess)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((entry, index) => (
                            <TatajubaRow key={index} entry={entry} />
                        ))}
                    <TatajubaSectionTitle variant="h6" mt={8}>
                        Internal Only 🔐
                    </TatajubaSectionTitle>
                    {whitelabels
                        .filter((entry) => entry.status === WhitelabelStatus.InternalOnly)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((entry, index) => (
                            <TatajubaRow key={index} entry={entry} />
                        ))}
                    <TatajubaSectionTitle variant="h6" mt={8}>
                        iFrame Demos <IFrameIcon />
                    </TatajubaSectionTitle>

                    <Typography
                        variant="h6"
                        color="primary"
                        component={Link}
                        href="https://www.brakeable.com/demos/Einstein-2num3c"
                    >
                        Einstein
                    </Typography>
                    <br />
                    <Typography
                        variant="h6"
                        color="primary"
                        component={Link}
                        href="https://www.brakeable.com/demos/Penguin-1iutwg"
                    >
                        Penguin
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}

const TatajubaLoader = () => {
    const { data: companies, isFetching, error } = useQuery(getAllBrands)
    if (isFetching) return <LoadingSkeleton large />
    if (error) return <div> Error! </div>
    if (!companies || companies?.length === 0) return null

    return (
        <Box sx={{ position: 'relative' }}>
            <HeaderBar>
                <CardTitle>
                    <T i18nKey="admin.tatajuba.title" />
                </CardTitle>
                <Stack direction="row" gap={1}>
                    <Button href={routeBuilder.analyticsLog()}>Analytics Log</Button>
                    <Button href={routeBuilder.aiVerifier()}>AI Verifier</Button>
                </Stack>
            </HeaderBar>
            <Tatajuba whitelabels={companies} />
        </Box>
    )
}

export default TatajubaLoader
