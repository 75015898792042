import { Whitelabel, BackendWhitelabel, FrontendWhitelabel } from '../data/whitelabel/types'

const mergeWhitelabel = (
    frontendWhitelabel: FrontendWhitelabel,
    backendWhitelabel: BackendWhitelabel
): Whitelabel => {
    return {
        theme: {
            palette: {
                primary: backendWhitelabel.theme.primary,
                secondary: backendWhitelabel.theme.secondary,
                crosshairColors: backendWhitelabel.theme.crosshairColors,
                illustrationColors: backendWhitelabel.theme.illustrationColors,
                progressbarColor: backendWhitelabel.theme.progressbarColor,
                textColor: backendWhitelabel.theme.textColor,
            } as any,
        },
        id: backendWhitelabel.id,
        name: backendWhitelabel.name,
        b2bActive: backendWhitelabel.b2bActive,
        logo: backendWhitelabel.logo,
        status: backendWhitelabel.status,
        repairGuides: backendWhitelabel.repairGuides,
        repairServices: backendWhitelabel.repairServices,
        alterationServices: backendWhitelabel.alterationServices,
        productCategoryTree: frontendWhitelabel.productCategoryTree,
        config: frontendWhitelabel.config,
    }
}

export default mergeWhitelabel
