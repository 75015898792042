import { styled } from '@mui/material'

const LinktreeEntry = styled('button')`
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.textColor ?? theme.palette.primary.main};
    padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4)};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, fill 0.1s ease-in-out,
        stroke 0.1s ease-in-out;

    svg {
        fill: ${({ theme }) => theme.palette.primary.main};
        stroke: ${({ theme }) => theme.palette.primary.main};
    }

    &:not(:disabled):hover {
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.primary.contrastText};

        svg {
            fill: ${({ theme }) => theme.palette.primary.contrastText};
            stroke: ${({ theme }) => theme.palette.primary.contrastText};
        }
    }

    &:disabled {
        border-color: ${({ theme }) => theme.palette.grey[200]};
        color: ${({ theme }) => theme.palette.grey[200]};
    }
`
export const LinktreeEntryLink = styled('a')`
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.textColor ?? theme.palette.primary.main};
    padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4)};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, fill 0.1s ease-in-out,
        stroke 0.1s ease-in-out;

    svg {
        fill: ${({ theme }) => theme.palette.primary.main};
        stroke: ${({ theme }) => theme.palette.primary.main};
    }

    &:hover {
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.primary.contrastText};

        svg {
            fill: ${({ theme }) => theme.palette.primary.contrastText};
            stroke: ${({ theme }) => theme.palette.primary.contrastText};
        }
    }
`

export default LinktreeEntry
