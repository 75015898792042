import React from 'react'
import { useTranslation } from 'react-i18next'
import { RepairMultiStepFormContext } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import T from '../../../../components/typography/t'
import LinktreeEntry from '../../shared/components/linktree-entry'
import { Stack } from '@mui/material'
import InspectorStepTitle from '../../../../components/typography/inspector-step-title'

const SolutionChooserStepContext = () => {
    const { form, ctx, props } = React.useContext(RepairMultiStepFormContext)

    const aiSelectedServices = form.formValues.aiSelectedServices
    const aiSelectedRepairGuides = form.formValues.aiSelectedRepairGuides
    const showDiySolutions =
        ctx.whitelabel.config.repair.solutions.diy.activate && aiSelectedRepairGuides.length > 0
    const showSubmitRepairRequestSolutions =
        ctx.whitelabel.config.repair.solutions.submitRepairOrder.activate &&
        (aiSelectedServices.length > 0 ||
            !ctx.whitelabel.config.repair.solutions.requestRepairOffer.activateAsFallback)
    const showRequestRepairOfferSolutions =
        ctx.whitelabel.config.repair.solutions.requestRepairOffer.activate ||
        (aiSelectedServices.length === 0 &&
            ctx.whitelabel.config.repair.solutions.requestRepairOffer.activateAsFallback)
    const showStoreFinderSolutions = ctx.whitelabel.config.repair.solutions.storeFinder.activate

    React.useEffect(() => {
        ctx.setSolutionPath.reset()
    }, [])

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: false,
                hideNextButton: true,
            }}
        >
            <InspectorStepTitle i18nKey="serviceDesk.repair.solutionStep.linktree.title" mb={4} />
            <Stack gap={2} sx={{ width: '100%', justifyContent: 'center' }}>
                {showDiySolutions && (
                    <LinktreeEntry
                        onClick={() => {
                            ctx.setSolutionPath.diy()
                            props.nextSlide()
                        }}
                    >
                        <T
                            variant="h3"
                            mb={1}
                            i18nKey="serviceDesk.repair.solutionStep.linktree.diyTitle"
                        />
                        <T
                            variant="body1"
                            i18nKey="serviceDesk.repair.solutionStep.linktree.diySubtitle"
                        />
                    </LinktreeEntry>
                )}
                {showRequestRepairOfferSolutions && (
                    <LinktreeEntry
                        onClick={() => {
                            ctx.setSolutionPath.requestRepairOffer()
                            props.nextSlide()
                        }}
                    >
                        <T
                            variant="h3"
                            mb={1}
                            i18nKey="serviceDesk.repair.solutionStep.linktree.contactFormTitle"
                        />
                        <T
                            variant="body1"
                            i18nKey="serviceDesk.repair.solutionStep.linktree.contactFormSubtitle"
                        />
                    </LinktreeEntry>
                )}
                {showSubmitRepairRequestSolutions && (
                    <LinktreeEntry
                        onClick={() => {
                            ctx.setSolutionPath.submitRepairOrder({
                                skipCostApprovalStep: aiSelectedServices.length === 0,
                            })
                            props.nextSlide()
                        }}
                    >
                        <T
                            variant="h3"
                            mb={1}
                            i18nKey="serviceDesk.repair.solutionStep.linktree.servicesTitle"
                        />
                        <T
                            variant="body1"
                            i18nKey="serviceDesk.repair.solutionStep.linktree.servicesSubtitle"
                        />
                    </LinktreeEntry>
                )}
                {showStoreFinderSolutions && (
                    <LinktreeEntry
                        onClick={() => {
                            ctx.setSolutionPath.storeFinder()
                            props.nextSlide()
                        }}
                    >
                        <T
                            variant="h3"
                            mb={1}
                            i18nKey="serviceDesk.repair.solutionStep.linktree.storeFinderTitle"
                        />
                        <T
                            variant="body1"
                            i18nKey="serviceDesk.repair.solutionStep.linktree.storeFinderSubtitle"
                        />
                    </LinktreeEntry>
                )}
            </Stack>
        </StepFrameworkWrapper>
    )
}

export default SolutionChooserStepContext
