import defaultProductTree from '../products/index'
import { FieldType, type FrontendWhitelabel } from './types'

const intersportWhitelabel: FrontendWhitelabel = {
    id: '2dh79y',
    productCategoryTree: defaultProductTree,
    config: {
        repair: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Address',
                            label: 'serviceDesk.shared.contactStep.form.addressLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'ZipCode',
                            label: 'serviceDesk.shared.contactStep.form.zipCodeLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                            fieldProps: {
                                type: 'number',
                                fieldControlSx: { width: 150, flexShrink: 0 },
                            },
                        },
                        {
                            key: 'City',
                            label: 'serviceDesk.shared.contactStep.form.cityLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Country',
                            label: 'serviceDesk.shared.contactStep.form.countryLabel',
                            type: FieldType.TextField,
                            params: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Comment',
                            label: 'serviceDesk.shared.contactStep.form.commentLabel',
                            type: FieldType.TextField,
                            params: { required: false },
                            fieldProps: {
                                multiline: true,
                                rows: 2,
                            },
                        },
                    ],
                ],
            },
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
            solutions: {
                diy: {
                    activate: true,
                },
                storeFinder: {
                    activate: true,
                    iframeUrl: ({ language }) => 'https://www.intersport.de/haendlersuche/?lang=',
                },
                requestRepairOffer: {
                    activate: true,
                    activateAsFallback: false,
                },
                submitRepairOrder: {
                    activate: false,
                    minCost: 20,
                    shippingCost: 10,
                    submissionInfoTexts: [],
                    submissionCheckboxes: [],
                    submissionDialogs: [],
                },
            },
        },
    },
}

export default intersportWhitelabel
