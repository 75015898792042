import React from 'react'

export type Slide = {
    name: string
    Component: () => JSX.Element
    skip: boolean
    obscureProgress?: boolean
}

export type StepProps = {
    currentStep: number
    maxSteps: number
    setSlide: (index: number) => void
    nextSlide: () => void
    prevSlide: () => void
    gotoEnd: () => void
    setSlideSkip: (name: string, skip: boolean) => void
    direction: Direction
}

export enum Direction {
    FORWARD = 'forward',
    BACKWARD = 'backward',
}

const useSlider = (
    slideDefinition: Array<Slide>,
    initialSlide = 0
): StepProps & { slideIndex: number; getSlides: () => Slide[] } => {
    const [state, setState] = React.useState({
        slideIndex: initialSlide,
        slides: slideDefinition,
        direction: Direction.FORWARD,
    })
    const getSlides = () => state.slides.filter((slide) => !slide.skip)
    const nextSlide = () => {
        setState((state) => ({
            ...state,
            slideIndex: Math.min(state.slideIndex + 1, state.slides.length - 1),
            direction: Direction.FORWARD,
        }))
    }
    const prevSlide = () => {
        setState((state) => ({
            ...state,
            slideIndex: Math.max(state.slideIndex - 1, 0),
            direction: Direction.BACKWARD,
        }))
    }
    const setSlide = (index: number) => setState((state) => ({ ...state, slideIndex: index }))
    const maxSteps = getSlides().length
    const gotoEnd = () => setState((state) => ({ ...state, slideIndex: state.slides.length - 1 }))
    const currentStep = state.slideIndex
    const setSlideSkip = (name: string, skip: boolean) => {
        const slide = slideDefinition.find((slide) => slide.name === name)
        if (slide) {
            setState((state) => ({
                ...state,
                slides: state.slides.map((s) => (s.name === name ? { ...s, skip } : s)),
            }))
        } else {
            console.warn('slide not found:', name)
        }
    }

    return {
        slideIndex: state.slideIndex,
        nextSlide,
        prevSlide,
        setSlide,
        gotoEnd,
        currentStep,
        maxSteps,
        setSlideSkip,
        getSlides,
        direction: state.direction,
    }
}

export default useSlider
