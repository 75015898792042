import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import { useTranslation } from 'react-i18next'
import { type Field as FieldT } from '../../../../../../shared/data/whitelabel/types'
import { Stack } from '@mui/material'
import Field from '../../../shared/components/field'
import createFormField from '../../../shared/helper/create-form-field'

export const ManualProofOfPurchaseInput = ({
    useFormReturn,
    fields,
}: {
    useFormReturn: UseFormReturn
    fields: FieldT[][]
}) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle
                i18nKey="serviceDesk.warranty.purchaseConfirmationStep.form.title"
                mb={4}
            />
            {fields.map((cells, index) => (
                <Stack
                    direction="row"
                    mb={index === fields.length - 1 ? 0 : 1}
                    gap={2}
                    sx={{ width: '100%' }}
                    key={index}
                >
                    {cells.map(createFormField(useFormReturn.getValues())).map((cell) => (
                        <Field key={cell.key} field={cell} useFormReturn={useFormReturn} />
                    ))}
                </Stack>
            ))}
        </>
    )
}

export default ManualProofOfPurchaseInput
