import React from 'react'
import { UseFormReturn, Controller } from 'react-hook-form'
import { Field as FieldT, FieldType } from '../../../../../shared/data/whitelabel/types'
import InputField, { FieldControl } from '../../../../components/form/input-field'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import { ClearIcon } from '@mui/x-date-pickers/icons'
import T from '../../../../components/typography/t'
import { InputLabel, Select, MenuItem } from '@mui/material'

const Field = ({
    field: f,
    useFormReturn,
}: {
    field: FieldT
    useFormReturn: UseFormReturn<any>
}) => {
    const [t] = useTranslation()
    const {
        register,
        watch,
        setValue,
        control,
        formState: { errors },
    } = useFormReturn
    const field = typeof f === 'function' ? f(useFormReturn.getValues()) : f

    if (field.type === FieldType.TextField) {
        return (
            <InputField
                key={field.key}
                id={field.key}
                fullWidth
                {...register(field.key as any, field.params)}
                {...(field.fieldProps as any)}
                required={field.params?.required}
                label={t(field.label as any)}
                error={!!errors[field.key]}
                helperPopup={field.params?.helperPopup}
                helperText={
                    errors[field.key] ? (
                        <T
                            i18nKey={errors[field.key]?.message as any}
                            sx={{ color: 'error.main' }}
                            component="span"
                        />
                    ) : (
                        <T i18nKey={field.fieldProps?.helperText as any} component="span" />
                    )
                }
            />
        )
    }
    if (field.type === FieldType.Autocomplete) {
        const watchedValue = watch(field.key)?.value
        const v = typeof watchedValue === 'object' ? watchedValue.value : watchedValue
        const value = React.useMemo(
            () =>
                field.options.find((o) => (typeof o === 'object' ? o.value === v : o === v)) ||
                undefined,
            [field.options, watchedValue]
        )
        register(field.key as any, field.params)

        return (
            <Controller
                name={field.key}
                control={control}
                render={({ field: { onChange, onBlur } }) => (
                    <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(field.key ?? undefined, newValue)
                            onChange(newValue ?? undefined)
                        }}
                        onBlur={onBlur}
                        autoComplete
                        autoHighlight
                        autoSelect
                        options={field.options}
                        getOptionLabel={(option) =>
                            typeof option === 'object' ? option.label : option
                        }
                        renderInput={(params) => (
                            <InputField
                                {...params}
                                label={t(field.label as any)}
                                {...(field.fieldProps as any)}
                                required={field.params?.required}
                            />
                        )}
                        sx={{ width: '100%' }}
                        clearIcon={<ClearIcon />}
                    />
                )}
            />
        )
    }
    if (field.type === FieldType.Dropdown) {
        const watchedValue = watch(field.key)
        const value = typeof watchedValue === 'object' ? watchedValue.value : watchedValue
        return (
            <FieldControl fullWidth>
                <InputLabel id={field.key}>
                    {t(field.label as any)}
                    {field.params?.required ? ' *' : ''}
                </InputLabel>
                <Select
                    native
                    labelId={field.key}
                    id={field.key}
                    required={!!field.params?.required}
                    disabled={field.options.length === 0}
                    value={value ?? ''}
                    {...register(field.key as any, field.params)}
                >
                    <option value=" ">
                        {t('serviceDesk.shared.forms.selectPlaceholder' as any)}
                    </option>
                    {field.options.map((option) => {
                        const value = typeof option === 'object' ? option.value : option
                        const label = typeof option === 'object' ? option.label : option
                        return (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        )
                    })}
                </Select>
            </FieldControl>
        )
    }
    return null
}

export default Field
