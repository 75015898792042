export enum RepairGuideType {
    VIDEO = 'video',
    TEXT = 'text',
    EXTERNAL = 'external',
}

export type BaseRepairGuide = {
    id: string
    title: string
    image: string
    author: {
        id: string
        name: string
        logo: string
    }
    translations: Record<string, string | null>
}

export type LegacyRepairGuide = {
    id: string
    title: string
    image: string
    authorCompany: string
    authorLogo: string
    translations: Record<string, string | null>
    videoUrl?: string
    url?: string
    type: RepairGuideType
}

export type ExternalRepairGuide = BaseRepairGuide & {
    type: RepairGuideType.EXTERNAL
    url: string
}

export type TextRepairGuide = BaseRepairGuide & {
    type: RepairGuideType.TEXT
}

export type VideoRepairGuide = BaseRepairGuide & {
    type: RepairGuideType.VIDEO
    videoUrl: string
}

export type RepairGuide = TextRepairGuide | VideoRepairGuide | ExternalRepairGuide

export type Difficulty = 'Very easy' | 'Easy' | 'Moderate' | 'Difficult'

export enum DifficultyEnum {
    veryEasy = 'Very easy',
    easy = 'Easy',
    moderate = 'Moderate',
    difficult = 'Difficult',
}

export type Tool = {
    name: string
    url: string
}
export type Part = {
    name: string
    url: string
}

export type LinesItem = {
    level: number
    textMarkup: string
}

export type GuideImage = {
    url: string
    width: number
    height: number
}

interface EncodingsItem {
    codecs: string
    mime: string
    url: string
}

export type VideoData = {
    image: GuideImage
    encodings: EncodingsItem[]
}

type ImageMedia = {
    type: 'image'
    data: GuideImage[]
}

type VideoMedia = {
    type: 'video'
    data: VideoData
}

export type Media = ImageMedia | VideoMedia

export type GuideStep = {
    title?: string
    lines: LinesItem[]
    stepid: number
    orderby: number
    media: Media
}

export type TextGuideContent = {
    guideId: string
    title: string
    heroImage: GuideImage
    metaInformation: {
        difficulty: Difficulty
        timeRequired: string
    }
    introduction: string
    toolsNeeded: Tool[]
    partsNeeded: Part[]
    steps: GuideStep[]
}

export const mediaIsImage = (media: Media): media is ImageMedia => {
    return media.type === 'image'
}
export const mediaIsVideo = (media: Media): media is VideoMedia => {
    return media.type === 'video'
}
