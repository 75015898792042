import React from 'react'
import { WidthContainer } from '../../../components/layout'
import { useTheme, Typography, Stack, Button, Box } from '@mui/material'
import HappyIllustration from './happy-illustration'
import ConfettiExplosion from 'react-confetti-explosion'
import useDialog from '../../../mixins/use-dialog'
import T from '../../../components/typography/t'
import StoreFinderIFrameDialog from '../../service-desk/shared/components/store-finder-iframe-dialog'

const GuideOutroPage = React.forwardRef<
    HTMLDivElement,
    { assistanceIframeUrl?: string; logo: string }
>(({ assistanceIframeUrl, logo }, ref) => {
    const theme = useTheme()
    const [itemRepaired, setItemRepaired] = React.useState(false)
    const { isOpen, open, close } = useDialog()
    return (
        <WidthContainer
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
            }}
            ref={ref}
        >
            {itemRepaired ? (
                <>
                    <Box
                        sx={{
                            position: 'relative',
                            zIndex: 1000,
                        }}
                    >
                        <HappyIllustration theme={theme} />
                        <Box
                            sx={{
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                position: 'absolute',
                                zIndex: 1000,
                                pointerEvents: 'none',
                            }}
                        >
                            <ConfettiExplosion width={document.body.offsetWidth} duration={4400} />
                        </Box>
                    </Box>
                    <Typography variant="h5" component="h1" fontSize={16} fontWeight="600" mb={1}>
                        <T i18nKey="serviceDesk.ufixit.outro.enjoy" />
                    </Typography>
                </>
            ) : (
                <>
                    <Typography variant="h5" component="h1" fontSize={16} fontWeight="600" mb={1}>
                        <T i18nKey="serviceDesk.ufixit.outro.didYouManage" />
                    </Typography>
                    <Stack
                        direction={{ sm: 'row', xs: 'column' }}
                        gap={2}
                        alignItems="center"
                        mt={4}
                    >
                        <Button onClick={open}>
                            <T i18nKey="serviceDesk.ufixit.outro.furtherAssistance" />
                        </Button>
                        <Button
                            onClick={() => {
                                setItemRepaired(true)
                            }}
                            variant="contained"
                        >
                            <T i18nKey="serviceDesk.ufixit.outro.yesIAmHappy" />
                        </Button>
                    </Stack>
                </>
            )}
            <StoreFinderIFrameDialog
                storeFinderIframeUrl={assistanceIframeUrl!}
                isOpen={isOpen}
                close={close}
                logo={logo}
            />
        </WidthContainer>
    )
})

export default GuideOutroPage
