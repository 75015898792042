import React from 'react'
import RepairMultiStep from './repair-multi-step'
import uploadcareClient from '../../../mixins/uploadcare-client'
import { ThemeProvider, createTheme } from '@mui/material'
import { submitRepairRequest } from 'wasp/client/operations'
import { FormValues, WhitelabelWithRepairConfig } from './context'
import mergeObjects from '../../../mixins/merge-object'
import { useAnalytics } from 'use-analytics'
import { RepairRequestPageViewEvent } from '../../../../shared/analytics'
import { useIsMobile } from '../../../mixins/media-query'
import parseQueryString from '../../../mixins/parse-query-string'
import intersportWhitelabel from '../../../../shared/data/whitelabel/Intersport-2dh79y'
import { useRollbar } from '@rollbar/react'
import { OrderType } from '../../../../shared/types/service-desk/order-type'
import WhitelabelLoader from '../shared/whitelabel-loader'

export const IntersportRepairModule = () => {
    return (
        <RepairModule
            whitelabel={intersportWhitelabel as WhitelabelWithRepairConfig}
            orderType={OrderType.B2C}
        />
    )
}

const RepairModule = ({
    whitelabel,
    orderType,
}: {
    whitelabel: WhitelabelWithRepairConfig
    orderType: OrderType
}) => {
    const rollbar = useRollbar()
    const utmData = parseQueryString()
    const analytics = useAnalytics()
    const isMobile = useIsMobile()

    React.useEffect(() => {
        analytics.track(RepairRequestPageViewEvent, { isMobile, brandId: whitelabel.id, utmData })
    }, [])

    const handleSubmit = async (formValues: FormValues) => {
        try {
            await submitRepairRequest({
                companyId: whitelabel.id,
                defectPhotoUrl: formValues.uploadedPhotoUrl!,
                pinpoint: formValues.pinpoint,
                description: formValues.description!,
                product: formValues.selectedProduct,
                productView: formValues.selectedProductView,
                contactForm: formValues.contactFormValues,
                repairInformationForm: formValues.repairInformationForm,
                utmData: utmData,
                anonymousUserId: analytics.user().anonymousId,
                aiSuggestedRepairServices: formValues.aiSelectedServices,
                aiSuggestedRepairGuides: formValues.aiSelectedRepairGuides,
                costApproval: formValues.costApproval,
                orderType,
                retailerCode: formValues.retailerCode,
                retailerForm: formValues.retailerForm,
                requestIndividualSupport: formValues.requestIndividualSupport,
                individualSupportForm: formValues.individualSupportForm,
            })
        } catch (error) {
            rollbar.error('Error submitting repair request', {
                error,
                formValues,
                companyId: whitelabel.id,
                utmData: utmData,
                anonymousUserId: analytics.user().anonymousId,
            })
        }
    }

    return (
        <ThemeProvider
            theme={(theme: any) =>
                createTheme({
                    ...theme,
                    palette: {
                        ...theme.palette,
                        ...whitelabel?.theme.palette,
                    },
                    components: mergeObjects(theme.components, whitelabel?.theme.components),
                } as any)
            }
        >
            <RepairMultiStep
                uploadCareClient={uploadcareClient}
                whitelabel={whitelabel as WhitelabelWithRepairConfig}
                onSubmit={handleSubmit}
                orderType={orderType}
            />
        </ThemeProvider>
    )
}

export const RepairPageB2B = () => (
    <WhitelabelLoader>
        {({ whitelabel }) => (
            <RepairModule
                whitelabel={whitelabel as WhitelabelWithRepairConfig}
                orderType={OrderType.B2B}
            />
        )}
    </WhitelabelLoader>
)

export const RepairPageB2C = () => (
    <WhitelabelLoader>
        {({ whitelabel }) => (
            <RepairModule
                whitelabel={whitelabel as WhitelabelWithRepairConfig}
                orderType={OrderType.B2C}
            />
        )}
    </WhitelabelLoader>
)
