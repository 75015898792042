import React from 'react'
import { styled } from '@mui/material'
import Coord from '../../../../../../shared/types/common/coord'
// @ts-ignore
import CrosshairSvg from '../../../../../assets/img/crosshair.svg?react'

const ImageWrapper = styled('div')`
    max-width: 100%;
    max-height: 100%;
    position: relative;
    min-height: 0; // to shrink element to fit image inside flexbox
    flex-shrink: 1;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`

const ProductImage = styled('img')`
    max-width: 100%;
    max-height: 100%;
    position: relative;
`

const Crosshair = styled(CrosshairSvg)`
    width: 40px;
    height: 40px;
    position: absolute;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 100;
`

const Pinpoint = ({
    productImageUrl,
    onPinpointChanged,
    initialCoords = { x: -1000, y: -1000 },
}: {
    productImageUrl: string
    onPinpointChanged: (coord: Coord) => void
    initialCoords?: Coord
}) => {
    const [coordinates, setCoordinates] = React.useState<Coord>(initialCoords)
    const [imageSize, setImageSize] = React.useState<{ width: number; height: number }>({
        width: 0,
        height: 0,
    })

    const imageRef = React.useRef<HTMLImageElement>(null)

    const updateCoordinates = (coord: Coord) => {
        if (imageRef.current == null) {
            return
        }
        const imageRect = imageRef.current.getBoundingClientRect()

        const calculatedCoordinates = {
            x: Math.floor(Math.min(Math.max(coord.x - imageRect.x, 0), imageSize.width)),
            y: Math.floor(Math.min(Math.max(coord.y - imageRect.y, 0), imageSize.height)),
        }

        const proportionalCoordinates = {
            x: calculatedCoordinates.x / imageSize.width,
            y: calculatedCoordinates.y / imageSize.height,
        }

        setCoordinates(proportionalCoordinates)

        onPinpointChanged(proportionalCoordinates)
    }

    const handleEvent = (e: any) => {
        if (e.type == 'touchend' || e.type == 'touchmove' || e.type == 'touchstart') {
            var evt = typeof e.originalEvent === 'undefined' ? e : e.originalEvent
            var touch = evt.touches[0] || evt.changedTouches[0]
            updateCoordinates({ x: touch.clientX, y: touch.clientY })
            e.preventDefault()
        } else if (e.type == 'mouseup') {
            updateCoordinates({ x: e.clientX, y: e.clientY })
        } else {
            return
        }
    }

    const updateImageSize = (e: any) => {
        if (imageRef.current == null) {
            return
        }

        setImageSize({
            width: imageRef.current.offsetWidth,
            height: imageRef.current.offsetHeight,
        })
    }

    React.useEffect(() => {
        window.addEventListener('resize', updateImageSize)
        window.addEventListener('pageshow', updateImageSize)
        window.addEventListener('focus', updateImageSize)
        window.addEventListener('visibilitychange', updateImageSize)

        if (imageRef.current == null) {
            return
        }
        imageRef.current.addEventListener('touchstart', handleEvent, { passive: false })
        imageRef.current.addEventListener('touchmove', handleEvent, { passive: false })
        imageRef.current.addEventListener('touchend', handleEvent, { passive: false })
        imageRef.current.addEventListener('mouseup', handleEvent, { passive: false })
        imageRef.current.addEventListener('load', updateImageSize, { passive: false })

        return () => {
            window.removeEventListener('resize', updateImageSize)
            window.removeEventListener('pageshow', updateImageSize)
            window.removeEventListener('focus', updateImageSize)
            window.removeEventListener('visibilitychange', updateImageSize)

            if (imageRef.current == null) {
                return
            }
            imageRef.current.removeEventListener('touchstart', handleEvent)
            imageRef.current.removeEventListener('touchmove', handleEvent)
            imageRef.current.removeEventListener('touchend', handleEvent)
            imageRef.current.removeEventListener('mouseup', handleEvent)
            imageRef.current.removeEventListener('load', updateImageSize)
        }
    }, [imageRef.current])

    return (
        <ImageWrapper
            style={{
                width: imageSize.width > 0 ? imageSize.width + 'px' : '100%',
                height: imageSize.height > 0 ? imageSize.height + 'px' : '100%',
            }}
        >
            <ProductImage
                data-testid="pinpoint-image"
                ref={imageRef}
                onLoad={updateImageSize}
                src={productImageUrl}
            />
            <Crosshair
                style={{
                    display: imageRef.current ? 'block' : 'none',
                    left: coordinates.x * imageSize.width + 'px',
                    top: coordinates.y * imageSize.height + 'px',
                }}
                sx={{
                    display: imageRef.current ? 'block' : 'none',
                    left: coordinates.x * imageSize.width + 'px',
                    top: coordinates.y * imageSize.height + 'px',
                    fill: (theme) =>
                        theme.palette.crosshairColors?.fill ?? theme.palette.primary.main,
                    stroke: (theme) =>
                        theme.palette.crosshairColors?.stroke ?? theme.palette.primary.main,
                }}
            />
        </ImageWrapper>
    )
}

export default Pinpoint
