import React from 'react'
import { AlterationsMultiStepFormContext } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import { Stack, Typography, Switch, styled, Box } from '@mui/material'
import AlterationService from '../../../../../shared/data/alteration-services/types'
import { useTranslation } from 'react-i18next'
import { findProductTypeById } from '../../../../../shared/data/products/helpers'
import LabelledDelimiter from '../../shared/components/labelled-delimiter'
import T from '../../../../components/typography/t'
import InspectorStepTitle from '../../../../components/typography/inspector-step-title'

const AlterionServiceBox = styled('a', { shouldForwardProp: (prop) => prop !== '$selected' })<{
    $selected: boolean
}>(
    ({ theme, $selected }) => `
    display: flex;
    flex-direction: column;
    border: 1px solid ${theme.palette.grey[200]};
    border-radius: ${theme.spacing(1)};

    width: calc(25% - ${theme.spacing(2)});
    flex-shrink: 1;
    overflow: hidden;

    border-color: ${$selected ? theme.palette.primary.main : theme.palette.grey[200]};


    ${theme.breakpoints.down('md')} {
        width: calc(33% - ${theme.spacing(2)});
    }
    ${theme.breakpoints.down('sm')} {
        width: calc(50% - ${theme.spacing(2)});
    }
`
)

const AlterionServiceImage = styled('img')(
    ({ theme }) => `
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
`
)

const InnerBox = styled(Stack)(
    ({ theme }) => `
    padding: ${theme.spacing(1)};
    justify-content: space-between;
    flex-grow: 1;
`
)

const AlterationSelectionStepContext = () => {
    const { t, i18n } = useTranslation()
    const { form, props, ctx } = React.useContext(AlterationsMultiStepFormContext)

    const language = i18n.language.slice(0, 2)
    const selectedProduct = findProductTypeById(
        form.formValues.selectedProduct!,
        ctx.productCategoryTree
    )
    const availableAlterationServices = ctx.whitelabel.alterationServices.filter((s) =>
        selectedProduct?.applicableAlterations.includes(s.id)
    )
    const getRepairServiceTitle = (repairService: AlterationService) => {
        if (repairService.translations && repairService.translations.title[language]) {
            return repairService.translations.title[language]
        }
        return repairService.title
    }

    const getRepairServiceDescription = (repairService: AlterationService) => {
        if (repairService.translations && repairService.translations.description?.[language]) {
            return repairService.translations.description?.[language]
        }
        return repairService.description
    }

    const handleServiceAcceptance = (serviceId: string) => {
        const service = availableAlterationServices.find((s) => s.id === serviceId)
        if (!service) return
        form.setValue('selectedAlterations', [...form.formValues.selectedAlterations, service.id])
    }

    const handleServiceRejection = (serviceId: string) => {
        const service = availableAlterationServices.find((s) => s.id === serviceId)
        if (!service) return
        form.setValue(
            'selectedAlterations',
            form.formValues.selectedAlterations.filter((id: string) => id !== serviceId)
        )
    }

    const totalPrice = form.formValues.selectedAlterations.reduce((acc, curr) => {
        const service = availableAlterationServices.find((s) => s.id === curr)
        if (!service) return acc
        return acc + service.price[0]
    }, 0)

    return (
        <StepFrameworkWrapper
            wide
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: form.formValues.selectedAlterations.length === 0,
            }}
        >
            <InspectorStepTitle
                i18nKey="serviceDesk.alteration.alterationSelectionStep.title"
                mb={4}
            />
            <Stack gap={2} minWidth={300} direction="row" flexWrap="wrap" width="100%">
                {availableAlterationServices.map((s) => {
                    const isSelected = form.formValues.selectedAlterations.includes(s.id)
                    const toggle = () =>
                        isSelected ? handleServiceRejection(s.id) : handleServiceAcceptance(s.id)
                    return (
                        <AlterionServiceBox key={s.id} $selected={isSelected} onClick={toggle}>
                            <AlterionServiceImage src={s.imgUrl} alt={s.title} />
                            <InnerBox>
                                <Box>
                                    <Typography>{getRepairServiceTitle(s)}</Typography>
                                    <Typography variant="body2" color="grey.500">
                                        {getRepairServiceDescription(s)}
                                    </Typography>
                                </Box>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography fontWeight={500}>
                                        {s.price.length > 1
                                            ? `${s.price[0].toFixed(2)} - ${s.price[1].toFixed(2)}`
                                            : s.price[0].toFixed(2)}
                                        €
                                    </Typography>
                                    <Switch
                                        checked={isSelected}
                                        sx={{
                                            alignSelf: 'flex-end',
                                        }}
                                        onChange={toggle}
                                    />
                                </Stack>
                            </InnerBox>
                        </AlterionServiceBox>
                    )
                })}
                <LabelledDelimiter sx={{ my: 4 }} />
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                >
                    <T
                        i18nKey="serviceDesk.alteration.alterationSelectionStep.total"
                        component="p"
                        variant="body1"
                    />
                    <Typography
                        variant="h2"
                        component="p"
                        textAlign="center"
                        fontWeight={600}
                        color="primary"
                    >
                        {`${totalPrice.toFixed(2)}\u00A0€`}
                    </Typography>
                </Stack>
            </Stack>
        </StepFrameworkWrapper>
    )
}

export default AlterationSelectionStepContext
