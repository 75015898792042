import { TextGuideContent } from '../../../../../shared/data/repair-guides/types'

export type Guides = {
    [key: number | string]: TextGuideContent
}

const guides: Guides = {
    '811': {
        title: 'Banana Teardown',
        guideId: '811',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Moderate',
            timeRequired: 'No estimate',
        },
        introduction:
            "<p>In this teardown, we open a banana to see what's inside.  Yellow and delicious, but most importantly, yellow.</p>",
        steps: [
            {
                title: '',
                orderby: 1,
                stepid: 4383,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/QlgIemJkxpVCKBJC.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Take one banana from the bunch.',
                    },
                    {
                        level: 0,
                        textMarkup: "Don't squeeze too hard!",
                    },
                ],
            },
            {
                title: '',
                orderby: 2,
                stepid: 4384,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/AccRAcWkPglGvHmR.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/iEWaSBWPYm3tYjpg.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Hold the banana in your left hand and grip the stem between your right thumb and forefinger.',
                    },
                ],
            },
            {
                title: '',
                orderby: 3,
                stepid: 4385,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/5HbDNEZIEUBvhThP.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/Hcpb3wpDpVF36Sxu.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Pull the stem downward until the peel splits.',
                    },
                ],
            },
            {
                title: '',
                orderby: 4,
                stepid: 4386,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/kUuqk6IOCkBhJOKp.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/PEdiDbyVQT6G1BwO.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Insert your thumbs into the split of the peel and pull the two sides apart.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Expose the top of the banana.  It may be slightly squished from pulling on the stem, but this will not affect the flavor.',
                    },
                ],
            },
            {
                title: '',
                orderby: 5,
                stepid: 4387,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/OtEwPF6vVZpArJ4R.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Pull open the peel, starting from your original split, and opening it along the length of the banana.',
                    },
                ],
            },
            {
                title: '',
                orderby: 6,
                stepid: 4388,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/gupWBYpZV43dd2Vh.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/EWtuJRfjyvvfogv3.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Remove fruit from peel.',
                    },
                ],
            },
            {
                title: '',
                orderby: 7,
                stepid: 4389,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/lPt2dJrcVZe4XApM.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/V5CAEebHaYIJUcmE.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Eat and enjoy!',
                    },
                    {
                        level: 0,
                        textMarkup: "This is where you'll need your teeth.",
                    },
                    {
                        level: 0,
                        textMarkup: 'Do not choke on banana!',
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Fingers',
                url: 'https://www.amazon.com/ANCIRS-Finger-Making-Objects-Disappear/dp/B07ZRDRTGY/ref=sr_1_6?crid=1SRWACZRB4YDW&keywords=fake+finger+cover&qid=1670028581&sprefix=fake+finger+cove%2Caps%2C123&sr=8-6',
            },
            {
                name: 'Teeth',
                url: '/Item/Teeth',
            },
            {
                name: 'Thumbs',
                url: '/Item/Thumbs',
            },
        ],
    },
    '10001': {
        title: 'Steckschnalle austauschen',
        guideId: '10001',
        heroImage: {
            url: 'https://ucarecdn.com/e67a3b32-1a60-4bb0-afac-36be9819de19/',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Very easy',
            timeRequired: '5 Minuten',
        },
        introduction:
            'In dieser Anleitung zeigen wir Ihnen, wie Sie die Schnalle Ihrer Messenger Bag schnell und einfach ersetzen können. Eine defekte Schnalle kann die Funktionalität Ihrer Tasche beeinträchtigen, aber mit den richtigen Werkzeugen und wenigen Schritten bringen Sie Ihre Bag im Handumdrehen wieder in Topform. Lassen Sie uns starten!',
        steps: [
            {
                title: 'Steckschnalle austauschen',
                orderby: 1,
                stepid: 315116,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://ucarecdn.com/5068e771-e85f-4faf-91c9-578d1d11b9da/',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: '1. Gurt durch Leiterschnalle ausfädeln.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            '2. Defekte Kunststoff-Schnalle aus- und neue Lockmonster-Schnalle einfädeln.',
                    },
                    {
                        level: 0,
                        textMarkup: '3. Leiterschnalle wieder einfädeln.',
                    },
                    {
                        level: 0,
                        textMarkup: '4. Scho Fertig!',
                    },
                ],
            },
        ],
        partsNeeded: [
            {
                name: 'Steckschnalle',
                url: 'https://freitag.ch/de_DE/spare-parts/150003-lockmonster-buckle?v=7640170736618',
            },
        ],
        toolsNeeded: [],
    },
    '20001': {
        title: 'Ölen des Taschenmessers',
        guideId: '20001',
        heroImage: {
            url: 'https://ucarecdn.com/a15b39f5-0caf-42d2-9f3b-c4c15f174c6c/',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Very easy',
            timeRequired: '5 Minuten',
        },
        introduction:
            'In dieser Anleitung erfahren Sie, wie Sie Ihr Taschenmesser richtig ölen, um seine Leistung und Lebensdauer zu optimieren. Regelmäßiges Ölen sorgt dafür, dass die Klingen geschmeidig gleiten und Rostbildung verhindert wird. Mit wenigen einfachen Schritten können Sie die Funktionalität Ihres Messers erhalten. Lassen Sie uns beginnen!',
        steps: [
            {
                orderby: 1,
                stepid: 315116,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://ucarecdn.com/163da7ac-fe1b-41af-b5de-fb01bffce8e8/',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            '1. Entferne die Batterie bei Modellen mit integrierter Elektronik.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            '2. Bewegen Sie die Werkzeuge in warmem Wasser bis die Leichtgängigkeit wieder hergestellt ist.',
                    },
                ],
            },
            {
                orderby: 1,
                stepid: 315116,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://ucarecdn.com/a15b39f5-0caf-42d2-9f3b-c4c15f174c6c/',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: '13 Lasse das Taschenmesser trocknen.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            '4. Geben Sie einen kleinen Tropfen Öl zwischen Werkzeugtalon und Feder.',
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Messer-Öl',
                url: 'https://www.victorinox.com/de-DE/Produkte/Schweizer-Taschenmesser/Accessoires/Multi-Tool-%C3%96l/p/4.3302',
            },
        ],
    },
    '20002': {
        title: 'Schärfen des Taschenmessers',
        guideId: '20002',
        heroImage: {
            url: 'https://ucarecdn.com/ffd3f4b8-75f5-4bd5-89e9-7bf71de41ae0/',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Very easy',
            timeRequired: '5 Minuten',
        },
        introduction:
            'In dieser Anleitung zeigen wir Ihnen, wie Sie Ihr Taschenmesser effektiv schleifen, um die Schärfe und Leistung zu verbessern. Ein gut geschliffenes Messer erleichtert nicht nur die Arbeit, sondern sorgt auch für mehr Sicherheit beim Schneiden. Mit den richtigen Techniken und Werkzeugen bringen Sie Ihre Klinge im Handumdrehen wieder in Topform. Lassen Sie uns loslegen!',
        steps: [
            {
                orderby: 1,
                stepid: 315116,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://ucarecdn.com/ee633350-9ba5-478e-a957-61086eb58d95/',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            '<b>Gerader Schliff</b>: Nachschärfen mit einem Abziehstein beidseitig im Winkel von 15 - 20°. Dies ergibt einen Schnittwinkel von 30 - 40°. Beim Nachschleifen auf einer Schleifscheibe muss ausgiebig mit Wasser gekühlt werden, damit keine zu hohen Temperaturen entstehen',
                    },
                    {
                        level: 0,
                        textMarkup:
                            '<b>Wellenschliff</b>: Die Klinge mit einem Abziehstein auf der flachen Seite des Wellenschliffs im Winkel von 15 - 20° nachschärfen.',
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Abziehstein oder Schleifscheibe',
                url: '',
            },
            {
                name: 'Victorinox Messerschärfer',
                url: 'https://www.victorinox.com/de-DE/Produkte/Schweizer-Taschenmesser/Accessoires/Messersch%C3%A4rfer-klein/p/7.8714',
            },
        ],
    },
    '69099': {
        title: 'How do I repair taped seams on my jacket?',
        guideId: '69099',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '10 minutes',
        },
        introduction:
            '<p>Taped seams can occasionally separate from a jacket due to incorrect product care or other outer influences.  It takes just a few steps to reattach them – we’ll show you how here.</p>',
        steps: [
            {
                title: '',
                orderby: 1,
                stepid: 139001,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/oMM1bpGMwWPTPplV.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Set the iron at 150 °C maximum. Lay the dish towel on the affected area.',
                    },
                ],
            },
            {
                title: '',
                orderby: 2,
                stepid: 139002,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LQkZJFKPLIUrTrtw.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/C2MN6ud3fjWaZDkB.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Press the iron down firmly on the affected area. Wait 10 seconds. Repeat if necessary.',
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Flat Iron',
                url: '/Item/B%C3%BCgeleisen',
            },
            {
                name: 'Dish Towel',
                url: 'https://www.amazon.com/Homaxy-Cotton-Waffle-Kitchen-Absorbent/dp/B07WMQP4SF/ref=sr_1_2?crid=2M0ZIE4C3K0U3&keywords=dish+towel&qid=1684952113&sprefix=dish+towel%2Caps%2C101&sr=8-2',
            },
        ],
    },
    '69101': {
        title: 'How do I replace a cord stopper on my jacket drawstring?',
        guideId: '69101',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '5 minutes',
        },
        introduction:
            '<p>Occasionally, a cord stopper can come loose from the drawstring on your jacket. You can put it back on in just a few steps – read on to find out how.</p>',
        steps: [
            {
                title: '',
                orderby: 1,
                stepid: 139004,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/wQ3LKToFf3eBWtX2.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/uLx2VY1iSRX1X1Jp.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Thread the end of the drawstring through the grommet. Reach into the inner pocket and pull the drawstring through.',
                    },
                ],
            },
            {
                title: '',
                orderby: 2,
                stepid: 139005,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LeWQUWhlPgfyL2km.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LZQmR2fNqClDkhjx.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LgZOURtSdDOKFT2r.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Thread the cord stopper onto the drawstring, tie a knot in the end and cut off the leftover drawstring.',
                    },
                ],
            },
        ],
        partsNeeded: [
            {
                name: 'Cordstopper',
                url: 'https://www.vaude.com/de/de/12582-tanka-kordelstopper.html#?colour=451',
            },
        ],
        toolsNeeded: [
            {
                name: 'Scissors',
                url: 'https://eustore.ifixit.com/products/utility-scissors',
            },
        ],
    },
    '69102': {
        title: 'How do I replace a broken zip slider on my clothing?',
        guideId: '69102',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '15 minutes',
        },
        introduction:
            '<p>uses premium zips from YKK and other brand name manufacturers. Nevertheless, frequent or improper use can cause the slider to become bent or expand so the teeth no longer zip together.</p>\n\n<p>It only takes a few steps to replace the slider – read on to find out how.</p>',
        steps: [
            {
                title: '',
                orderby: 1,
                stepid: 139006,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/rEERqPYOKaTGyX3Y.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/fFAfFZGkuXBTPD6J.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Select a replacement slider and zip stop.',
                    },
                ],
            },
            {
                title: '',
                orderby: 2,
                stepid: 139007,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/BKnjxLUGIOCb26YN.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Remove the zip stop at the end of the zip by prying it open with a wire cutter and pulling it away.',
                    },
                ],
            },
            {
                title: '',
                orderby: 3,
                stepid: 139008,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/cy3RUJdiEl4iQsGm.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Remove the broken slider.',
                    },
                ],
            },
            {
                title: '',
                orderby: 4,
                stepid: 139009,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/2ukcEhQheRSckrYU.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Insert the new slider onto the zip and use the wire cutter to press the zip stop into the position where the old stop had been.',
                    },
                ],
            },
            {
                title: '',
                orderby: 5,
                stepid: 139010,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/RyCgTUfaueHDxdCK.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/ibbNUdoiBtwEZIBj.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/KlatmXAQGHRxk5c1.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Positon the zip stop together with the help of the tweezers.',
                    },
                ],
            },
            {
                title: '',
                orderby: 6,
                stepid: 139011,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/oNlGbNEuRGavRGQS.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/dO1FyQHcRuCHJXeO_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Pinch the zip stop together with the help of the tweezers.',
                    },
                ],
            },
            {
                title: '',
                orderby: 7,
                stepid: 139012,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/M23wJwlPMxCTID5C.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Remove the zip puller (black) from the slider and thread it onto the new slider.',
                    },
                ],
            },
        ],
        partsNeeded: [
            {
                name: 'Zipper slider',
                url: 'https://www.vaude.com/de-DE/Wo-kaufen/Finde-einen-Haendler/Online-Haendler/',
            },
            {
                name: 'Zip stop',
                url: '/Item/Krampen',
            },
        ],
        toolsNeeded: [
            {
                name: 'Flush Cutter',
                url: 'https://eustore.ifixit.com/products/flush-cutter',
            },
            {
                name: 'Tweezers',
                url: 'https://eustore.ifixit.com/products/tweezers',
            },
        ],
    },
    '69105': {
        title: 'How do I lubricate a sticky zip on my clothing?',
        guideId: '69105',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/6draOBRknNP2fuNG.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '5 minutes',
        },
        introduction:
            '<p>Zips often become harder to open or close. Most of the time, lubricating the zip helps it run smoothly again. We’ll show you how you can lubricate a zip on a jacket, but it works just as well on other zips such as on a tent or backpack.</p>',
        steps: [
            {
                title: '',
                orderby: 1,
                stepid: 139017,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/fVR4pDAFS1sH6nwk.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/MWal1wQSZxj2HguC_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Open the zip if possible. Spray a small amount of silicone spray at both openings of the slider. Move the slider up and down several times to distribute the silicone.',
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Silicone spray',
                url: 'https://www.reidl.de/katalog/105639-fuer-die-Kunststofftechnik/874224-Silicon-Trennmittel-Spray400ml-OKS-1361.html?gclid=CPzCobToz88CFQgz0wodlioByA',
            },
        ],
    },
    '69111': {
        title: 'How do I replace a broken buckle on my backpack?',
        guideId: '69111',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '5 minutes',
        },
        introduction:
            '<p>If the hook end (male end) of your buckle is broken, it’s easy to replace. We’ll show you how in just a few easy steps.</p>',
        steps: [
            {
                title: '',
                orderby: 1,
                stepid: 139037,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'In order to determine the correct buckle size, measure the webbing. The webbing illustrated here is 15 mm, so you would need a 15 mm buckle.',
                    },
                ],
            },
            {
                title: '',
                orderby: 2,
                stepid: 139039,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/jlXJntiWiMMd4bB1.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/ytbYnEqLevJrwXH5.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/PaoowYxKlWpegeRL.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Remove the broken buckle from the webbing by first pulling the webbing through the locking strap and then unthreading it from the buckle.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Use a screwdriver or tweezers to help pull the webbing through, if necessary.',
                    },
                ],
            },
        ],
        partsNeeded: [
            {
                name: 'Slide-in buckles Single Adjust',
                url: 'https://www.vaude.com/de/de/11297-steckschnalle-15-mm-single-adjust.html#?colour=451&size=13424',
            },
            {
                name: 'Slide-in buckles Dual Adjust',
                url: 'https://www.vaude.com/de/de/11299-steckschnalle-20-mm-dual-adjust.html#?colour=451',
            },
        ],
        toolsNeeded: [
            {
                name: 'Flathead 3/32" or 2.5 mm Screwdriver',
                url: 'https://eustore.ifixit.com/products/flathead-3-32-or-2-5-mm-screwdriver',
            },
            {
                name: 'Measuring Tape',
                url: 'http://www.amazon.com/dp/B000PSA6GU/',
            },
        ],
    },
    '69115': {
        title: 'How do I replace a broken zip on my jacket?',
        guideId: '69115',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/HnFokidbJhDKAVMV.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Difficult',
            timeRequired: '2 hours',
        },
        introduction:
            '<p>If the zip on your jacket breaks, sometimes the only solution is to take out the entire zip and replace it with a new one. We’ll show you the steps to take here. You’ll need to be able to use a sewing machine.</p>',
        steps: [
            {
                title: '',
                orderby: 1,
                stepid: 139048,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LIwsKBYbBB6HAYMd.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/Adri4nfIZUGXiEtj_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Separate the seams of the broken zip using the seam ripper. It’s best to begin on the outside of the jacket.',
                    },
                ],
            },
            {
                title: '',
                orderby: 2,
                stepid: 139049,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/g3ADFMuXREVgrckJ.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/1ZKCPfsXNa5tfwtB_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Next, separate the seam between the jacket and the front flap.',
                    },
                ],
            },
            {
                title: '',
                orderby: 3,
                stepid: 142097,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/oNR5Z2JZ5Fa2nLIi.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'After you´ve separated the seam between the jacket and the front flap,  you’ll see the lining of your jacket.',
                    },
                ],
            },
            {
                title: '',
                orderby: 4,
                stepid: 139054,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/kjjdq2TJNbofIfsJ.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/PTYeMIYnXEyEkMAc_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Then you can separate the zip from the jacket.',
                    },
                ],
            },
            {
                title: '',
                orderby: 5,
                stepid: 139055,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/UtS5uZwCbJDerp5V.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/lWCWBgmBBOTEcUey.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/XlUrOgQLEIkDypVu.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'After you’ve separated the zip and front flap from the jacket, you’ll need to separate the zip from the front flap. You should then have both sides of the zip and the front flap laying before you.',
                    },
                ],
            },
            {
                title: '',
                orderby: 6,
                stepid: 139056,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/Ynu5TnFKk3vMTkxK.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/myIHgZLS2hQNIAwX.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Measure the length of the zip that you’ve removed and mark the length on the new zip. Use the tweezers to crimp the zip stop onto the new zip in the correct position.',
                    },
                ],
            },
            {
                title: '',
                orderby: 7,
                stepid: 139057,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/rZZk4IWFxtaURcWD.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Cut off any excess zip approx. 2 cm beyond the zip stop.',
                    },
                ],
            },
            {
                title: '',
                orderby: 8,
                stepid: 139058,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LhI1UBt4wO3GqCac.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Use the wire cutters to pry off the zip teeth after the stopper.',
                    },
                ],
            },
            {
                title: '',
                orderby: 9,
                stepid: 142098,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/3KaUMhORNtR4V2Vm.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/YmOqPD2tPmxcP1Ha_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Then remove any leftover pieces with the seam ripper.',
                    },
                ],
            },
            {
                title: '',
                orderby: 10,
                stepid: 139060,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/B2MZWbYYhcMaotbW.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/HnusQprdWuE5MdGY_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Fold over the seam allowance of the zip and sew it down with the sewing machine.',
                    },
                ],
            },
            {
                title: '',
                orderby: 11,
                stepid: 142099,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/nOAPcCKjNP2TOQdE.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Put the inlay aside.',
                    },
                ],
            },
            {
                title: '',
                orderby: 12,
                stepid: 139061,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/k6nmEjJS1QHThnkj.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Position the pocket attachment correctly and sew it on to the closure edge.',
                    },
                ],
            },
            {
                title: '',
                orderby: 13,
                stepid: 139062,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/lD4CXJWYKI2FlVwV.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/aKVhUTX6kTWFqsoY_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Sew the zip onto the front flap using the zip foot.',
                    },
                ],
            },
            {
                title: '',
                orderby: 14,
                stepid: 139063,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/VuDTKTyRfWStTVdP.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/ZAVS1y1ZaxeVaMpW_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Sew the front flap with the zip attached onto the jacket lining.',
                    },
                ],
            },
            {
                title: '',
                orderby: 15,
                stepid: 139065,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/nejxxowUJnIRSt62.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/VToN2mm5kFX2onRw_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Sew the remaining zip half (without flap) onto the other side of the open jacket.',
                    },
                ],
            },
            {
                title: '',
                orderby: 16,
                stepid: 139066,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/1EZxEPwUjQ4xGLSl.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/myBhmygjHYlk4Scy_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Sew along the zip again on the outer fabric.',
                    },
                ],
            },
            {
                title: '',
                orderby: 17,
                stepid: 139067,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/pa5G3emD6KNSbf4T.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Smooth out the other half of the jacket, aligning the two sides together using a seam that comes together or other design element. This is important so that the zip is correctly aligned at the end.',
                    },
                ],
            },
            {
                title: '',
                orderby: 18,
                stepid: 139068,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/sfr2jWFQjamvWywd.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/MfoJ5s6QdUZIRmTH_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Then sew the second side of the zip on. Move the slider as you sew to keep it out of the way.',
                    },
                ],
            },
            {
                title: '',
                orderby: 19,
                stepid: 139069,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/Ws1mNcIDwLAgY5RI.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/MFUqAeXfqVcqX52L_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'The last step is to re-sew the hem seam. If any sewing holes are still visible in the jacket, you can close them using the steam setting on an iron. Be sure that the iron does not touch the jacket!',
                    },
                ],
            },
        ],
        partsNeeded: [
            {
                name: 'Zipper',
                url: '/Item/Ersatzrei%C3%9Fverschluss',
            },
            {
                name: 'Zip stop',
                url: '/Item/Krampen',
            },
        ],
        toolsNeeded: [
            {
                name: 'Yarn',
                url: '/Item/Yarn',
            },
            {
                name: 'Sewing Machine',
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
            },
            {
                name: 'Seam Ripper',
                url: 'http://www.amazon.com/Clover-463-Seam-Ripper/dp/B000YZ8MVU/ref=sr_1_1?ie=UTF8&qid=1346440445&sr=8-1&keywords=seam+ripper',
            },
            {
                name: 'Fabric Scissors',
                url: 'http://www.amazon.com/Gingher-8-Inch-Knife-Dressmakers-Shears/dp/B000UU6SR4/',
            },
            {
                name: 'Tweezers',
                url: 'https://eustore.ifixit.com/products/tweezers',
            },
            {
                name: 'Flush Cutter',
                url: 'https://eustore.ifixit.com/products/flush-cutter',
            },
        ],
    },
    '69120': {
        title: 'How do I replace a sternum strap on my backpack?',
        guideId: '69120',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/3CWd5ZwgV4rkrfyM.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '5 minutes',
        },
        introduction:
            '<p>If the sternum strap comes off of its rail or gets lost, you can easily replace it.</p>',
        steps: [
            {
                title: '',
                orderby: 1,
                stepid: 139072,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/35BUBNnUaDiAJFoE.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Guide the new sternum strap onto the thin end of the rail.',
                    },
                ],
            },
            {
                title: '',
                orderby: 2,
                stepid: 142096,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/rNOK1wDoMnLDSPEV.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/dYnZZlTOqCeyCAO3_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Guide the other side of the sternum strap onto the thin end of the rail and position it.',
                    },
                ],
            },
        ],
        partsNeeded: [
            {
                name: 'Chest belt for backpacks',
                url: 'https://www.vaude.com/de/de/12583-rucksack-brustgurt-25-mm.html#?colour=451&size=13424',
            },
            {
                name: 'Chest Belt for Kids',
                url: 'https://www.vaude.com/en-GB/Equipment/Spare-Parts-Accessories/Backpack-Spare-Parts/Chest-belt-20-mm-Kids',
            },
        ],
        toolsNeeded: [],
    },
    '69137': {
        title: 'How do I repair a two-way zip slider on my jacket on a split zip?',
        guideId: '69137',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/YUnbHtRArnHNQ2KY.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '10 minutes',
        },
        introduction:
            '<p>A two-way zip (two sliders for opening the zip from either the top or the bottom) can sometimes create a split zip where the teeth no longer align with each other. We’ll show you how to fix this in just a few easy steps.</p>',
        steps: [
            {
                title: '',
                orderby: 1,
                stepid: 139088,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/gyYfKkM5hWIgRH2b.full',
                            width: 1920,
                            height: 1080,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/hZBuEq1J2npDLf4U_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Carefully open the zip using the seam ripper.',
                    },
                ],
            },
            {
                title: '',
                orderby: 2,
                stepid: 139089,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/HGOmxakBnEEhxceZ.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/EIRrYsHphvKATjSc.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/5YDniJyeno2HS2NU.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Guide the slider back onto the end of the zip.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Pull the lower slider up a bit so that there’s an “opening” between the two sliders.',
                    },
                ],
            },
            {
                title: '',
                orderby: 3,
                stepid: 139090,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/PELQwac1N1Z1qtH5.full',
                            width: 1920,
                            height: 1080,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/hBD4yBc1GPVelFmy_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'With a small jerk, pull the upper zip slider downwards.',
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Seam Ripper',
                url: 'http://www.amazon.com/Clover-463-Seam-Ripper/dp/B000YZ8MVU/ref=sr_1_1?ie=UTF8&qid=1346440445&sr=8-1&keywords=seam+ripper',
            },
        ],
    },
    '69138': {
        title: 'How can I resew a loose Velcro on a jacket?',
        guideId: '69138',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Moderate',
            timeRequired: '20 minutes',
        },
        introduction:
            '<p>If a seam on your Velcro (i.e. on the flap of your jacket) comes loose, it is relatively easy to sew it back on. You’ll need to be able to use a sewing machine.</p>',
        steps: [
            {
                title: 'Variations',
                orderby: 1,
                stepid: 139091,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'If the Velcro comes loose on the outer flap of your jacket, you can easily sew it back on using matching thread. If the loop side of the Velcro comes loose, it’s a little harder to fix. We’ll show you in detail how to accomplish this.',
                    },
                ],
            },
            {
                title: '',
                orderby: 2,
                stepid: 139092,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/3LMrNCxUQCKFWIXx.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/J2VSOFswc2NYEQon.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Using the seam ripper, completely take out the seam of the jacket flap for about 10 cm where the Velcro is loose.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'The more of the seam you take out, the better the new sewing on the Velcro will be.',
                    },
                ],
            },
            {
                title: '',
                orderby: 3,
                stepid: 139093,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/sxrUJiRPZsTX1GFS.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/1OHysEJLZbALlIHl_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Thread the sewing machine with matching thread and place the sewing machine foot as near to the edge of the Velcro as possible. Sew until you get to the corner.',
                    },
                ],
            },
            {
                title: '',
                orderby: 4,
                stepid: 139094,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/t2WgV2bfbuHxKywZ.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/WemRy5EE5JFTVkKE_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Stop at the first corner and lift the sewing machine foot, leaving the needle in the Velcro. Turn the jacket, put down the foot and continue to stitch.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'When you get back to where you started, lock down the thread by making a few stitches back and forth.',
                    },
                ],
            },
            {
                title: '',
                orderby: 5,
                stepid: 139095,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/1cxItRmEcZxcUAbU.full',
                            width: 1280,
                            height: 720,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/EGT1ITDhgGQMOwTS_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'To finish the repair, resew the opened flap.',
                    },
                ],
            },
        ],
        partsNeeded: [
            {
                name: 'Passender Klett',
                url: '/Item/Passender_Klett',
            },
        ],
        toolsNeeded: [
            {
                name: 'Yarn',
                url: '/Item/Yarn',
            },
            {
                name: 'Sewing Machine',
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
            },
            {
                name: 'Seam Ripper',
                url: 'http://www.amazon.com/Clover-463-Seam-Ripper/dp/B000YZ8MVU/ref=sr_1_1?ie=UTF8&qid=1346440445&sr=8-1&keywords=seam+ripper',
            },
            {
                name: 'Scissors',
                url: 'https://eustore.ifixit.com/products/utility-scissors',
            },
        ],
    },
    '69141': {
        title: 'What’s the right way to wash and dry my down jacket?',
        guideId: '69141',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '2 hours',
        },
        introduction:
            '<p>Thermal appeal with down fill has to be treated specially. The general rule is: was as little as possible and as often as necessary. Use liquid detergent for fine wash or for functional clothing. We recommend Nikwax Down Wash.</p>',
        steps: [
            {
                title: 'Read the care label',
                orderby: 1,
                stepid: 139099,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'There are symbols for the recommended care products on the smaller label. We recommend Nikwax Down Wash for the washing cycle and TX Direct Spray from Nikwax for waterproofing if necessary.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            "Down jackets usually are washed at 30 °C with a gentle cycle. You'll find detailed information on the sewn in care label.",
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Details to the care symbols for washing, bleaching, drying or ironing you will find here: <a href="http://help.vaude.com/care-instructions/?lang=en" rel="nofollow noopener noreferrer" title="http://help.vaude.com/care-instructions/?lang=en">http://help.vaude.com/care-instructions/...</a>',
                    },
                ],
            },
            {
                title: 'Washing',
                orderby: 2,
                stepid: 139100,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/h6hsqlvv126AV3Vx.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/ygQNXTVZTh5DOVdI.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LQyYpT1lRUMU6NSS.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Set your washing machine to a gentle cycle with extra rinse and the recommended temperature. If you are using NIKWAX Down Wash, you can omit the extra rinse cycle. The extra rinse cycle is important for ensuring that no residues are left behind on the product.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Please always close buttons, zippers and Velcro closures before washing. This keeps fibers from catching and the clothing from stretching out during the wash cycle.',
                    },
                ],
            },
            {
                title: 'Drying',
                orderby: 3,
                stepid: 139101,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/GAJGOQXBKDPNxqOV.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/L1VPctBfCE5QfbpF.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'After the wash cycle, put the down jacket into the dryer with 2 to 3 tennis balls. Dry for 40 – 60 minutes at a low temperature using the gentle cycle.',
                    },
                    {
                        level: 0,
                        textMarkup: 'The tennis balls will restore the loft in your jacket.',
                    },
                ],
            },
            {
                title: 'Waterproofing',
                orderby: 4,
                stepid: 139103,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/eCpxIBSaSgCd3XM1.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/bVL4xiELOeHEQxkH.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Hang the jacket up on a clothes hanger, close all zips and spray evenly with the waterproofing spray. This keeps the inside untreated so that moisture can be absorbed and transferred outward.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Please follow the instructions printed on the care product.',
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Liquid light-duty or functional detergent',
                url: 'https://www.vaude.com/de-DE/Wo-kaufen/Finde-einen-Haendler/Online-Haendler/',
            },
        ],
    },
    '69150': {
        title: 'How do I wash and dry my membrane jacket or trousers?',
        guideId: '69150',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '2 hours',
        },
        introduction:
            '<p>In general: wash as little as possible and as often as necessary. Please follow the instructions on the product’s care label. The following is an example of what steps to wash a waterproof membrane jacket the best way. Only use liquid detergent for fine wash or for functional clothing. Even better is functional detergent like Nikwax Tech Wash.</p>',
        steps: [
            {
                title: 'Read the care label on the product',
                orderby: 1,
                stepid: 139106,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/xTdZUtsb6KeQwPgP.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/J1DlapeYXhHfFDTo.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Set the washing machine to 30 °C with a gentle cycle. There are symbols for the recommended care products on the smaller label. We recommend Nikwax Tech Wash for the washing cycle and TX Direct Spray from Nikwax for waterproofing if necessary.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Details to the care symbols for washing, bleaching, drying or ironing you will find here: <a href="http://help.vaude.com/care-instructions/?lang=en" rel="nofollow noopener noreferrer" title="http://help.vaude.com/care-instructions/?lang=en">http://help.vaude.com/care-instructions/...</a>',
                    },
                ],
            },
            {
                title: '',
                orderby: 2,
                stepid: 139107,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/fMgeHZ63p1iDrXSM.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Fill the washing machine. For optimal results you should wash similar colours together.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Please always close buttons, zippers and Velcro closures before washing. This keeps fibers from catching and the clothing from stretching out during the wash cycle.',
                    },
                ],
            },
            {
                title: 'Washing',
                orderby: 3,
                stepid: 139108,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/QHRbfO35Q4cqvn1W.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/S3L4tNJa6jWX6LqC.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Set the washing machine to the cycle and temperature recommended on the apparel. In our example it is 30 °C and a gentle cycle. Put the correct amount of liquid in the dispenser depending on the hardness of your water. You can find out how much you should use by reading the instructions on the care product.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Do not use fabric softener or bleach!',
                    },
                ],
            },
            {
                title: 'Drying',
                orderby: 4,
                stepid: 139109,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/F32CJ6uJaJCfIIN4.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'For optimal reactivation of the waterproofing (DWR), put the clothing into the dryer for 30 to 50 minutes after washing',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Alternatively, you can air dry the clothing in a warm and dry place, however, the waterproofing will not be reactivated.',
                    },
                ],
            },
            {
                title: 'Waterproofing if necessary',
                orderby: 5,
                stepid: 139110,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/IZxXBgT1jHejnZCT.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'When your jacket is dry, you can do a water beading test to find out if you need to re-waterproof it. Water should pearl up into beads on the surface of the jacket. To waterproof, we recommend TX-Direct Spray from Nikwax. Spray the outside of the clothing evenly. This leaves the inside untreated to that it can absorb moisture and move it outward.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Please follow the instructions printed on the care product.',
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Liquid light-duty or functional detergent',
                url: 'https://www.vaude.com/de-DE/Wo-kaufen/Finde-einen-Haendler/Online-Haendler/',
            },
        ],
    },
    '69155': {
        title: 'What’s the best way to wash and dry my softshell jacket?',
        guideId: '69155',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '2 hours',
        },
        introduction:
            '<p>In general, waterproof, water repellent and windproof performance apparel  (Sympatex, Ceplex and Windproof products) should be washed as rarely as possible and as often as necessary. Please follow the instructions on the product’s care label. Only use liquid detergent for fine wash or for functional clothing. Even better is functional detergent like Nikwax Tech Wash.</p>',
        steps: [
            {
                title: 'Read the care label on the product',
                orderby: 1,
                stepid: 139111,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/eJASQLdYQnIYkk5u.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Set the washing machine to 30 °C with a gentle cycle. There are symbols for the recommended care products on the smaller label. We recommend Nikwax Tech Wash for the washing cycle and TX Direct Spray from Nikwax for waterproofing if necessary.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Details to the care symbols for washing, bleaching, drying or ironing you will find here: <a href="http://help.vaude.com/care-instructions/?lang=en" rel="nofollow noopener noreferrer" title="http://help.vaude.com/care-instructions/?lang=en">http://help.vaude.com/care-instructions/...</a>',
                    },
                ],
            },
            {
                title: '',
                orderby: 2,
                stepid: 139113,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/t4eeJVm41GUDqA32.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Fill the washing machine. For optimal results you should wash similar colors together.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Please always close buttons, zippers and Velcro closures before washing. This keeps fibers from catching and the clothing from stretching out during the wash cycle.',
                    },
                ],
            },
            {
                title: 'Washing',
                orderby: 3,
                stepid: 139115,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/n4R1qiJxOvHqr2Cw.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/fJS6kvdiQIYVhSuF.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Set the washing machine to the cycle and temperature recommended on the apparel. In our example it’s 30 °C and a gentle cycle. Put the correct amount of liquid in the dispenser depending on the hardness of your water. You can find out how much you should use by reading the instructions on the care product.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Do not use fabric softener or bleach!',
                    },
                ],
            },
            {
                title: 'Drying',
                orderby: 4,
                stepid: 139116,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/FHByxytKIW2MMyvU.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/VxCFAJveCibTIRoT.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'For optimal reactivation of the waterproofing (DWR), put the clothing into the dryer for 30 to 50 minutes after washing. Alternatively, you can air dry the clothing in a warm and dry place, however, the waterproofing will not be reactivated.',
                    },
                ],
            },
            {
                title: 'Waterproofing if necessary',
                orderby: 5,
                stepid: 139117,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/WlrtOsIr2WgiyKNw.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'If the waterproofing has been reduced with time, you can treat your jacket with a spray-on waterproofing. We recommend the spray on product from Nikwax for softshell products.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Please follow the instructions printed on the care product.',
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Liquid light-duty or functional detergent',
                url: 'https://www.vaude.com/de-DE/Wo-kaufen/Finde-einen-Haendler/Online-Haendler/',
            },
        ],
    },
    '71477': {
        title: 'How do I replace a sternum strap with a hydration tube holder on my backpack?',
        guideId: '71477',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '5 minutes',
        },
        introduction:
            '<p>If the sternum strap comes off of its rail or gets lost, it’s easy to replace. The following instructions will show you how you replace a sternum strap with a hydration tube holder.</p>',
        steps: [
            {
                title: '',
                orderby: 1,
                stepid: 142465,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'The sternum strap has come off on both sides of the backpack suspension system.',
                    },
                ],
            },
            {
                title: '',
                orderby: 2,
                stepid: 142466,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/K5OkAfRyWBAUVX1k.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/DmcY6VPtZIMU5wE4.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Guide the sternum strap onto the rail at the thin end.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Depending on where you want to attach your hydration tube, thread the sternum strap with the attachment onto the right or left rail.',
                    },
                ],
            },
            {
                title: '',
                orderby: 3,
                stepid: 142468,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/GqiMbWc1VVG4IJnI.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/PHsBTUbN35SYlQXu.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Guide the other side of the sternum strap onto the thin end of the rail and position it.',
                    },
                ],
            },
        ],
        partsNeeded: [
            {
                name: 'Chest Belt with Drinking Tube Mount',
                url: 'https://www.vaude.com/de/de/12783-chest-belt-rucksack-brustgurt-15-mm.html#?colour=484&size=13424',
            },
        ],
        toolsNeeded: [],
    },
    '74517': {
        title: 'How do I attach a new snap?',
        guideId: '74517',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/rTyQjDhPMJQmaMRE.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Moderate',
            timeRequired: '10 minutes',
        },
        introduction:
            '<p>During prolonged or heavy use, a snap can come off your clothing or gear and get lost. Not to worry – a worn-out or defective snap on a garment, tent, backpack or sleeping bag can easily be replaced.  Replacement snaps  often come with the product in a small bag. If you don’t have a replacement snap, you can pick one up in a fabric supplies store – they come in several shapes and sizes and fit almost anywhere. Here we’ll show you an example of how you can replace a snap on a pair of trousers.</p>',
        steps: [
            {
                title: '',
                orderby: 1,
                stepid: 150462,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/YdlMWLUvCv4VryPM.full',
                            width: 1920,
                            height: 1080,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/ImlcjJMPIKVYxgyT_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'In our example, the snap stud is missing. The snap post can be removed with the pliers.',
                    },
                ],
            },
            {
                title: '',
                orderby: 2,
                stepid: 150463,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/OJCqHTcqNZZAgyQ2.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Lay out the two pieces for the lower flap.',
                    },
                ],
            },
            {
                title: '',
                orderby: 3,
                stepid: 150465,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/phS3TCjHRM5wQPjJ.full',
                            width: 1920,
                            height: 1080,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/AQP1vBeq2wa4kdbQ_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Place the stud component on the lower flap where the previous stud was positioned and hold it there with a plastic snap holder if necessary. Rivet the two parts together with a few strong blows of the hammer.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Alternatively, you can purchase a hand press for snaps at a sewing supplies store.',
                    },
                ],
            },
            {
                title: '',
                orderby: 4,
                stepid: 150466,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/rR2WuiWMHFTXkiII.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Lay out the other two pieces for the top flap.',
                    },
                ],
            },
            {
                title: '',
                orderby: 5,
                stepid: 150467,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/O4IOZFmgVAufcbQq.full',
                            width: 1920,
                            height: 1080,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/ekg5OGYnjQiIl5uc_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Place the post part of the snap on the top flap where the previous post was positioned and attach it with a plastic holder if necessary. Rivet the two parts together with a few strong blows of the hammer.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Alternatively, you can purchase a hand press for snaps at a sewing supplies store.',
                    },
                ],
            },
        ],
        partsNeeded: [
            {
                name: 'Druckknopf-Set von Prym',
                url: '/Item/Druckknopf-Set_von_Prym',
            },
        ],
        toolsNeeded: [
            {
                name: 'Beißzange',
                url: '/Item/bei%C3%9Fzange',
            },
            {
                name: 'Hammer',
                url: 'https://smile.amazon.com/-/en/Hammers/b/?ie=UTF8&node=553190',
            },
        ],
    },
    '74532': {
        title: 'How do I patch a garment?',
        guideId: '74532',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/nshdoEAeS5MjKUHh.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Moderate',
            timeRequired: '30 minutes',
        },
        introduction:
            '<p>Elbows and knees are classic areas of wear on garments. With the right fabric patch, you can hide the damage and make your product look great again. If you don’t have the same color as the garment for your patch, a contrasting color can also create an optical highlight. You can also use the same type of repair for a tear. In this example, we’ll show you how to do it.</p>',
        steps: [
            {
                title: '',
                orderby: 1,
                stepid: 150497,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/nMdELlPouY4GXirh.full',
                            width: 1920,
                            height: 1080,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/GnNOfjxarIfFoUGv_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Using a piece of tailor’s chalk, outline the size of the patch you’ll need to fully cover the area.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'In order to ensure that the patch looks good and fits the garment, take note of any seams or design elements you can use to design your patch.',
                    },
                ],
            },
            {
                title: '',
                orderby: 2,
                stepid: 150498,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/xJWVUjAVmo6ZSPsU.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Transfer the size and shape of the patch to an appropriate piece of fabric for the patch.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Add 1 cm for a seam allowance along the entire patch.',
                    },
                ],
            },
            {
                title: '',
                orderby: 3,
                stepid: 150499,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/MX3PblNLPGmYTOjq.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Cut along the outer line of the patch.',
                    },
                ],
            },
            {
                title: '',
                orderby: 4,
                stepid: 150504,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/YxZPB5xUvtKFFtJw.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Fold over the seam allowance on the patch and iron it down – this will help with sewing the patch on.',
                    },
                ],
            },
            {
                title: '',
                orderby: 5,
                stepid: 150506,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/uBfUMQJ5ZNA2aDMX.full',
                            width: 1920,
                            height: 1080,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/24ouPdXdM1sJl1RQ_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Lay the patch on the marked line and top stitch it to the fabric close to the edge of the patch.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'You can use pins to attach the patch to the jacket so it won’t slide around while you’re sewing it on.',
                    },
                ],
            },
            {
                title: '',
                orderby: 6,
                stepid: 150507,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/3KOca2qNvIwcqACW.full',
                            width: 1920,
                            height: 1080,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/rQZhaaYtKSIHhLZA_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Use a top stitch to sew around the patch, locking off the first and last stitches by stitching back and forth a few times.',
                    },
                ],
            },
            {
                title: '',
                orderby: 7,
                stepid: 150509,
                media: {
                    type: 'video',
                    data: {
                        image: {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LwoU2xK31OOkuZwK.full',
                            width: 1920,
                            height: 1080,
                        },
                        encodings: [
                            {
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                mime: 'video/mp4',
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/XvrFnFObpYMSaOOy_MP4_720.mp4',
                            },
                        ],
                    },
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Cut away the torn area inside the jacket along the new seam.',
                    },
                ],
            },
        ],
        partsNeeded: [
            {
                name: 'Patch',
                url: '/Item/Flicken',
            },
        ],
        toolsNeeded: [
            {
                name: 'Scissors',
                url: 'https://eustore.ifixit.com/products/utility-scissors',
            },
            {
                name: 'Sewing Machine',
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
            },
            {
                name: 'Yarn',
                url: '/Item/Yarn',
            },
            {
                name: "Tailor's Chalk",
                url: 'http://www.amazon.com/Clover-Triangle-Tailors-Chalk-White/dp/B000B836MA/ref=sr_1_1_m?s=arts-crafts&ie=UTF8&qid=1386713671&sr=1-1&keywords=tailors+chalk',
            },
            {
                name: 'Protractor',
                url: 'https://www.amazon.com/s/ref=nb_sb_noss_2?url=search-alias%3Daps&field-keywords=protractor&rh=i%3Aaps%2Ck%3Aprotractor',
            },
        ],
    },
    '151451': {
        title: 'How to repair a broken webbing in a sleeping bag',
        guideId: '151451',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Moderate',
            timeRequired: '20 minutes',
        },
        introduction:
            '<p>The lightweight fabrics on sleeping bags carry bags can often tear after repeated use of the compression straps.  This can make packing the sleeping bag more difficult, and less effective.</p>\n\n<p>This guide will show how to use a sewing machine to fix a torn seam between the fabric and the compression webbing. This is a simple fix for anyone with experience of sewing machines, but may be a bit challenging without sewing knowledge.</p>',
        steps: [
            {
                title: 'Broken Compression Strap',
                orderby: 1,
                stepid: 315116,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/QYCdQCZa6jfs4s22.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/HwKSAxMIBYHQso1y.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Find the location of the tear on your product. In best case, only the seam stitching should be broken, and no additional tears or damage to the webbing of fabric.',
                    },
                ],
            },
            {
                title: 'Turn the bag inside-out',
                orderby: 2,
                stepid: 315117,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/KcBfQbMoDMOx4442.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/1Vn4frf2uoca1WlG.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/mgjrN2SPVPiBtZwO.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Unpack the sleeping bag and set it aside. Turn the carry bag inside-out and inspect the damage from inside. The seam should be clearly visible and there should be no fraying on the fabric or the webbing - if there is, you should carefully trim off frayed edges so long as enough fabric remains to run through the sewing machine later.',
                    },
                ],
            },
            {
                title: 'Prepare the seam',
                orderby: 3,
                stepid: 315118,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/Ff4CLyVhHYbGuqeA.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/HMOqbOrJyLkqY1aV.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/vMQPjMBGMDuOXwcE.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Use pins to secure the webbing back into the correct place.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Check the other (unbroken) webbing seams for the correct positioning on top or between the seam fabrics. This is unique to each product, so take care to match what you have and not copying the example shown.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Trim away any lose threads or fraying.',
                    },
                ],
            },
            {
                title: 'Sewing the Seam',
                orderby: 4,
                stepid: 317523,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/2B1KM5laDAvHYYkT.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LQcABLSCF4nQfmXj.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/5I12FnYnHtaKDMD3.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Prepare your sewing machine and the area around it. Position the broken seam underneath the foot and pull the foot down.',
                    },
                    {
                        level: 0,
                        textMarkup: '<strong>Remove the pins before starting!</strong>',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Start at one end of the seam and end at the other. Turn the bag around 180 degrees and repeat the process back the other way. Continue to do this until the repair looks to be a similar (or stronger) strength to the original. Lock the repair at both ends with a lock stitch or back stitch. Check the repair and finally cut the thread.',
                    },
                ],
            },
            {
                title: '',
                orderby: 5,
                stepid: 317531,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/VlBgsSLVshjffxPb.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/xbQTOMysMbXuLZdY.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LXpKnuuJaJlOdx1m.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'You repair is finished! Check the repair strength by pulling an unbroken strap gently, and then pulling the repaired strap in the same way.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'When pulling the repaired strap, if it looks weak or strained, repeat step 4 again and add some additional stitching on the sewing machine.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            "Turn the bag back the correct way, and stuff the sleeping bag back in. If it looks unstrained and strong, you're ready to go!",
                    },
                    {
                        level: 0,
                        textMarkup:
                            '<strong>Note: </strong>it is normal for the repaired strap to be 5-10mm shorter after the repair, and this may be noticeable when the bag is packed.',
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Sewing Machine',
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
            },
        ],
    },
    '169514': {
        title: 'How to repair a hole in woven textile (using an iron-on patch)',
        guideId: '169514',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '20 minutes',
        },
        introduction:
            '<p>Accidents happen, and especially when hiking outdoors it\'s easy to tear a hole in woven items such a trousers. This guide will walk you through a simple and effective method of repairing a hole using an iron-on patch. Before you start, we recommend also looking at the guide for repairs using a self-adhesive patch, as a quick alternative when you\'re outside without access to an iron: <a href="https://www.ifixit.com/Guide/How+to+repair+a+hole+in+woven+textile+(using+a+self+adhesive+patch)/169940" target="_blank">How to repair a hole in woven textile (using a self adhesive patch)</a></p>',
        steps: [
            {
                title: 'Prepare and clean the area',
                orderby: 1,
                stepid: 359158,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/RY4DCUcRNux6Xe1x.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/2DXdoYPQEXaZCUPT.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Start by ensuring the damaged area is clean and free from any loose threads or debris.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Prepare the iron-on patch.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            '<em><strong>Note: </strong>in this guide we are demonstrating with a high-stretch patch, to match the stretch of the trousers. There are many patches available, and you should pick a quality closely matching the original fabric.</em>',
                    },
                ],
            },
            {
                title: 'Position the iron-on Patch',
                orderby: 2,
                stepid: 359297,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LyJBqykARZEL4RXu.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/PpAQe6uUsNPDCawy.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/EFR1WcfYKBZMjMs5.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'For this repair, we decided to put the patch on the inside of the garment; so the hole will remain slightly visible on the front but will be repaired.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Turn the item inside-out, and flatten the area to be repaired.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Cut the patch to size, ensuring it completely covers the damaged area. The patch should be 1cm larger than the hole on all sides.',
                    },
                ],
            },
            {
                title: 'Iron the patch',
                orderby: 3,
                stepid: 359298,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/plcCSB3nFDRtn6Zf.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/yESR4b4K2NLLZIkr.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/RMPvsKIplPnwVuEq.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Place the glue side of the badge against the fabric. This is often shiny, or with a different pattern.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Take care: </strong>If the patch is upside-down, the glue will melt directly to your iron and the patch will get stuck to it.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Preheat your iron to the maximum heat setting, suitable for your clothing item. Check the composition label for guidance and then adjust the iron accordingly.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Gently press the iron onto the cloth-covered patch, applying even pressure.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Move the iron in circular motions for about 15-30 seconds.',
                    },
                ],
            },
            {
                title: 'Allow to cool',
                orderby: 4,
                stepid: 359299,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/NUyQUMc3KSCOSNtx.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/qAvA1Zn4nxSXXPX3.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'After ironing, let the patch cool for a few minutes.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Once cooled, carefully check that the badge is properly fixed.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                    {
                        level: 0,
                        textMarkup:
                            "<em>... however! If you'd like to make the repair extra secure follow the next two steps too.</em>",
                    },
                ],
            },
            {
                title: 'Optional: Add a patch on the outside',
                orderby: 5,
                stepid: 359300,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/yoNETWkCyI5H1hHl.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/2ycnH4jpoXSLWEpO.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/nwmJWiaKpNI4DxZl.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'If you want to reinforce this repair, you can choose to add a additional patch on the outside.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'We suggest to cut a shape with rounded corners to improve the fixation and durability.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Get creative:</strong> You might also like to cut a customized shape such as a leaf, bear, or heart. Wear it as a badge of pride, to remember the adventure that caused it!',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 6,
                stepid: 360496,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/BhfLLeIbsxyZwFTf.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/nGAGMQWTQ2MkTETN.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/MsuCqbmtRVPcaMjV.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Iron-on fabric Patches',
                url: 'https://www.amazon.com/dp/B08429B5DP?psc=1&ref=ppx_yo2ov_dt_b_product_details',
            },
            {
                name: 'Steam iron',
                url: 'https://www.amazon.com/PurSteam-Professional-Steam-Iron-Display/dp/B07WJJTBP2/ref=sr_1_3?crid=2JURPKMRWKD8N&keywords=steam%2Biron&qid=1654207995&sprefix=steam%2Biron%2Caps%2C153&sr=8-3&th=1',
            },
            {
                name: 'Ironing board or heat-resistant surface',
                url: '/Item/Ironing_board_or_heat-resistant_surface',
            },
        ],
    },
    '169515': {
        title: 'How to repair a hole in knitted textile (hand stitching)',
        guideId: '169515',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/Hy2HBfnuwDBZ1aGJ.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '20 minutes',
        },
        introduction:
            "<p>When practicing your sport in summer or winter, it's easy to get a tear in your T-shirt, leggings or jacket. Knitted fabrics are very stretchy and the best way to repair a hole is to sew it by hand, as explained in this guide.</p>",
        steps: [
            {
                title: 'Identify the hole',
                orderby: 1,
                stepid: 359159,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/mYFYamNdXV32YIYd.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/v1h4vnSAw2CFFYoe.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/JQlfsPjgX3wyABZ1.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Lay the woven textile on a flat surface with the tear fully exposed.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Check how large the tear is, where it is on the item, and whether it is fraying at the edges.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            '<em>Consider: this repair will be visible at the end, so you might wish to use a matching thread colour - <strong>or</strong> - you might want to celebrate this memory with some contrast colour thread! Think about how you want the final repair to look before starting.</em>',
                    },
                ],
            },
            {
                title: 'Prepare the needle and start sewing',
                orderby: 2,
                stepid: 359303,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/FURxTGXh5HgtGgGw.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/uaTHH4eBbKytmkMA.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/4iPAv5DS4KSWfUpx.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Prepare the needle with the thread.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Turn the defective product inside-out, and pinch the broken edges together, so that they are facing towards you.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Pass the needle through both pieces of fabric, starting at one end of the hole. Tie a knot to secure it, and pull gently. Be careful not to bunch the fabric, especially if it is lightweight and thin.',
                    },
                ],
            },
            {
                title: 'Continue sewingalong the hole',
                orderby: 3,
                stepid: 359304,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/5uhyLDnFHOV2EDl1.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/XrkAaXAkZ2BMHXRS.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/HdrKXvCjKjqRwCAS.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Continue sewing along the hole with a regular sewing technique; passing the needle through both pieces of fabric from one side, then from next side, and so on.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Work your way along the seam until the entire hole is closed. Before Making a knot, check whether you think the repair is strong enough - if not, repeat the sewing procces back along the hole to the starting point.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Make a knot at the end of the repair.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 4,
                stepid: 359305,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/PsTEfhwBqT5iwMua.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/CgJaJaW5XLORSgmU.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/bKUUWvL6aRhc5gEu.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Make a final test of the repair by lightly stretching the area.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Trim off the excess thread',
                    },
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Thread',
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
            },
            {
                name: 'Fabric Scissors',
                url: 'http://www.amazon.com/Gingher-8-Inch-Knife-Dressmakers-Shears/dp/B000UU6SR4/',
            },
            {
                name: 'Hand Sewing Needle',
                url: 'https://www.amazon.com/SINGER-Large-Needles-Magnetic-Card-12/dp/B002I35QMO/ref=sxin_0_ac_d_rm?crid=2D44X2L0TRTDM&keywords=sewing+needles+hand&pd_rd_i=B002I35QMO&pd_rd_r=7dfc4a4d-85d9-4fa9-bae0-e641c5d5fa59&pd_rd_w=TG8zg&pd_rd_wg=IkY1h&pf_rd_p=91b604bb-c371-4573-970f-bed68a552852&pf_rd_r=14CZ6X5JHRAM9W311PP0&qid=1559661764&s=gateway&sprefix=sewing+needle%2Caps%2C148',
            },
        ],
    },
    '169517': {
        title: 'How to repair a trouser/cuff hem (without shortening)',
        guideId: '169517',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/IjmGvIxGPvQVM5uC.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '10 minutes',
        },
        introduction:
            '<p>During sport, the hem of your trousers or jacket sleeves may become damaged and frayed. This will often get worse if not fixed, so don\'t let it get to that stage! This guide is one of two options for fixing this defect, and which does not shorten the length of your item. If you\'d like to check the other option, please follow this link: <a href="https://www.ifixit.com/Guide/How+to+repair+a+trouser-cuff+hem+(by+shortening)/169526" target="_blank">How to repair a trouser/cuff hem (by shortening)</a>.</p>',
        steps: [
            {
                title: 'Identify the defect',
                orderby: 1,
                stepid: 359160,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/4KkRPSBfluFKPqFF.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/1K51QBhdVKiStyxF.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/R2ZNrFFCOmmXJfKu.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Identify the defective area and assess how large the damage is.',
                    },
                ],
            },
            {
                title: 'Prepare the stitching',
                orderby: 2,
                stepid: 359307,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/uJDIAAP2nIiNuHeD.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/qsjubA4ZE4joJNpO.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/eBPKLVlGiEbqHxqM.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Cut away any damaged thread around the hole, to create a clean surface to repair.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            "<strong>Take care: </strong>don't cut away fabric, as this will shorten the repair.",
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Turn the edges of the hole inside, so that they are tucked inside the hole.',
                    },
                ],
            },
            {
                title: 'Sew the repair',
                orderby: 3,
                stepid: 359308,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/fHTWoQZyNesoCkKT.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/uZGnpIMDJYkK5DPl.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/IIEEKdUIG3tmkhfd.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Pin the folded parts of the hole securely in place.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Prepare the sewing machine and offer up the repaired area.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip: </strong>For tips on setting up the machine take a look at <a href="https://www.ifixit.com/Guide/Thread+a+Sewing+Machine/19463" target="_blank">this iFixit guide</a>.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Depress the sewing machine foot and remove any pins in the way of the sewing route. Start sewing a straight line from one side of the hole to the other, close to the edge of the hem, and add a lock stitch at the beginning and end.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 4,
                stepid: 359309,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/ugJRatGo6IRChJYB.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/BX5pBoKNOk15rkQS.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/rm1HYQaYnoteaiS2.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Finish the sewing and cut the loose thread to make a neat finish.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Thread',
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
            },
            {
                name: 'Scissors',
                url: 'https://eustore.ifixit.com/products/utility-scissors',
            },
            {
                name: 'Sewing Machine',
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
            },
        ],
    },
    '169519': {
        title: 'How to replace a zipper slider',
        guideId: '169519',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '30 minutes',
        },
        introduction:
            "<p>Over time, the zipper puller of your item might get damaged or broken. This doesn't mean the end, and it doesn't need to be a difficult repair or replacement of the entire zip! In this guide, we will demonstrate a simple and practical method for replacing the zipper slider, and keeping your favourite item going.</p>",
        steps: [
            {
                title: 'Identify the problem',
                orderby: 1,
                stepid: 359162,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/tFo2NG3WvirdjPJd.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/o4HITiQbGLolKm1G.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Inspect your product to understand the way the zipper has been constructed, and what the defect is.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'In this example, the zipper sliding component is defective, and cannot slide properly anymore. It needs to be replaced.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Note:</strong> at this stage, it is important to determine which part of the zipper is broken. If there is something wrong with the zipper teeth (i.e., one tooth has snapped off) then this guide will not help solve the problem',
                    },
                ],
            },
            {
                title: 'Remove the zipper "top stop"',
                orderby: 2,
                stepid: 359312,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/jwTT4ZXpOOJguVTC.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/NAymaRrXPb6X2P5H.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/VNhSNb2Xh2HfJLkF.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'To perform this repair, we will first remove the &quot;top stop&quot; of the zipper. This is piece of plastic at the top of the zipper, which is slightly larger than the other zipper teeth.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Important:</strong> This procedure will permanently remove this part of the zipper and we will replace it with a thick knot instead. Do not perform this repair if you do not wish to cause this type of damage/repair.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Use sturdy scissors, or preferably pliers, to remove the top stop. Take care not to damage the surrounding material or zipper teeth; and take care with your fingers!',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Note: </strong>For this guide, we have demonstrated on a plastic top-stop. Some zippers may have a metal top-stop, which can be carefully removed by un-bending, and re-attached at the end.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Once the top stop is removed, keep it to one side, for referring to later when making the replacement knot.',
                    },
                ],
            },
            {
                title: 'Remove the defective slider',
                orderby: 3,
                stepid: 361529,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/ZnoZ2y3Ox3iQg3jw.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LHSxJvRwYfNM2YDX.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/iyfwFVHtaoGuNWrw.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Slide the defective slider up to the top of the zipper, and remove it.',
                    },
                ],
            },
            {
                title: 'Replace the zip slider',
                orderby: 4,
                stepid: 359313,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/MUSqCSIDacMwaHbJ.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/WplHlUGsnN52PQBp.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/wLS4f4P1KfokWwFa.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Remove any pullers/toggles/fasteners from the zipper puller, and set them aside. These can be reused later.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Find a new slider, matching with the zip tape size. Craft shops, sewing shops, or even second-hand shops are great places to find these.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip: </strong> to determine the zipper puller size, check for the number on the backside. For more information, check out the iFixit guide   <a href="https://www.ifixit.com/Wiki/Plastic_Tooth_Zipper_Size" target="_blank">Plastic Tooth Zipper Size</a>',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Take your new zipper puller, and insert it back onto the zip from the topside...',
                    },
                    {
                        level: 0,
                        textMarkup: '... and pull it down to the middle of the zip.',
                    },
                ],
            },
            {
                title: 'Tie a new "top stop" knot',
                orderby: 5,
                stepid: 359314,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/YaUO1LmVKQPVchyV.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/FkrgsXNXr4gcnMM6.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/qCcWuhC3mCSPscXj.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Because we removed the plastic top stop, we need to create a new one, which we will do by tying a thick knot. For this, we will use a sewing needle and thick thread. The knot must be thick enough to stop the slider at the tape end again.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Get creative: </strong>this knot will be visible, so might choose a matching thread colour to be less noticeable; or a bright contract colour to celebrate your repair!',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Start by putting the needle through the zipper tape, and making a standard hand-sewing knot. Then, keep repeating the process of sewing through the tape, to build a thicker and thicker bundle of thread.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            "<strong>Tip: </strong>periodically pull the zipper up to the repair, and gently test if the thread bundle is large enough to stop it coming off. If it's still too small, keep adding more thread.",
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Once the thread bundle is large enough to prevent the zipper puller from coming off, make a final securing knot, and cut the excess thread away.',
                    },
                ],
            },
            {
                title: 'Replace the zip puller',
                orderby: 6,
                stepid: 359315,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/E31ccN2ZrZCWxxIc.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/bxEMa2fnUoSovpQL.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/lPbLaEvfFYmN2CZy.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'The thread bundle is now complete, and will stop the new slider from coming off.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Re-attach any additional strong/thread/loops which were on the original zipper puller (here, we re-attached the pulling string).',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Check that the repair functions correctly by pulling the zip and and down. Put on the item and test it again.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 7,
                stepid: 361530,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/cr2qfIXDFCHBxkZo.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Thread',
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
            },
            {
                name: 'Hand Sewing Needle',
                url: 'https://www.amazon.com/dp/B002PI751C',
            },
            {
                name: 'Scissors',
                url: 'https://eustore.ifixit.com/products/utility-scissors',
            },
            {
                name: 'new zip slider',
                url: '/Item/new_zip_slider',
            },
        ],
    },
    '169520': {
        title: 'How to repair a broken seam',
        guideId: '169520',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/Lws1WjrIFJOjQH5J.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '20 minutes',
        },
        introduction:
            "<p>During sport, a strong stretch can damage the stitching on your jacket or T-shirt. Some seams may break under this heavy strain. Don't worry, they can be easily repaired with a sewing machine and you will be able to continue wearing your favorite outfit for more and more sports to come.</p>",
        steps: [
            {
                title: 'Identify the damaged seam',
                orderby: 1,
                stepid: 359163,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/xRcV5TJ2ASLNDRdB.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/nCWqvcpqWSruQZx3.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/hPQG3qjmSx3WkC5S.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Locate the damaged area of your item.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Check how it looks on the outside, and whether it is spreading further by gently pulling the seam.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Also check how the damage looks on the inside, and take note of the type of stitching, and fabric condition around the seam.',
                    },
                ],
            },
            {
                title: 'Prepare & repair',
                orderby: 2,
                stepid: 359316,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/R1v2C6NaAKXywC1e.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/TtdJb6REPINjvlEt.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/qBHBAa61RQvoNQVp.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Prepare and clean the area around the damaged seam by removing broken thread and trimming any loose threads.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Prepare the damaged seam by placing the fabric edge to edge.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Place the item under the sewing machine, and make a short back-and-forth lockstitch using the reverse lever, to secure one end. Then proceed to make a single long stitch along the seam until the end of the damage. Secure at the end with another locking stitch. Inspect the repair, and if it requires another long stitch, repeat the process.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip: </strong>check the iFixit <a href="https://www.ifixit.com/Topic/Basic_Sewing" target="_blank">basic sewing</a> skills page for more tips on sewing machine setup.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 3,
                stepid: 359317,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/lKZxGG3ILPGYTJkm.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LLQVAGmuOpJbrn4B.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/NKeVS4HNrRJ2XbUX.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Sewing Machine',
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
            },
            {
                name: 'Thread',
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
            },
            {
                name: 'Fabric Scissors',
                url: 'http://www.amazon.com/Gingher-8-Inch-Knife-Dressmakers-Shears/dp/B000UU6SR4/',
            },
        ],
    },
    '169521': {
        title: 'How to repair a taped seam',
        guideId: '169521',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/ChjQeDLNrHgxYWAl.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Very easy',
            timeRequired: '20 minutes',
        },
        introduction:
            "<p>After repeated use or washing of waterproof sports item, taped seams can become damaged as the glue wears away. This can reduce the waterproof performance and you may feel that it's time to buy a new item - but repair is possible! This guide will show how to repair defective taped seams, so that you can continue enjoying going outside in wet weather.</p>",
        steps: [
            {
                title: 'Preparation',
                orderby: 1,
                stepid: 359164,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/2d1BwhBvMnUSGFZ3.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/6jWePDBoD1Zc412S.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/2BjQfsqnD2lCmQAx.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Identify the defective taped seam, and the extent of the damage. Is it only a small area peeling up, or something larger?',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Note: </strong>We will be using a hot iron to re-weld the taped seam, so prepare a tidy area matching the size of your repair.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Lay a heatproof material underneath the item, such as an ironing board, or ironing board cover. Set the iron at 150 °C maximum.',
                    },
                ],
            },
            {
                title: 'Prepare the hot-melt tape',
                orderby: 2,
                stepid: 359318,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/yMQSh3n4mte3lBIy.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/OVFJEPZFhAMsEkCH.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/pMZyEXDDiEZLafIv.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            '<strong>Note: </strong> In this guide, we assume that the tape cannot be re-welded due to the glue being damaged or worn-out. But if you want to make as test first, move to the next step <em>without </em>adding extra thermo-tape.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Prepare the hot-melt tape by cutting a small piece to the same size as the defect. The tape should fit underneath the damaged tape, so that none is protruding out from the edges',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip: </strong>we have used a common form of curtain tape here, as it is widely available; but more advanced tapes are also available from sewing/craft stores.',
                    },
                ],
            },
            {
                title: 'Iron the taped seam',
                orderby: 3,
                stepid: 360409,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/lAELfEcCjWhLftqH.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/STQOmibXTyVOsV5g.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/PT6GifwWJLrsm1AY.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            '<em>We will now use the head from the iron to melt the hot-melt tape in between the broken seam layers, to bond them together again.</em>',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Carefully check the tape is underneath the old taped seam and not protruding out from the edges. Position the heated iron on top of this area, and apply light pressure, for up to 10 seconds.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Let the area cool down, and then check whether the hot-melt tape has melted and bonded the defect successfully. If it has not, apply the iron again, and increase the pressure a little. Continue this process until it is fully glued down.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip: </strong>you may find in this process, that some extra hot-melt tape is needed in some areas. simply cut some more small pieces and slide them between the seam, and repeat the steps above.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 4,
                stepid: 360410,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/5ltPT2vJOgflABZi.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Steam iron',
                url: 'https://www.amazon.com/PurSteam-Professional-Steam-Iron-Display/dp/B07WJJTBP2/ref=sr_1_3?crid=2JURPKMRWKD8N&keywords=steam%2Biron&qid=1654207995&sprefix=steam%2Biron%2Caps%2C153&sr=8-3&th=1',
            },
            {
                name: 'thermo glue tape',
                url: '/Item/thermo_glue_tape',
            },
            {
                name: 'Scissors',
                url: 'https://eustore.ifixit.com/products/utility-scissors',
            },
        ],
    },
    '169522': {
        title: 'How to repair a hook & loop fastening',
        guideId: '169522',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/fs3sMTpSdETipqlT.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '10 minutes',
        },
        introduction:
            "<p>Hook and loop fastenings are quick and convenient for adjusting cuffs and hems on many sportwear items. But sometimes, the fastening is so strong, that repeated use can break the surrounding stitching. Especially on children's items, this can become very frustrating. In this guide, we'll show you how to repair this quickly and easily.</p>",
        steps: [
            {
                title: 'Identify the defect',
                orderby: 1,
                stepid: 359165,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/UwA4jbZQ4vKsR1si.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/wLZKfYDnlmLEAXwG.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/IlNrWkhs4MHe2wZF.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Check the defective area, and assess how big the repair will need to be.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Is the hook &amp; loop part still mostly held in place with the original stitching? If so (like our example) then only a small repair is needed.',
                    },
                ],
            },
            {
                title: 'Prepare & repair',
                orderby: 2,
                stepid: 360420,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/KGg5SDsa2bkjErKx.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/RiINL4rpe5uSTsMY.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/Wl1fMlRgMcxQq5HB.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Cut away any damaged thread around the defect, to provide a clean base for the repair.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Then, position the item under the sewing machine, and make a small locking stitch with the forward &amp; reverse function.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip: </strong>use the iFixit <a href="https://www.ifixit.com/Topic/Basic_Sewing" target="_blank">basic sewing</a> resources for more help on sewing machine setup.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Continue sewing around the defective zone, following the line of the original thread.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 3,
                stepid: 360421,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/ehoPUMb1puQwVeIo.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/WyQdE4iSZXUvUEwH.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/xm1UxHIwN2gA2MUS.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                    {
                        level: 0,
                        textMarkup:
                            '<em>Note: we have used a contrast thread and a basic sewing pattern to demonstrate this repair. Bu you can have fun with bright colours, or interesting stitch patterns to further embellish your repair.  </em>',
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Sewing Machine',
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
            },
        ],
    },
    '169524': {
        title: 'How to replace a button',
        guideId: '169524',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Very easy',
            timeRequired: '5 minutes',
        },
        introduction:
            "<p>Don't worry about repairing a simple button on your shirt, trousers or any other sport items. They can often break off - but they're very easy to fix it again and again. This guide will show you how to do it with a simple needle and thread.</p>",
        steps: [
            {
                title: 'Prepare the area',
                orderby: 1,
                stepid: 359167,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/k4PZUvYP6KjWxgS4.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/3VdpVZ1VqsROCekl.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Identify where the button fell out. Clean up the area including removing any old thread which is still there.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Prepare the needle and thread, and the replacement button.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip: </strong>many garments come with a spare button on the inside, sewn to the care label. Check there, before buying a new button.',
                    },
                ],
            },
            {
                title: 'Hand sewing',
                orderby: 2,
                stepid: 359319,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/W1LBI2K1ypC2CAkP.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/FZixNlDyXtPrRSx2.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/oyXTEXFS5dATdWVS.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Start by threading the needle onto approximately 30cm of pre-cut thread. Next, tie both ends together to create a loop of thread with the needle on it.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            'Add a second or third knot over the first, to make it thicker. It should be large enough to not pull through the fabric.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Push the needle through the fabric, from the backside of the fabric and pull tight. Thread the needle through one hole of the button, from the backside.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Next, thread the needle back through the other (opposite) hole in the button, and then push through the fabric <ins>in the same location </ins>as the thread coming from the backside.',
                    },
                    {
                        level: 1,
                        textMarkup: 'Repeat the complete Step 2 again, to add extra strength.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            '<em><strong>Note: </strong>We have demonstrated on a 2-hole button. For a 4-hole button, simply repeat Step 2 for the other button holes.</em>',
                    },
                ],
            },
            {
                title: 'Secure with a knot',
                orderby: 3,
                stepid: 359320,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/6GbDnAgRkGd5lmHr.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/voTgtRRIsOVYsTEH.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/2HpGPbUmBoSGYKwb.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Once the button is secure, it is time to finish the repair on the inside, with a knot.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Carefully pass the needle through the loops of thread on the backside of the repair, taking care not to pass it through the fabric itself.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Careful: </strong>Do not pull tight! Leave a loop large enough to pass the needle through.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Pass the needle through the loop, and now pull tight to create the knot. Repeat this process once more to add a second knot, for extra security.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 4,
                stepid: 360196,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/4DG3cNRnNbACJf5x.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Hand Sewing Needle',
                url: 'https://www.amazon.com/SINGER-Large-Needles-Magnetic-Card-12/dp/B002I35QMO/ref=sxin_0_ac_d_rm?crid=2D44X2L0TRTDM&keywords=sewing+needles+hand&pd_rd_i=B002I35QMO&pd_rd_r=7dfc4a4d-85d9-4fa9-bae0-e641c5d5fa59&pd_rd_w=TG8zg&pd_rd_wg=IkY1h&pf_rd_p=91b604bb-c371-4573-970f-bed68a552852&pf_rd_r=14CZ6X5JHRAM9W311PP0&qid=1559661764&s=gateway&sprefix=sewing+needle%2Caps%2C148',
            },
            {
                name: 'Thread',
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
            },
            {
                name: 'Scissors',
                url: 'https://eustore.ifixit.com/products/utility-scissors',
            },
            {
                name: 'New button',
                url: '/Item/New_button',
            },
        ],
    },
    '169525': {
        title: 'How to repair hem/cuff binding',
        guideId: '169525',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/XCYJCgSWQ34Uyaq4.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '10 minutes',
        },
        introduction:
            '<p>Binding hems can break if they get stretched too much, and can easily become more and more frayed. This simple fix can repair the hem quickly and easily.</p>',
        steps: [
            {
                title: 'Identify the defect',
                orderby: 1,
                stepid: 359168,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/uYEYbij2G1NXrAee.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/fCEsMGwyMwtKnjjs.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/Tq1E6eRyYMsFZk1f.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Here, you can see the hole in the binding hem, where the stitching has broken.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'If the binding is undamaged, you can easily repair this defective hem.',
                    },
                ],
            },
            {
                title: 'Tidy up the area',
                orderby: 2,
                stepid: 361751,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/DQOx2XaSEoM3qTUM.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/kWTkcu1YDf4JoTb1.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/YWcBBorXqG4Hgmd1.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Cut away any broken threads, to clean the binding area.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Then, position the binding properly again along the sleeve hem.',
                    },
                ],
            },
            {
                title: 'Position the hem on the sewing machine',
                orderby: 3,
                stepid: 361752,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/BstZr5UnMbgqH5Wy.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/WfY6T11fhpknPylc.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/wpYGrRvT4BAjBVbu.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Remove the wide casing of the sewing machine, and slide the sleeve over the narrow part remaining.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Carefully line up the hem with the needle, and reposition the binding into the correct place.',
                    },
                ],
            },
            {
                title: 'Sew carefully',
                orderby: 4,
                stepid: 361755,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/PKmGcdsTZbRbBVhs.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/ES22ZoaN2MRwpkcW.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/sDUOSF4LAOZdcK1V.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Start sewing with a smooth tension to ensure the elasticity remains afterwards. Avoid bunching or over stretching the fabric.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Continue carefully until the end of the defective zone, and finish with a locking stitch.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Remove the item and check if the repair is holding, and whether the elasticity still stretches correctly.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 5,
                stepid: 361756,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/ZIcVFF6BChNoDFN3.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/tnPBQPqB6JCXKJGc.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Sewing Machine',
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
            },
            {
                name: 'Thread',
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
            },
        ],
    },
    '169526': {
        title: 'How to repair a trouser/cuff hem (by shortening)',
        guideId: '169526',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Moderate',
            timeRequired: '30 minutes',
        },
        introduction:
            '<p>During sport, the hem of your trousers or jacket sleeves may become damaged and frayed. This will often get worse if not fixed, so don\'t let it get to that stage! This guide is one of two options for fixing this defect, and will result in the leg/sleeve becoming shorter. If you prefer not to make the item shorter, please check the other guide: <a href="https://www.ifixit.com/Guide/How+to+repair+a+trouser-cuff+hem+(without+shortening)/169517" target="_blank">How to repair a trouser/cuff hem (without shortening)</a>.</p>',
        steps: [
            {
                title: 'Defective hem',
                orderby: 1,
                stepid: 359169,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/DBQ41wFh5wIbcSwT.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/vyRTXUUcfySH3akU.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Identify the defective area and assess how large the damage is.',
                    },
                ],
            },
            {
                title: 'Prepare the hem',
                orderby: 2,
                stepid: 360200,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/AtrUDMCiL2xA6ZPb.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/HULHLxn2jIVYSD15.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/GNSQrfHNroaGpuXv.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Use a &quot;seam ripper&quot; tool to unstitch the original hem.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Unfold the hem.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Draw line above the defective part that you want to remove.',
                    },
                ],
            },
            {
                title: 'Cut and invert the item',
                orderby: 3,
                stepid: 359321,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/AHmnAUY3vY2biHBo.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/1KFJ4Rb3EqrQqbQe.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/QYQN3UQW5GgLBdhA.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Cut along the line and set the removed piece aside.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Turn the item inside-out.',
                    },
                ],
            },
            {
                title: 'Mark out the new hem',
                orderby: 4,
                stepid: 359322,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LRZXx1RHA4APpLyZ.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/fRqnKLiUkZZ5PRQe.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/EdeUXtytYJNDZFeD.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Draw a 1st line 2 cm from the edge -  and a 2nd line with 2cm on top of it.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Fold the new hem twice on those lines.',
                    },
                ],
            },
            {
                title: 'Iron and pin the hem',
                orderby: 5,
                stepid: 360203,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/MaLtPVRSEBFVUHGU.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/Dbx426ooAVnjoxaa.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/VK3wuJ2xvXYDrPXp.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Set up the iron on moderate heat without stream',
                    },
                    {
                        level: 0,
                        textMarkup: 'Fixed the folded hem by ironing and then with pins.',
                    },
                ],
            },
            {
                title: 'Sewing the new hem',
                orderby: 6,
                stepid: 360204,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/Ig4jRBLFIGU6gAyC.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/WmuE2154WZouV2Zo.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/hyYNKKAkneyDXP4k.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Use a sewing machine to fix the hem.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Stitch 2mm from the edge and add a locking stitch at both ends.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Stitch the 2nd line parallel with the first.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Cut the extra thread to make a clean finished seam. Turn the item right-way-round.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 7,
                stepid: 360205,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/MN4MtXvPglDHjp6v.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/ecQnQPrOBDa3T5aH.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/r4WlPkZLArPfOmdC.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Pen',
                url: 'https://www.amazon.com/Pilot-Retractable-Premium-Roller-Point/dp/B0030F32L6/ref=sr_1_8?crid=1ZF5SMXOJHXJF&keywords=pen&qid=1693498763&sprefix=pen%2Caps%2C95&sr=8-8',
            },
            {
                name: 'Ruler',
                url: 'https://www.amazon.com/Officemate-Classic-Stainless-Measurements-66612/dp/B00IXLPO8G/ref=sr_1_7?crid=6EUBJDC52GJT&keywords=ruler&qid=1704926908&sprefix=ruler%2Caps%2C155&sr=8-7',
            },
            {
                name: 'Fabric Scissors',
                url: 'http://www.amazon.com/Gingher-8-Inch-Knife-Dressmakers-Shears/dp/B000UU6SR4/',
            },
            {
                name: 'Sewing Machine',
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
            },
            {
                name: 'Steam iron',
                url: 'https://www.amazon.com/PurSteam-Professional-Steam-Iron-Display/dp/B07WJJTBP2/ref=sr_1_3?crid=2JURPKMRWKD8N&keywords=steam%2Biron&qid=1654207995&sprefix=steam%2Biron%2Caps%2C153&sr=8-3&th=1',
            },
            {
                name: 'unstitching small cutter',
                url: '/Item/unstitching_small_cutter',
            },
        ],
    },
    '169527': {
        title: 'How to remove pilling',
        guideId: '169527',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Very easy',
            timeRequired: '5 minutes',
        },
        introduction:
            '<p>Some fabrics can degrade over time with pilling. But this could be removed with a very easy process.</p>\n\n<p>Pilling often appears on winter products like knitted jumpers, merino wool sweaters, knitted 2nd layers like fleece jackets - but can sometimes also be visible on cotton or polyester T-Shirts.</p>\n\n<p>With this repair guide, you will be able to refresh your damaged products to look as good as new!</p>',
        steps: [
            {
                title: 'Material preparation',
                orderby: 1,
                stepid: 359170,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/ZkCHZWEapYLbqLNG.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/n3PCG2eSYRigUts4.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Lay out the garment on a clean surface. Prepare the pilling shaver.',
                    },
                ],
            },
            {
                title: 'Remove the pilling',
                orderby: 2,
                stepid: 359323,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/w4MMUMKpnIlPfjwK.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/TaLNaydLsPYH5mKC.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/SWpdcCI26KmmqjFe.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Smooth the garment to make sure it is flat before starting.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Make little circles with light pressure on the damaged fabric. The pilling will be cut away by the shaver.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 3,
                stepid: 360197,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/3VEfdfdU5TwHGFDc.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/VbrIwh3fOxqZkJqk.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/MB2bnmqLFjl1GYmY.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                    {
                        level: 0,
                        textMarkup:
                            '<em>Be sure to clean the lint from inside your shaver after use.</em>',
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Pilling Shaver',
                url: '/Item/Pilling_Rasierer',
            },
        ],
    },
    '169528': {
        title: 'How to repair loose threads',
        guideId: '169528',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/yHocBdXgHgQOVw1N.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '5 minutes',
        },
        introduction:
            "<p>Knitted beanies or pullovers are easy to snag during use, resulting in a loose thread loop. But it's not serious, and not hard to fix - follow this guide to find out how!</p>",
        steps: [
            {
                title: 'Prepare the repair',
                orderby: 1,
                stepid: 359331,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/jXeHWAFNJlXKMLjS.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LE3UnCoGA5AeQLEI.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/4ReoGdZyRLdClCiM.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Identify the snag',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Prepare your needle - for this guide we are using a specialized &quot;snag repair needle&quot; which has a textured end. These are available from most sewing shops.',
                    },
                ],
            },
            {
                title: 'Pull snag loop through',
                orderby: 2,
                stepid: 359332,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/TbL1KInb3eQGUSTM.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/cMWQeran2ItANe3r.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/vZZtjcuSwBBgkcTh.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Pass the needle through the bottom of the snag loop.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Wrap the thread around the sharp end of the needle.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Then, pass the needle through the fabric. The snag loop will also be pulled through by the textured part of the needle. Keep pulling it through, until both the needle and the snag loop are on the inside of the item.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 3,
                stepid: 359333,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/STNdOTaXrLDjGaAW.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/vjDhrSIvmwMeRM1j.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/rHqOeoCE3VeAhe2O.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Pull tightly on the thread to secure it to the back of the fabric.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Then, reposition the fabric appearance on the front, by stretching it to balance the tension.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Thread',
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
            },
            {
                name: 'snag needle',
                url: '/Item/snag_needle',
            },
        ],
    },
    '169531': {
        title: 'How to repair a "lost" drawstring',
        guideId: '169531',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Very easy',
            timeRequired: '5 minutes',
        },
        introduction:
            "<p>The adjustment drawstring on hoodies or shorts can easily get “lost” in the hem tunnel - especially if the knot has come loose during washing. But no problem: it's easy to repair, and doesn't take long at all!</p>",
        steps: [
            {
                title: 'Identify the lost drawstring',
                orderby: 1,
                stepid: 359481,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/f2aexAURbiFnPK3W.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/HocT4pOn324hcSr3.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "One end of the drawstring has got lost inside the hem tunnel and you can't get it back.",
                    },
                ],
            },
            {
                title: 'Pull out the drawstring completely',
                orderby: 2,
                stepid: 361274,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/vkxgKAytMA1FFOBs.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/ZLajSfVojEhEUtnQ.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/MpJVFJUPSGjPvinZ.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Pull it on the drawstring and take it out completely.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Note: </strong>some drawstrings are sewn in place at the back, for safety reasons. If your item has this, simply remove this stitch with small scissors or a seam ripper.',
                    },
                ],
            },
            {
                title: 'Attach the safety pin to the drawstring',
                orderby: 3,
                stepid: 359482,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/vOVgNTWPTYxLeUx3.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/IJYvhGWSXCyLoHCL.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/xaBWJ6UQXUE16sxV.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Locate the knot at one end of the drawstring; or if there is no knot, make one.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Push the safety pin through the knot and re-clasp it.',
                    },
                ],
            },
            {
                title: 'Pull the drawstring through the hem tunnel',
                orderby: 4,
                stepid: 359483,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/PmCgo2KTQdgQrLQf.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/eXoWVAkx5SAc4b4N.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/23AA6U2AbDuqPXpr.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Push the safety pin into one of the holes in the hem tunnel.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Push the pin as far as possible, and then hold it in place through the fabric. With the other hand, bunch the hem tunnel towards the first hand.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Slide the pin into the bunched hem tunnel, and try to pinch it with the receiving hand. Now, let go of the pin with the first hand, and use this hand to pull the hem tunnel taught.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'When done correctly, the pin will transfer from one hand to the next hand, slowly moving along the hem tunnel with every bunching-tautening motion.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Keep repeating this action until the pin reaches the other end of the hem tunnel. Now, pull out the pin and drawstring together.',
                    },
                ],
            },
            {
                title: 'Rearrange the drawstring',
                orderby: 5,
                stepid: 361275,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/HqLCVNwaP2iaJSMi.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/CduuRiQQ6vMvunFO.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/6JoWfXGlVy2Hvk2k.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Fully remove the drawstring end, and pin, from the hem tunnel',
                    },
                    {
                        level: 0,
                        textMarkup: 'Remove the safety pin from the drawstring',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Re-center the drawstring by pulling on each side appropriately',
                    },
                    {
                        level: 1,
                        textMarkup:
                            "<em><strong>Note: </strong>if you don't want to re-sew the security stitch at the back, then you're done! ... 🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!</em>",
                    },
                ],
            },
            {
                title: 'Optional: Re-sew the security stitch',
                orderby: 6,
                stepid: 361276,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/OAdCRO1hNd3YCJMg.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/xcfkFY5VlavGZlpR.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/kufXKZMVOp5nxwFj.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'If you wish, the cord drawstring can be re-attached at the back of the item with a security stitch.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            'This can also be a useful step for drawcords without knots, to help them stay in place during washing.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Place the item under the sewing machine',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Make a short straight stitch over the drawcord, to attach it to the fabric. Repeat 1-2 times for extra security and finish with a lock stitch',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 7,
                stepid: 361277,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/2pMyfddXKAetpnus.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/DRgIOwb4yWGE3Zs1.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/KVujRCCuMBIGjrgm.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Safety Pin',
                url: 'http://www.amazon.com/Singer-Assorted-Safety-Multisize-50-Count/dp/B000PSJSRI/ref=sr_1_1?ie=UTF8&qid=1377552190&sr=8-1&keywords=safety+pin',
            },
            {
                name: 'Sewing Machine',
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
            },
        ],
    },
    '169532': {
        title: 'How to repair a hole in a knitted fabric (using darning)',
        guideId: '169532',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Moderate',
            timeRequired: '10 minutes',
        },
        introduction:
            '<p>A hole in knitted sports items can easily appear after snagging it on something sharp, especially winter items such as beanies or jumpers.</p>\n\n<p>Here we will explain how to perform a repair using a standard darning technique; and demonstrate with a contrast colour thread to make the photos clearer. Depending on your preference, you might choose a matching thread colour to hide the repair, or a contrast thread colour to celebrate your repair and the adventure which made the hole!</p>',
        steps: [
            {
                title: 'Prepare the repair',
                orderby: 1,
                stepid: 359173,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/KsQDIa1Q2xRqijEA.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/TAWtVKP1LyrdJKrZ.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Prepare a clean and uncluttered space to perform the repair. Find the hole in your item of clothing, and inspect it noting the size and shape, and the stretch and thickness of the surrounding fabric.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Choose your thread colour, and prepare the needle and darning egg.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Note: </strong>in this guide we use a darning <em>egg</em>, but darning mushrooms and many other options are also available.',
                    },
                ],
            },
            {
                title: 'Start darning',
                orderby: 2,
                stepid: 359310,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/qwmfvkyEM5ZOonS1.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/ipwVXpg2OlSHbPAQ.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/aUsO1QhXGVHmsKOt.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Position the egg underneath the hole.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Hold the fabric around the egg, so that both the egg and fabric create a stable and secure position to work on. Do not apply excess pressure or tension to the fabric - the hole size should not increase.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Start the repair on one end of the hole, and position the needle to one side of the hole - at an area which is not broken. Thread the needle into the fabric from the bottom side of the hole, and exit the fabric at the top side of the hole, to create the first vertical stitch.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip: </strong>the darning technique will gradually build up a series of interlocking vertical and horizonal stitches to fill the hole. This is the first one!',
                    },
                ],
            },
            {
                title: 'Continue darning vertically',
                orderby: 3,
                stepid: 361517,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/BBhWCJBmMKmpH3pc.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/5V1jjZ25TRiHjQ4x.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/SJRVqDAsOaKGTOSJ.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'After the first vertical stitch, there is no need to make a knot at the end of the thread; just leave it free.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Make a second vertical stitch through the fabric and back out in the vertical direction, but this time downwards.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Continue with the same &quot;up and down&quot; process, spacing the stitches in regular intervals all along the hole.',
                    },
                ],
            },
            {
                title: 'Start darning horizontally',
                orderby: 4,
                stepid: 361518,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/mvuWJXvfTOhHyHFS.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/OYeJWhHETnRx2RNE.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/SVShCDyWRiI4CRVw.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'You should now have completed the vertical stitching. Check that the vertical stitches are evenly spread, and not too spaced out before continuing.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip: </strong>if needed, add extra vertical stitches to fill in big gaps, or even repeat the whole vertical stitching process again to add a second row of vertical stitches. The amount of stitches will determine the density and durability of the repair, and is unique to each item/fabric.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            "Once you're satisfied with the vertical stitches, begin adding the horizontal stitches. For this, start again at one side of the hole and start stitching in the opposite orientation to the first set of stitches. Turn the product 90 degrees if it helps.",
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Important: </strong>the horizontal stitches must weave between the vertical stitches. To do this, thread the needle under and over each vertical stitch in an alternating fashion.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Proceed with this &quot;under and over&quot; action as you continue across the hole, until the hole is fully covered.',
                    },
                ],
            },
            {
                title: 'Complete the darning',
                orderby: 5,
                stepid: 359311,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/UpTOnfqQwfCNVwgB.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/q6nS2reipRWG1R1o.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/O2ZVZgTUGCU1tOQt.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Continue the horizontal stitching until the hole is fully covered.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Try to finish the last horizontal stitch, so that the thread-end is at the opposite corner of the starting point.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip:</strong> If you ended up on the same side as the starting point, simply make one more horizontal stitch.',
                    },
                ],
            },
            {
                title: 'Tidy the repair',
                orderby: 6,
                stepid: 361519,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/PCv6IVBF6vHtK2Nf.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/ulDGhXgf4U2OBO3K.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/cQhgmCDxEFlJyXIt.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Inspect your repair, and ensure there are no further stitches to be made before cutting the threads. Then, make a double stitch at the final point, to secure the thread - there is no need to make a knot.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Carefully cut one loose thread...',
                    },
                    {
                        level: 0,
                        textMarkup: '... and then carefully cut the other loose thread.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Flatten down the edges of the repair and smooth the entire repaired area.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip: </strong>flatten item on a hard   and flat surface, and use the rounded side of your darning egg or mushroom to firmly apply pressure on a circular motion to the repaired areas, to smooth down the edges.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 7,
                stepid: 361520,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/AiGAnBkOwqRahxgj.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Thread',
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
            },
            {
                name: 'Hand Sewing Needle',
                url: 'https://www.amazon.com/SINGER-Large-Needles-Magnetic-Card-12/dp/B002I35QMO/ref=sxin_0_ac_d_rm?crid=2D44X2L0TRTDM&keywords=sewing+needles+hand&pd_rd_i=B002I35QMO&pd_rd_r=7dfc4a4d-85d9-4fa9-bae0-e641c5d5fa59&pd_rd_w=TG8zg&pd_rd_wg=IkY1h&pf_rd_p=91b604bb-c371-4573-970f-bed68a552852&pf_rd_r=14CZ6X5JHRAM9W311PP0&qid=1559661764&s=gateway&sprefix=sewing+needle%2Caps%2C148',
            },
            {
                name: 'Scissors',
                url: 'https://eustore.ifixit.com/products/utility-scissors',
            },
            {
                name: 'darning egg',
                url: '/Item/darning_egg',
            },
        ],
    },
    '169940': {
        title: 'How to repair a hole in woven textile (using a self adhesive patch)',
        guideId: '169940',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Very easy',
            timeRequired: '5 minutes',
        },
        introduction:
            "<p>When you're out hiking in wild terrain, it's easy to get your trousers snagged on thorns or fences. A self-adhesive patch is a great alternative to traditional iron-on patches, and can repair the hole quickly and easily either at home or in the field. There are various types of patches to match different types of fabrics; and in this guide we use an elasticated fabric with high stretch, to match the stretch of the hiking trousers.</p>",
        steps: [
            {
                title: 'Prepare the area',
                orderby: 1,
                stepid: 360490,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/kNyEbT2ODLnSKulT.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/XMbdNuKmi1BMUymD.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Start by finding a clean and flat place to perform the repair.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Ensure the damaged area is clean and free from any loose threads or debris. Gently brush away any particles to create a smooth surface for the patch to adhere to;  and cut the damaged thread around the hole.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Lay the item on the flat and clean surface.',
                    },
                ],
            },
            {
                title: 'Prepare the patch',
                orderby: 2,
                stepid: 360491,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/rE4Q1JNTY5PWjxqI.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/QvkeaaZlAvnQFwpl.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/mJTBKRr2MWZIaHwX.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Choose a matching patch for your broken item, considering the size of the repair, the colour, and qualities of the fabric (stretch, toughness, texture, etc.).  Consider that the location of the defect will affect this choice, and a hard-wearing material might be required in high-wear/stress zones.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<em><strong>Tip:</strong> In many cases, an exact colour match will not be possible. Embrace it! Find some creativity with your repair using contrasting colours or printed designs.</em>',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Carefully remove the patch from the backing paper liner. Avoid touching the adhesive surface too much, as this will reduce the durability of the repair. Take care also, that thinner patches may crease or stick themselves more easily.',
                    },
                ],
            },
            {
                title: 'Position & place the patch',
                orderby: 3,
                stepid: 360492,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/rtFiAr1ngFQvTPeX.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/bIxiZwLstNe4jqR5.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/1LhSpwfmE1kDPyMX.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Position the stretchy direction of the patch matching with the stretchy direction of the fabric.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Place the top edge of the patch carefully onto the item, and apply gentle pressure.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Before proceeding further: </strong>check whether the middle of the patch will align with the tear. If it will not, carefully pull up the top edge and reposition. Take care, as each repositioning will reduce the durability of the patch, and ideally repositioning should not be done.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Continue holding the lifted edge, while applying gentle pressure from the direction of the adhered edge. Gently push your finger along the patch towards the bottom edge until the whole patch is secured onto the item.',
                    },
                ],
            },
            {
                title: 'Secure the patch firmly',
                orderby: 4,
                stepid: 360493,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/cgcKY5fATcYEYymX.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/FyoEX1Xu5JEMAwSD.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/MtO4kZLWyFf5JnYD.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'The patch is now only lightly secured; and now it is important to give some additional pressure, to secure it fully.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'First, use your hands to apply pressure to all parts of the patch. Give special focus to the edges of the patch (as these will fray the quickest from wear and laundering), and the area surrounding the tear.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Next, use the backside of a spoon to apply harder pressure, and use a circular motion to press down around the patch for 1-2 minutes.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 5,
                stepid: 360494,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/u25NJW3DP35jYHEn.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/I6fLsQhmjgtBqEwe.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/V6RFLm3RPPZOvPBK.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The patch has been applied, and you're ready to go!",
                    },
                    {
                        level: 1,
                        textMarkup:
                            'Be aware: Different patches work in different way, but often the garment should be left 2-3 days before being washed.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            'Be prepared: Consider taking additional patches with you during your next trip, for quick on-the-go fixes',
                    },
                    {
                        level: 1,
                        textMarkup:
                            'Extra secure: if the tear or defect was very large, or touching a sensitive part of your body, it might be worthwhile to add a second patch on the inside (other side) of the defect. Simply turn the item inside-out and repeat this guide.',
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Self-adhesive patch',
                url: 'https://www.homedepot.com/p/reviews/BOEN-12-in-x-12-in-Drywall-Self-Adhesive-Wall-Repair-Patch-WR-1212/204834672/1',
            },
            {
                name: 'Spoon',
                url: 'https://www.amazon.com/AmazonBasics-Stainless-Dinner-Spoons-Pearled/dp/B0736W5C31/ref=sr_1_5?crid=JGHUWGOEVC2B&keywords=spoon&qid=1684950346&sprefix=spoon%2Caps%2C227&sr=8-5',
            },
        ],
    },
    '170249': {
        title: 'How to repair a hole in woven textile (using a sewing machine)',
        guideId: '170249',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '10 minutes',
        },
        introduction:
            "<p>When doing sports, it's common to get some damage - from small tears to big rips. Even on swimtrunks, sharp coral or equipment can make a hole. If you've got access to a sewing machine, these can often be quickly patched up! This guide will walk you through at a level suitable for beginners and upwards.</p>",
        steps: [
            {
                title: 'Identify the hole',
                orderby: 1,
                stepid: 361521,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/uNRYkanuxx1Jj3is.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/NQh6Q23SyHmfK5sv.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Identify the tear or hole in your garment and note the size: is it a large tear or a small tear?',
                    },
                    {
                        level: 0,
                        textMarkup:
                            '<strong>Tip: </strong>smaller holes might be fixable using a self-adhesive patch. Check out our guide on this technique before pulling out the sewing machine!',
                    },
                ],
            },
            {
                title: 'Prepare the repair',
                orderby: 2,
                stepid: 361522,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/e4aG6PkQJ5DXJm1A.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/aUSnB6jcYT1hPgF4.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/wtb1AdLsVsV5RAEk.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Turn the item inside-out, and lay it on a flat and clean surface with the tear fully exposed. Inspect the tear, and use scissors to gently cut any frayed edges.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Extra:</strong> If the fabric is wrinkled or creased, use a hot iron to smooth the area around the repair first.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Pinch the seam together carefully, to create an accurate match. Ensure there is enough fabric pinched together to fit underneath the sewing machine foot, and be sewn together.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Use sewing pins to tack the two sides together. The goal is to hold the fabric in place, in exactly the same orientation as you had it pinched by hand. This is an important step, as it will determine the location and accuracy of the final repair.',
                    },
                ],
            },
            {
                title: 'Use the sewing machine to fix the hole',
                orderby: 3,
                stepid: 361523,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/MgbTRI1vSUwL1TPC.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/pkkmWHNQW3WCrvfb.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/UF5avmpTZVFBsiud.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Time to sew the repair closed! Take time to set up the sewing machine, and create a clean and open area to work in.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip:</strong> Use the iFixit resources for <a href="https://www.ifixit.com/Guide/Thread+a+Sewing+Machine/19463" target="_blank">setting up</a> a sewing machine, and practicing <a href="https://www.ifixit.com/Topic/Basic_Sewing" target="_blank">basic</a> stitch techniques on some scrap fabric first.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Carefully offer up the item to the sewing machine, and position one end of the repair under the needle, and depress the foot to hold the item in place. Set the machine to a straight stitch.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Take care:</strong> remove the tacking pins one-by-one as needed - they must not run under the sewing machine, or it may break the sewing machine needle.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Make a locking stitch by using the reverse lever, and then make a single stitch along the length of the seam. At the end, secure the stitch with another lock stitch.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Note: </strong>Depending on the fabric, repeat the stich 1.2 times if needed, for extra durability.',
                    },
                ],
            },
            {
                title: 'Inspect & trim the repair',
                orderby: 4,
                stepid: 361524,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/2cenGw2xqodTZDPN.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/5hNPSlDolPSbOw3P.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/pI5NJcQ6gaqYpVZh.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Remove the items from the sewing machine and inspect the repair. The old edges will be uneven, but the new stitch should look secure and well fastened on both ends.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip: </strong>Turn the item right-way-round and inspect it from the outside too. Does it look correct? This will be the final appearance of the repair, so any small adjustment should be made at this stage, by making additional stitches or unpicking and re-stitching any errors.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Carefully cut away the excess fabric which is no longer needed. Make the cut carefully, and not too close to the stitching, or the repair will break during use. Remove any sharp corners of fabric to prevent any itchiness during use.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 5,
                stepid: 361525,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/TnBKkBf5CWwpVUmF.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/pUZSOLaY6xxsoCZY.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/l3HJLW4rUskSKjwL.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                    {
                        level: 0,
                        textMarkup: 'Turn the item right-way-round, and give a final check.',
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Sewing Machine',
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
            },
        ],
    },
    '172345': {
        title: 'How to Replace a Backpack Buckle (using existing strap)',
        guideId: '172345',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Very easy',
            timeRequired: '5 minutes',
        },
        introduction:
            '<p>Sometimes you need to change the buckle of your favourite backpack. It can be due to it being broken or simply not closing like it used to. We will show this quick fix that can be done in just couple of minutes of your time!</p>',
        steps: [
            {
                title: 'Identify the repair area',
                orderby: 1,
                stepid: 369656,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/CDQ5nsldBBVYGPmB.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Locate the buckle that needs to be replaced.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Note:</strong> For this simple solution we are using strap with a single fold and stitch (red circle). For straps with double stitching, additional steps would be needed to unstitch the buckle and then restitch it once it is finished.',
                    },
                ],
            },
            {
                title: 'Remove the existing buckle',
                orderby: 2,
                stepid: 369657,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/IfGQvamvdogyymCX.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/TFRtDaSm3KAXRJHf.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/3De5oGedTJgJDHJW.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Following the yellow arrows, push the strap out of the buckle.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Keep pushing the strap and gently slide the last bit following the direction of the red arrows.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Keep going forward in the direction of the green arrows until the buckle is out of the strap.',
                    },
                ],
            },
            {
                title: 'Insert the new buckle',
                orderby: 3,
                stepid: 369658,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/3VoOVPKGyVNIiEQY.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/Pt6dFVRK14RSNHVr.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/aAIDfFDedwCZDs2p.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Repeat the previous step in reverse. Start by introducing the buckle into the strap and following the direction of the green arrows.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Keep introducing the buckle following the direction of the yellow arrows.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'When the strap has passed on both parts of the buckle, pull the tip of the strap in the direction of the red arrows.',
                    },
                ],
            },
            {
                title: 'Tight the buckle',
                orderby: 4,
                stepid: 369659,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/fIle6qGgSi5Nke6w.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/dfhiHOP1weNFZ3Gd.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/GRIlLAbLCuLMjYsp.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Pull the strap in the direction of the red arrow to tighten it up.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            "Introduce the strap inside the &quot;strap keeper&quot; and then verify that the new buckle fits its counterpart. If it doesn't, you will need to repeat the previous steps to replace it as well.",
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip: </strong>if you need help replacing the &quot;strap keeper&quot;, check <a href="https://www.ifixit.com/Guide/How+to+Replace+a+%22Strap+Keeper%22+in+a+Backpack/173073" target="_blank">this guide</a>.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 5,
                stepid: 369660,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/X6EJFhdjL3lUtBaY.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/G2lDUvglUoMcNHZn.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/2PCbCv4TVCpOctJy.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [],
    },
    '173041': {
        title: 'How to Repair a Loose Webbing Strap in a Backpack',
        guideId: '173041',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Moderate',
            timeRequired: '35 minutes',
        },
        introduction:
            "<p>Your backpack strap is one of the most important parts of your bag, so it's important to take care of it! After many journeys and adventures, your strap can start to weather down. Don't worry, we've got you covered with these simple steps to ensure your backpack is as sturdy and like new again!</p>",
        steps: [
            {
                title: 'Identify the repair area',
                orderby: 1,
                stepid: 369570,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/a5BWIvCRqJfhJCNc.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/bGhk3bmbUHRJRrgh.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Identify the defective area and assess how large the damage is.',
                    },
                ],
            },
            {
                title: 'Prepare the straps',
                orderby: 2,
                stepid: 369571,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/GELI5AE4GyxuIoYV.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/XKNoJDYiNTXGrnba.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/MTojHycelIT1nEdF.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Cut away the tips of the straps to create a clean surface to repair.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Note: </strong>In this example, we cut 2 cm away from the tip.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Pin the two straps together and set aside.',
                    },
                ],
            },
            {
                title: 'Clean the area',
                orderby: 3,
                stepid: 369573,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/fyfxQQXKRKVj2EbL.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/YF3vWRMYFqrEUDgS.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/3BNXcDjOHUMAuPVh.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Use a &quot;seam ripper&quot; tool to unstitch the remaining piece of strap.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Note:</strong> This will leave a hole in the border of the backpack (red circle), that will be useful in the next step.',
                    },
                ],
            },
            {
                title: 'Hold the straps',
                orderby: 4,
                stepid: 369574,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/O1ScIMSkoTEIppVR.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/xtCFhcwTLbtH4BAf.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/g4tPVIpo2MCGQ2dw.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Insert the pinned straps inside the hole and use a safety pin to hold the pieces together.',
                    },
                ],
            },
            {
                title: 'Turn the backpack inside-out',
                orderby: 5,
                stepid: 369575,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/tPB4yTyPYECIk1Ru.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/6ALKZPAikOLQMoZU.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/sIUXCmIfDYG1UEER.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Locate the straps that were inserted in the previous step (red circle).',
                    },
                ],
            },
            {
                title: 'Clean the area before stitching',
                orderby: 6,
                stepid: 369576,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/ZrmeUWikhQgvXoKB.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/oGATQFkREC3j5dIY.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/VRiSmRHYAoWWPLhc.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Cut away the remaining pieces of the binding material that were present before (yellow arrows).',
                    },
                ],
            },
            {
                title: 'Stitch the strap',
                orderby: 7,
                stepid: 369577,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/coEXATXGTmHPdwDt.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/N6OrjCByFAecTQ4c.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Use a straight stitch to fix the strap in the right position.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip: </strong>For tips on setting up the machine take a look at <a href="https://www.ifixit.com/Guide/Thread+a+Sewing+Machine/19463" target="_blank">this iFixit guide</a>.',
                    },
                ],
            },
            {
                title: 'Reenforce the seam',
                orderby: 8,
                stepid: 369578,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/urRUphm4QVCenNIj.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/gcddGuVqdXhKSUyi.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/oqDnAPTwgrZGDcZt.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Change the settings in the sewing machine and switch from a &quot;straight&quot; to a &quot;zigzag&quot; stich.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Sew along the same line of the previous step.',
                    },
                ],
            },
            {
                title: 'Clean the area',
                orderby: 9,
                stepid: 369579,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/AATRofVtPPtkQGca.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/D45YUsNGfEYZZuph.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/HNZJockMmJ2AcxJv.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Locate the remaining piece of strap (red line) and cut it out.',
                    },
                ],
            },
            {
                title: 'Pin the "binding" strap',
                orderby: 10,
                stepid: 369580,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/cHGOLgQG4kGpCocN.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/uMyLCgVYlTrOsIjC.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/oZlDUXDftXCSVI1T.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Select a &quot;binding&quot; material, it can be a strap or any spare textile you have at home.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Make sure it is the same length as the area you are trying to repair (red line).',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Fold the side of binding strap inwards (yellow arrow) and fix the position using with pins.',
                    },
                ],
            },
            {
                title: 'Sew the "binding" strap to the inner part',
                orderby: 11,
                stepid: 369581,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/uO4efy2BTsPRJGw3.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/tedUSrFyoEWlNqnE.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/EXIU3f2EesZL1orh.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Change the settings in the sewing machine and switch back to a &quot;straight&quot; stich.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Start sewing and remove any pins in the way of the sewing route.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Turn the backpack inside-out to return to the original position.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 12,
                stepid: 369582,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/fbfCi2snSSZBlsbP.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/5d5ylKuSBBFR4geQ.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/VWsjE1NTHhkPubLW.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Seam Ripper',
                url: 'http://www.amazon.com/Clover-463-Seam-Ripper/dp/B000YZ8MVU/ref=sr_1_1?ie=UTF8&qid=1346440445&sr=8-1&keywords=seam+ripper',
            },
            {
                name: 'Sewing Machine',
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
            },
            {
                name: 'Binding strap',
                url: '/Item/Binding_strap',
            },
            {
                name: 'Sewing Pins',
                url: 'https://www.amazon.com/ForSewian-Pieces-Sewing-Straight-Quilting/dp/B08R2XCFZ5/ref=sr_1_9?crid=2BJKXOED4FMVZ&keywords=fabric+pins&qid=1654208111&s=arts-crafts&sprefix=fabric+pins%2Carts-crafts%2C143&sr=1-9',
            },
        ],
    },
    '173043': {
        title: 'How to Repair a Broken Drawstring Tunnel in a Backpack',
        guideId: '173043',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/twjxZMWf2eqgvExT.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '15 minutes',
        },
        introduction:
            "<p>If your backpack's drawstring is loose and the closing mechanism isn't working as it should, don't worry! We've got you covered. In just a few simple steps, we'll show you how to repair the string and make your favourite backpack as secure and comfortable as it used to be!</p>",
        steps: [
            {
                title: 'Identify the repair area',
                orderby: 1,
                stepid: 369563,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/NSs2cMKxrUE6Otdt.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/1K6TUJi6OMP4melt.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/TX1FCccFOF2AxTIg.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Identify the defective area and assess how large the damage is.',
                    },
                ],
            },
            {
                title: 'Clean the area',
                orderby: 2,
                stepid: 369564,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/oPZTadRurqLGABSY.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/hAEb314KJqNneZfN.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/FM3Jnrc5HyXoAMKI.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Cut away any damaged threads around the drawstring tunnel, to create a clean surface to repair.',
                    },
                ],
            },
            {
                title: 'Prepare the drawstring',
                orderby: 3,
                stepid: 369565,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/HfEfIj2fq1putLUy.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/jv6PUUJWFPDGKktl.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/TUKultuMoCOpFKcX.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Place the drawstring inside the tunnel.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Turn the edges inside, so that the drawstring is tucked inside.',
                    },
                ],
            },
            {
                title: 'Pin the area',
                orderby: 4,
                stepid: 369566,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/1wt1aB2dFAYiZIax.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/qWOggCtStUPOdqi4.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/D4N5SVC4xxXxYHex.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Pin the folded parts of the tunnel securely in place and prepare the sewing machine.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip: </strong>For tips on setting up the machine take a look at <a href="https://www.ifixit.com/Guide/Thread+a+Sewing+Machine/19463" target="_blank">this iFixit guide</a>.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Depress the sewing machine foot and remove any pins in the way of the sewing route.',
                    },
                ],
            },
            {
                title: 'Sew the repair',
                orderby: 5,
                stepid: 369568,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/uQbT6svFTRNEKiM5.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/NFcQZdiVDs5tPhQY.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/I642XVIiLLBaBVwn.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Start sewing a straight line from one side to the other and add a lock stitch at the beginning and the end.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Finish the sewing and cut the loose thread to make a neat finish.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 6,
                stepid: 369569,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/CnC33PwcVFVDVPCh.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/DhqftQloNoAM5doO.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/TpYIluOpESqk2SOH.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Sewing Scissors',
                url: 'https://www.amazon.com/-/es/tijeras-profesionales-pulgadas-inclinadas-resistentes/dp/B002PIE4JI/ref=sr_1_8?dchild=1&ie=UTF8&keywords=sewing%20scissors&language=en_US&qid=1626450645&sr=8-8',
            },
            {
                name: 'Sewing Machine',
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
            },
            {
                name: 'Sewing Pins',
                url: 'https://www.amazon.com/ForSewian-Pieces-Sewing-Straight-Quilting/dp/B08R2XCFZ5/ref=sr_1_9?crid=2BJKXOED4FMVZ&keywords=fabric+pins&qid=1654208111&s=arts-crafts&sprefix=fabric+pins%2Carts-crafts%2C143&sr=1-9',
            },
        ],
    },
    '173073': {
        title: 'How to Replace a "Strap Keeper" in a Backpack',
        guideId: '173073',
        heroImage: {
            url: 'https://guide-images.cdn.ifixit.com/igi/kgMptkDQAZQOuklT.full',
            width: 1,
            height: 1,
        },
        metaInformation: {
            difficulty: 'Easy',
            timeRequired: '10 minutes',
        },
        introduction:
            '<p>&quot;Strap keepers&quot; are the small pieces of elastic that hold in place the strap of your backpack. Although little in size, they play a big role in regulating the perfect length for the straps.</p>\n\n<p>After constant use, you might notice that the &quot;keepers&quot; get overstretched. This is your cue to take matters in your own hands and start repairing! We will walk you through the steps of fixing this small but relevant inconvenience in no time.</p>',
        steps: [
            {
                title: 'Identify the repair area',
                orderby: 1,
                stepid: 369599,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/3yGruJnWDftSYuQv.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/GykkQaAvfqxrbSbf.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/MOeqRj5kJVKTxeqw.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Check the &quot;strap keepers&quot; of your backpack (red arrows).',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'The length of the elastic should be tight. In this example, we have a worn-out elastic that has expanded and is bigger than the width of the strap (green arrow).',
                    },
                ],
            },
            {
                title: 'Measure the correct width',
                orderby: 2,
                stepid: 369600,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/Wsv3vL6HdXTLS4r4.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/SZGTJRBKyaKe2ZN1.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/gPeJRaAT4RFFhomN.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup: 'Use a pair of scissors to cut the old elastic.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Measure the width of the strap. In this example, the measurement was <strong>2 cm</strong> (red line).',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'For the strap keeper replacement, we need to use the following formula:',
                    },
                    {
                        level: 1,
                        textMarkup:
                            "<strong>Length = (Strap's width * 2.5) + 2 </strong> <em>In our example this would be: (2 * 2.5) + 2 = 7</em>",
                    },
                    {
                        level: 0,
                        textMarkup:
                            'This means that the width of the new strap keeper would need to be<strong> 7 cm </strong>(green line).',
                    },
                ],
            },
            {
                title: 'Prepare for stitching',
                orderby: 3,
                stepid: 369601,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/yXhcTqLutFsUsu3b.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/NWOJMXdot1T3IM41.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/X4ZAWfO3YUG6KPaq.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Using a fabric pencil, mark the length calculated on the previous step. In this example, we are marking 7cm (green line).',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Note:  </strong> We are using an elastic band that will serve as the new &quot;strap keeper&quot;. For this example, we chose the color black in order to make it stand out.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Cut along the marked length and then fold the elastic band around the strap.',
                    },
                ],
            },
            {
                title: 'Stitch the "strap keeper"',
                orderby: 4,
                stepid: 369602,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/gplWDuUigoDnAyDe.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/NyStUkdCvmoZCPFi.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/2dFUL6LfGYyITqFa.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Before stitching remove the strap and align the elastic in the correct position.',
                    },
                    {
                        level: 1,
                        textMarkup:
                            '<strong>Tip: </strong>For tips on setting up the machine take a look at <a href="https://www.ifixit.com/Guide/Thread+a+Sewing+Machine/19463" target="_blank">this iFixit guide</a>.',
                    },
                    {
                        level: 0,
                        textMarkup: 'Make sure you leave 1 cm away from the edge (red line).',
                    },
                    {
                        level: 0,
                        textMarkup: 'Clean the remaining loose threads.',
                    },
                ],
            },
            {
                title: 'Turn the "strap keeper" inside out',
                orderby: 5,
                stepid: 369603,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/bRWJjgSOvLEoc1LP.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/aKIPN65E2XvKZlSm.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/6bfPjrlEqTGNKpEr.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Turn the &quot;strap keeper&quot; so that the sewn part is facing inwards.',
                    },
                ],
            },
            {
                title: 'Insert the "strap keeper"',
                orderby: 6,
                stepid: 369604,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/HBHueSZkR25opwJU.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/cMFHGPoaRvDlbG6V.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/1g3iPtBbyOPxZWEG.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            'Following the red arrows, insert the &quot;strap keeper&quot; into the strap with the buckle.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Once it has been inserted into the buckle, follow the direction of the yellow arrows.',
                    },
                    {
                        level: 0,
                        textMarkup:
                            'Keep going forward and gently slide the &quot;strap keeper&quot; in the direction of the green arrows till it is holding the &quot;loose&quot; strap in place.',
                    },
                ],
            },
            {
                title: 'Finished and ready!',
                orderby: 7,
                stepid: 369605,
                media: {
                    type: 'image',
                    data: [
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/rwEDeGHrJHsB2lld.full',
                            width: 1,
                            height: 1,
                        },
                        {
                            url: 'https://guide-images.cdn.ifixit.com/igi/LTHHnIxU3igymiAx.full',
                            width: 1,
                            height: 1,
                        },
                    ],
                },
                lines: [
                    {
                        level: 0,
                        textMarkup:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                    },
                ],
            },
        ],
        partsNeeded: [],
        toolsNeeded: [
            {
                name: 'Measuring Tape or Yard Stick',
                url: 'https://a.co/d/7dUrK7g',
            },
            {
                name: 'Sewing Machine',
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
            },
            {
                name: 'Fabric pencil',
                url: 'https://www.amazon.com/SINGER-00310-Measure-Marking-Pencil/dp/B00566EA7M/ref=sr_1_5?crid=18SD0V1SZ9KCY&keywords=fabric+pencil&qid=1672161455&sprefix=fabric+pencil%2Caps%2C102&sr=8-5',
            },
            {
                name: 'Scissors',
                url: 'https://eustore.ifixit.com/products/utility-scissors',
            },
            {
                name: 'elastic band',
                url: '/Item/elastic_band',
            },
        ],
    },
}

export default guides
