import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormValues, RepairMultiStepFormContext } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import {
    Stack,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    List,
    ListItem,
    Typography,
} from '@mui/material'
import T, { I18nKey } from '../../../../components/typography/t'
import ContactCard from '../../shared/components/contact-card'
import InspectorStepTitle from '../../../../components/typography/inspector-step-title'
import ContentCard from '../../shared/components/content-card'
import LabelledDelimiter from '../../shared/components/labelled-delimiter'
import { SubmissionCheckbox, SubmissionDialog } from '../../../../../shared/data/whitelabel/types'
import { useForm } from 'react-hook-form'
import createFormField from '../../shared/helper/create-form-field'
import CloseIcon from '@mui/icons-material/Close'
import useDialog from '../../../../mixins/use-dialog'
import HelpIcon from '@mui/icons-material/Help'

const SubmitRepairOrderStep: React.FC<{
    contactForm: string[][]
    approvedCosts: number
    shippingCosts: number
    onSubmit: () => void
    submissionCheckboxes: SubmissionCheckbox[]
    submissionDialogs: SubmissionDialog[]
    formValues: FormValues
    submissionInfoTexts: I18nKey[]
}> = ({
    contactForm,
    approvedCosts,
    shippingCosts,
    onSubmit,
    submissionCheckboxes,
    submissionDialogs,
    formValues,
    submissionInfoTexts,
}) => {
    const [t] = useTranslation()

    const [dialogKey, setDialogKey] = React.useState<string | null>(null)
    const helpDialog = useDialog()
    const form = useForm({
        defaultValues: {
            ...submissionCheckboxes.reduce((acc, checkbox) => {
                acc[checkbox.name] = false
                return acc
            }, {} as any),
        },
    })

    const allChecked = submissionCheckboxes.every((checkbox) => form.watch(checkbox.name))

    const dialog = submissionDialogs.find((dialog) => dialog.key === dialogKey)

    const tComponents = {
        externalLink: <Link target="_blank" />,
        list: <List sx={{ listStyleType: 'disc', mx: 0 }} />,
        item: <ListItem sx={{ display: 'list-item', px: 0, mx: 1 }} />,
        dialogLink: (
            <Link
                onClick={(e: any) => {
                    setDialogKey(e.target.dataset.key)
                }}
            />
        ),
    }

    return (
        <Stack gap={4}>
            <InspectorStepTitle i18nKey="serviceDesk.repair.submitRepairOrderStep.title" />
            <Stack
                direction={{ md: 'row', sm: 'column' }}
                gap={2}
                justifyContent="center"
                alignSelf="center"
            >
                <ContentCard>
                    <Box position="relative">
                        <T
                            sx={{ color: 'primary.main' }}
                            component="h2"
                            variant="h6"
                            i18nKey="serviceDesk.repair.submitRepairOrderStep.repairCosts.title"
                        />
                        <HelpIcon
                            onClick={() => helpDialog.open()}
                            sx={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                transform: 'translateX(120%)',
                                fontSize: '1em',
                            }}
                        />
                    </Box>
                    <Stack gap={1} fontWeight={500}>
                        <Stack direction="row" justifyContent="space-between" gap={1}>
                            <T i18nKey="serviceDesk.repair.submitRepairOrderStep.repairCosts.costApproval" />

                            <Typography>{approvedCosts}&nbsp;€</Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" gap={1}>
                            <Box>
                                <T
                                    i18nKey="serviceDesk.repair.submitRepairOrderStep.repairCosts.shippingCosts"
                                    component="p"
                                ></T>
                                <T
                                    i18nKey="serviceDesk.repair.submitRepairOrderStep.repairCosts.shippingCostsSubline"
                                    component="p"
                                    sx={{ fontSize: '0.7em', color: 'grey' }}
                                ></T>
                            </Box>
                            <Typography>{shippingCosts}&nbsp;€</Typography>
                        </Stack>
                        <LabelledDelimiter $primary />
                        <Stack direction="row" justifyContent="space-between" gap={1}>
                            <Box>
                                <T
                                    i18nKey="serviceDesk.repair.submitRepairOrderStep.repairCosts.totalCosts"
                                    component="p"
                                ></T>
                                <T
                                    i18nKey="serviceDesk.repair.submitRepairOrderStep.repairCosts.totalCostsSubline"
                                    component="p"
                                    sx={{ fontSize: '0.7em', color: 'grey' }}
                                ></T>
                            </Box>
                            <Typography>{approvedCosts + shippingCosts}&nbsp;€</Typography>
                        </Stack>
                    </Stack>
                </ContentCard>
                <ContactCard contactForm={contactForm} />
            </Stack>
            <Stack gap={2}>
                {submissionInfoTexts.map((text) => (
                    <T
                        component="span"
                        i18nKey={text as any}
                        values={{ form: formValues }}
                        key={text as string}
                        components={tComponents}
                    />
                ))}
                {submissionCheckboxes.map((checkbox) => (
                    <Stack key={checkbox.name} direction="row" gap={1} alignItems="center">
                        <Checkbox
                            checked={form.watch(checkbox.name)}
                            onClick={() => form.setValue(checkbox.name, !form.watch(checkbox.name))}
                        />
                        <span>
                            <T
                                i18nKey={checkbox.label as any}
                                values={{ form: formValues }}
                                components={tComponents}
                            />
                        </span>
                    </Stack>
                ))}
            </Stack>
            <Button variant="contained" fullWidth onClick={onSubmit} disabled={!allChecked}>
                <T i18nKey="serviceDesk.repair.submitRepairOrderStep.submitAction" />
            </Button>
            {dialog && (
                <Dialog open={true} onClose={() => setDialogKey(null)}>
                    <DialogTitle>
                        <T i18nKey={dialog.title as any} />
                        <IconButton
                            onClick={() => setDialogKey(null)}
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <T
                            i18nKey={dialog.content as any}
                            values={{ form: formValues }}
                            components={tComponents}
                        />
                    </DialogContent>
                </Dialog>
            )}
            {helpDialog.isOpen && (
                <Dialog open={helpDialog.isOpen} onClose={() => helpDialog.close()}>
                    <DialogTitle>
                        <T i18nKey="serviceDesk.repair.costApprovalStep.help.title" />
                    </DialogTitle>
                    <DialogContent>
                        <T i18nKey="serviceDesk.repair.costApprovalStep.help.content" />
                    </DialogContent>
                </Dialog>
            )}
        </Stack>
    )
}

const SubmitRepairOrderStepContext = () => {
    const { form, ctx, props } = React.useContext(RepairMultiStepFormContext)
    const [isLoading, setIsloading] = React.useState(false)

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                hideNextButton: true,
            }}
            wide
            isLoading={isLoading}
        >
            <SubmitRepairOrderStep
                contactForm={ctx.whitelabel.config.repair.contactForm.fields
                    .map((line) =>
                        line
                            .map(createFormField(form.formValues.contactFormValues))
                            .filter((cell) => !cell.excludeFromSummary)
                            .map((cell) => {
                                const value = form.formValues.contactFormValues[cell.key]
                                return typeof value === 'string' ? value : value?.print
                            })
                    )
                    .filter((line) => line.length > 0)}
                approvedCosts={form.formValues.costApproval}
                shippingCosts={
                    ctx.whitelabel.config.repair.solutions.submitRepairOrder.shippingCost
                }
                onSubmit={async () => {
                    try {
                        setIsloading(true)
                        await form.submitForm()
                        setIsloading(false)
                        props.nextSlide()
                    } catch (error) {
                        console.error(error)
                    } finally {
                        setIsloading(false)
                    }
                }}
                submissionInfoTexts={
                    ctx.whitelabel.config.repair.solutions.submitRepairOrder.submissionInfoTexts
                }
                submissionCheckboxes={
                    ctx.whitelabel.config.repair.solutions.submitRepairOrder.submissionCheckboxes
                }
                submissionDialogs={
                    ctx.whitelabel.config.repair.solutions.submitRepairOrder.submissionDialogs
                }
                formValues={form.formValues}
            />
        </StepFrameworkWrapper>
    )
}

export default SubmitRepairOrderStepContext
