import React from 'react'
import WarrantyMultiStep from './warranty-multi-step'
import uploadcareClient from '../../../mixins/uploadcare-client'
import { ThemeProvider, createTheme } from '@mui/material'
import mergeObjects from '../../../mixins/merge-object'
import { useAnalytics } from 'use-analytics'
import { submitWarrantyRequest } from 'wasp/client/operations'
import { WarrantyRequestPageViewEvent } from '../../../../shared/analytics'
import { useIsMobile } from '../../../mixins/media-query'
import parseQueryString from '../../../mixins/parse-query-string'
import { FormValues, WhitelabelWithWarrantyConfig } from './context'
import { useRollbar } from '@rollbar/react'
import { OrderType } from '../../../../shared/types/service-desk/order-type'
import WhitelabelLoader from '../shared/whitelabel-loader'

const WarrantyModule = ({
    whitelabel,
    orderType,
}: {
    whitelabel: WhitelabelWithWarrantyConfig
    orderType: OrderType
}) => {
    const rollbar = useRollbar()
    const utmData = parseQueryString()
    const analytics = useAnalytics()
    const isMobile = useIsMobile()

    React.useEffect(() => {
        analytics.track(WarrantyRequestPageViewEvent, { isMobile, brandId: whitelabel.id, utmData })
    }, [])

    const handleSubmit = async (formValues: FormValues) => {
        try {
            await submitWarrantyRequest({
                companyId: whitelabel.id,
                defectPhotoUrl: formValues.defectPhotoUrl!,
                pinpoint: formValues.pinpoint!,
                description: formValues.description!,
                product: formValues.selectedProduct!,
                productView: formValues.selectedProductView,
                warrantyInformationForm: formValues.warrantyInformationForm,
                contactForm: formValues.contactFormValues,
                utmData: utmData,
                anonymousUserId: analytics.user().anonymousId,
                orderType,
                retailerCode: formValues.retailerCode,
                retailerForm: formValues.retailerForm,
                requestIndividualSupport: formValues.requestIndividualSupport,
                individualSupportForm: formValues.individualSupportForm,
            })
        } catch (error) {
            rollbar.error('Error submitting warranty request', {
                error,
                formValues,
                companyId: whitelabel.id,
                utmData: utmData,
                anonymousUserId: analytics.user().anonymousId,
            })
        }
    }

    return (
        <ThemeProvider
            theme={(theme: any) =>
                createTheme({
                    ...theme,
                    palette: {
                        ...theme.palette,
                        ...whitelabel?.theme.palette,
                    },
                    components: mergeObjects(theme.components, whitelabel?.theme.components),
                } as any)
            }
        >
            <WarrantyMultiStep
                uploadCareClient={uploadcareClient}
                whitelabel={whitelabel as WhitelabelWithWarrantyConfig}
                onSubmit={handleSubmit}
                orderType={orderType}
            />
        </ThemeProvider>
    )
}

export const WarrantyPageB2B = () => (
    <WhitelabelLoader>
        {({ whitelabel }) => (
            <WarrantyModule
                whitelabel={whitelabel as WhitelabelWithWarrantyConfig}
                orderType={OrderType.B2B}
            />
        )}
    </WhitelabelLoader>
)

export const WarrantyPageB2C = () => (
    <WhitelabelLoader>
        {({ whitelabel }) => (
            <WarrantyModule
                whitelabel={whitelabel as WhitelabelWithWarrantyConfig}
                orderType={OrderType.B2C}
            />
        )}
    </WhitelabelLoader>
)
