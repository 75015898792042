import React from 'react'
import { RepairMultiStepFormContext, RepairType } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import { Stack, styled, Tab, Tabs } from '@mui/material'
import InspectorStepTitle from '../../../../components/typography/inspector-step-title'
import { useTranslation } from 'react-i18next'
import createUploadcareImageSrc from '../../../../mixins/create-uploadcare-image-src'

const SqueezeStack = styled(Stack)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: 100%;
    flex-shrink: 1;
    min-height: 0; // too shrink element to fit image inside flexbox
    flex-grow: 1;
    width: 100%;
`
const ImageWrapperAligner = styled('div')`
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: 100%;
    flex-shrink: 1;
    min-height: 0; // too shrink element to fit image inside flexbox
    flex-grow: 1;
    width: 100%;

    img {
        max-height: 100%;
        max-width: 100%;
    }
`

const RepairTypeStep: React.FC<{
    onRepairTypeChange: (repairType: number) => void
    selectedRepairType: RepairType
}> = ({ onRepairTypeChange, selectedRepairType }) => {
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        onRepairTypeChange(newValue)
    }
    const { t } = useTranslation()

    const repairTypePreviews = {
        [RepairType.INVISIBLE]: createUploadcareImageSrc(
            'https://ucarecdn.com/9285402f-0106-4892-bdfa-be7e39e312a1/',
            { preview: '700x700', zoomObjects: 100 }
        ),
        [RepairType.VISIBLE]: createUploadcareImageSrc(
            'https://ucarecdn.com/81511d7a-e33c-46ef-80b3-616a8e64083e/',
            { preview: '700x700', zoomObjects: 100 }
        ),
        [RepairType.FUNKY]: createUploadcareImageSrc(
            'https://ucarecdn.com/93fbb0f3-005d-480c-a27c-d90c869d58e1/',
            { preview: '700x700', zoomObjects: 100 }
        ),
    }

    return (
        <>
            <InspectorStepTitle i18nKey="serviceDesk.shared.repairTypeStep.title" mb={4} />
            <SqueezeStack alignItems="center" spacing={2}>
                <Tabs value={selectedRepairType} onChange={handleTabChange}>
                    <Tab
                        label={t('serviceDesk.shared.repairTypeStep.invisible' as any)}
                        value={RepairType.INVISIBLE}
                    />
                    <Tab
                        label={t('serviceDesk.shared.repairTypeStep.visible' as any)}
                        value={RepairType.VISIBLE}
                    />
                    <Tab
                        label={t('serviceDesk.shared.repairTypeStep.funky' as any)}
                        value={RepairType.FUNKY}
                    />
                </Tabs>
                <ImageWrapperAligner>
                    <img
                        src={repairTypePreviews[selectedRepairType]}
                        alt="Repair Type"
                        key={selectedRepairType}
                    />
                </ImageWrapperAligner>
            </SqueezeStack>
        </>
    )
}

const RepairTypeStepContext = () => {
    const { form, ctx, props } = React.useContext(RepairMultiStepFormContext)

    return (
        <StepFrameworkWrapper
            squeezeInScreen
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
            }}
        >
            <RepairTypeStep
                onRepairTypeChange={(repairType) => form.setValue('repairType', repairType)}
                selectedRepairType={form.formValues.repairType!}
            />
        </StepFrameworkWrapper>
    )
}

export default RepairTypeStepContext
