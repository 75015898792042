import React from 'react'
import { useTranslation } from 'react-i18next'
import { RepairMultiStepFormContext } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import InspectorStepTitle from '../../../../components/typography/inspector-step-title'

const StoreFinderStepContext = () => {
    const { form, ctx, props } = React.useContext(RepairMultiStepFormContext)
    const { i18n } = useTranslation()

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: false,
                hideNextButton: true,
            }}
            wide
        >
            <InspectorStepTitle
                i18nKey="serviceDesk.repair.solutionStep.linktree.storeFinderTitle"
                mb={4}
            />
            <iframe
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    minHeight: '620px',
                }}
                src={ctx.whitelabel.config.repair.solutions.storeFinder.iframeUrl!({
                    language: i18n.language.split('-')[0],
                })}
            ></iframe>
        </StepFrameworkWrapper>
    )
}

export default StoreFinderStepContext
