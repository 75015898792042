import React from 'react'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import { useForm, UseFormReturn } from 'react-hook-form'
import { type ServiceDeskContactForm } from '../../../../../shared/data/whitelabel/types'
import Field from '../../shared/components/field'
import FormData from '../../../../../shared/types/common/form-data'
import { AlterationsMultiStepFormContext } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import InspectorStepTitle from '../../../../components/typography/inspector-step-title'

const ContactStep: React.FC<{
    contactForm: ServiceDeskContactForm
    useFormReturn: UseFormReturn<FormData>
}> = ({ contactForm, useFormReturn }) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle i18nKey="serviceDesk.repair.contactStep.title" mb={4} />
            <form
                data-testid="contact-form"
                style={{ width: '100%' }}
                onSubmit={(e) => {
                    // handleSubmit(onSubmit)(e)
                    e.preventDefault()
                }}
            >
                {contactForm.fields.map((cells, index) => (
                    <Stack
                        data-testid={`form-row-${index}`}
                        direction="row"
                        mb={index === contactForm.fields.length - 1 ? 0 : 1}
                        gap={2}
                        sx={{ width: '100%' }}
                        key={index}
                    >
                        {cells.map((cell, index) => (
                            <Field key={index} field={cell} useFormReturn={useFormReturn} />
                        ))}
                    </Stack>
                ))}
            </form>
        </>
    )
}

const ContactStepContext = () => {
    const { form, ctx, props } = React.useContext(AlterationsMultiStepFormContext)
    const useFormReturn = useForm<FormData>({
        values: form.formValues.contactFormValues,
        reValidateMode: 'onChange',
        mode: 'onChange',
    })
    const storeContactForm = () => {
        form.setValue('contactFormValues', useFormReturn.getValues())
    }

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: !useFormReturn.formState.isValid,
                onValidNextClick: async () => {
                    storeContactForm()
                    props.nextSlide()
                },
                onValidPrevClick: () => {
                    storeContactForm()
                    props.prevSlide()
                },
            }}
        >
            <ContactStep
                contactForm={ctx.whitelabel.config.alterations.contactForm}
                useFormReturn={useFormReturn}
            />
        </StepFrameworkWrapper>
    )
}

export default ContactStepContext
