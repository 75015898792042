import { styled } from '@mui/material'

const ContentCard = styled('div')`
    padding: ${({ theme }) => theme.spacing(4, 4)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1em;
    flex-grow: 1;
    box-shadow: ${({ theme }) => theme.shadows[4]};
    border-radius: ${({ theme }) => theme.spacing(1)};
    position: relative;
`

export default ContentCard
