import React from 'react'
import {
    getRepairGuideSuggestions as getRepairGuideSuggestionsServerAction,
    getRepairServiceAssignments as getRepairServiceAssignmentsServerAction,
} from 'wasp/client/operations'
import { UploadClient } from '@uploadcare/upload-client'
import {
    FormValues,
    RepairMultiStepFormContext,
    RepairType,
    WhitelabelWithRepairConfig,
} from './context'
import {
    RepairRequestStepChangeEvent,
    RepairRequestAiSuggestedGuidesEvent,
    RepairRequestAiSuggestedServicesEvent,
} from '../../../../shared/analytics'
import { ProductTypeId } from '../../../../shared/data/products/types'
import { findProductTypeById } from '../../../../shared/data/products/helpers'
import { MultiStepLayout } from '../../../components/layout'
import { useTranslation } from 'react-i18next'
import ProductSelectionStep from './steps/product-selection'
import PinpointStep from './steps/pinpoint'
import PhotoStep from './steps/photo'
import ContactStep from './steps/contact'
import SolutionChooserStep from './steps/solution-chooser'
import DescriptionStep from './steps/description'
import OutroStep from './steps/outro/index'
import DiyStep from './steps/diy'
import StoreFinderStep from './steps/store-finder'
import RequestRepairOfferStep from './steps/request-repair-offer'
import SubmitRepairOrderStep from './steps/submit-repair-order'
import CostApprovalStep from './steps/cost-approval'
import RepairTypeStep from './steps/repair-type'
import RetailerValidationStep from './steps/b2b/retailer-validation'
import useSlider, { type Slide } from '../shared/helper/use-slider'
import useMultiStepForm from '../shared/helper/use-multi-step-form'
import useMultiStepError from '../shared/helper/use-multi-step-error'
import { useAnalytics } from 'use-analytics'
import useUrlQuery from '../../../mixins/use-url-query'
import PurchaseConfirmationStep from './steps/purchase-confirmation'
import DetailsStep from './steps/details'
import { CustomInformationStepType } from '../../../../shared/data/whitelabel/types'
import { OrderType } from '../../../../shared/types/service-desk/order-type'

const RepairMultiStep = ({
    onSubmit,
    uploadCareClient,
    whitelabel,
    orderType,
}: {
    onSubmit: (formValues: FormValues) => Promise<void>
    uploadCareClient: UploadClient
    whitelabel: WhitelabelWithRepairConfig
    orderType: OrderType
}) => {
    const [getIFrameQueryParam] = useUrlQuery('iframe')
    const isIframe = getIFrameQueryParam() === 'true'
    const activateContactScreenIfB2C = orderType === OrderType.B2C
    const slideDefinition: Slide[] = [
        {
            name: 'RetailerValidationStep',
            Component: () => <RetailerValidationStep />,
            skip: orderType !== OrderType.B2B,
        },
        { name: 'ProductSelectionStep', Component: () => <ProductSelectionStep />, skip: false },
        { name: 'PinpointStep', Component: () => <PinpointStep />, skip: false },
        { name: 'PhotoStep', Component: () => <PhotoStep />, skip: false },
        { name: 'DescriptionStep', Component: () => <DescriptionStep />, skip: false },
        ...(whitelabel.config.repair.repairInformationSteps?.map((step) => ({
            name: 'RepairInformationStep-' + step.fieldKey,
            Component: () =>
                step.type === CustomInformationStepType.Photo ? (
                    <PurchaseConfirmationStep {...step} />
                ) : (
                    <DetailsStep {...step} />
                ),
            skip: false,
        })) ?? []),
        {
            name: 'RepairTypeStep',
            Component: () => <RepairTypeStep />,
            skip: !whitelabel.config.repair.displayRepairOptions,
        },
        {
            name: 'SolutionChooserStep',
            Component: () => <SolutionChooserStep />,
            skip: false,
            obscureProgress: true,
        },
        { name: 'DiyStep', Component: () => <DiyStep />, skip: true },
        { name: 'StoreFinderStep', Component: () => <StoreFinderStep />, skip: true },
        { name: 'CostApprovalStep', Component: () => <CostApprovalStep />, skip: true },
        { name: 'ContactStep', Component: () => <ContactStep />, skip: true },
        {
            name: 'RequestRepairOfferStep',
            Component: () => <RequestRepairOfferStep />,
            skip: true,
        },
        { name: 'SubmitRepairOrderStep', Component: () => <SubmitRepairOrderStep />, skip: true },
        { name: 'OutroStep', Component: () => <OutroStep />, skip: true },
    ]

    const analytics = useAnalytics()
    const [t] = useTranslation()
    const [meta, setMeta] = React.useState<any>({})
    const {
        getSlides,
        slideIndex,
        nextSlide,
        prevSlide,
        setSlide,
        gotoEnd,
        currentStep,
        maxSteps,
        setSlideSkip,
        direction,
    } = useSlider(slideDefinition, 0)

    const { errors, pushError, popError } = useMultiStepError()
    const formDefaultValues: FormValues = {
        selectedProduct: undefined,
        selectedProductView: 0,
        repairType: RepairType.INVISIBLE,
        aiSelectedServices: [],
        aiSelectedRepairGuides: [],
        acceptedServices: [],
        costApproval: whitelabel.config.repair.solutions.submitRepairOrder.minCost,
        repairInformationForm: {},
        description: undefined,
        uploadedPhotoUrl: undefined,
        pinpoint: undefined,
        selectedComponent: undefined,
        selectedProductCategory: undefined,
        contactFormValues: {},
        retailerCode: undefined,
        retailerForm: {},
        requestIndividualSupport: false,
        individualSupportForm: {},
    }
    const { form, resetForm, submitForm, formValues } = useMultiStepForm<FormValues>(
        formDefaultValues,
        onSubmit
    )

    const productCategoryTree = whitelabel.productCategoryTree

    const selectedProductNode = findProductTypeById(
        (form.getValues('selectedProduct') ?? (-1 as any)) as ProductTypeId,
        productCategoryTree
    )

    const getRepairServiceAssignments = async () => {
        if (selectedProductNode) {
            const args = {
                productCategory: selectedProductNode.internalName,
                problemDescription: form.getValues('description')!,
                selectedProductComponent: !!form.getValues('selectedComponent')
                    ? {
                          name: form.getValues('selectedComponent')!,
                          potentialFeatures: [],
                      }
                    : undefined,
                availableServices: selectedProductNode.applicableServices,
                brandId: whitelabel.id,
            }
            const selectedRepairGuideIds = await getRepairServiceAssignmentsServerAction(args)
            form.setValue('aiSelectedServices', selectedRepairGuideIds)
            const selectedRepairServices = whitelabel.repairServices.filter((service) =>
                selectedRepairGuideIds.includes(service.id)
            )
            form.setValue(
                'costApproval',
                Math.max(
                    whitelabel.config.repair.solutions.submitRepairOrder.minCost,
                    ...selectedRepairServices.flatMap((service) => service.price)
                )
            )
            analytics.track(RepairRequestAiSuggestedServicesEvent, {
                brandId: whitelabel.id,
                productCategory: selectedProductNode.internalName,
                productComponent: form.getValues('selectedComponent'),
                defectDescription: form.getValues('description'),
                serviceIds: selectedRepairGuideIds,
            })
        }
    }

    const getRepairGuideSuggestions = async () => {
        if (selectedProductNode) {
            const args = {
                productCategory: selectedProductNode.internalName,
                problemDescription: form.getValues('description')!,
                selectedProductComponent: !!form.getValues('selectedComponent')
                    ? {
                          name: form.getValues('selectedComponent')!,
                          potentialFeatures: [],
                      }
                    : undefined,
                availableGuides: selectedProductNode.applicableGuides,
                brandId: whitelabel.id,
            }
            const selectedRepairGuideIds = await getRepairGuideSuggestionsServerAction(args)
            form.setValue('aiSelectedRepairGuides', selectedRepairGuideIds)
            analytics.track(RepairRequestAiSuggestedGuidesEvent, {
                brandId: whitelabel.id,
                productCategory: selectedProductNode.internalName,
                productComponent: form.getValues('selectedComponent'),
                defectDescription: form.getValues('description'),
                guideIds: selectedRepairGuideIds,
            })
        }
    }

    const resetSolutionSteps = () => {
        setSlideSkip('DiyStep', true)
        setSlideSkip('CostApprovalStep', true)
        setSlideSkip('ContactStep', true)
        setSlideSkip('RequestRepairOfferStep', true)
        setSlideSkip('SubmitRepairOrderStep', true)
        setSlideSkip('StoreFinderStep', true)
        setSlideSkip('OutroStep', true)
    }

    const setupDiySolutionSteps = () => {
        setSlideSkip('DiyStep', false)
    }

    const setupRepairOrderSolutionSteps = ({
        skipCostApprovalStep,
    }: {
        skipCostApprovalStep: boolean
    }) => {
        if (skipCostApprovalStep) {
            setSlideSkip('CostApprovalStep', true)
        } else {
            setSlideSkip('CostApprovalStep', false)
        }
        setSlideSkip('ContactStep', !activateContactScreenIfB2C)
        setSlideSkip('SubmitRepairOrderStep', false)
        setSlideSkip('OutroStep', false)
    }

    const setupRepairOfferSolutionSteps = () => {
        setSlideSkip('ContactStep', !activateContactScreenIfB2C)
        setSlideSkip('RequestRepairOfferStep', false)
        setSlideSkip('OutroStep', false)
    }

    const setupStoreFinderSolutionSteps = () => {
        setSlideSkip('StoreFinderStep', false)
    }

    const setupSolutionSteps = () => {
        const aiSelectedServices = form.getValues('aiSelectedServices')
        const aiSelectedRepairGuides = form.getValues('aiSelectedRepairGuides')
        const showDiySolutions =
            whitelabel.config.repair.solutions.diy.activate && aiSelectedRepairGuides.length > 0
        const showSubmitRepairRequestSolutions =
            whitelabel.config.repair.solutions.submitRepairOrder.activate &&
            (aiSelectedServices.length > 0 ||
                !whitelabel.config.repair.solutions.requestRepairOffer.activateAsFallback)
        const showRequestRepairOfferSolutions =
            whitelabel.config.repair.solutions.requestRepairOffer.activate ||
            (aiSelectedServices.length === 0 &&
                whitelabel.config.repair.solutions.requestRepairOffer.activateAsFallback)
        const showStoreFinderSolutions = whitelabel.config.repair.solutions.storeFinder.activate

        resetSolutionSteps()

        const multipleSolutionsAvailable =
            [
                showDiySolutions,
                showSubmitRepairRequestSolutions,
                showRequestRepairOfferSolutions,
                showStoreFinderSolutions,
            ].filter((activate) => activate).length > 1

        if (multipleSolutionsAvailable) {
            // show solution chooser
            setSlideSkip('SolutionChooserStep', false)
        } else {
            setSlideSkip('SolutionChooserStep', true)
            switch (true) {
                case showDiySolutions:
                    setupDiySolutionSteps()
                    break
                case showSubmitRepairRequestSolutions:
                    setupRepairOrderSolutionSteps({
                        skipCostApprovalStep: aiSelectedServices.length === 0,
                    })
                    break
                case showRequestRepairOfferSolutions:
                    setupRepairOfferSolutionSteps()
                    break
                case showStoreFinderSolutions:
                    setupStoreFinderSolutionSteps()
                    break
            }
        }
    }

    const getRepairEngineResult = async () => {
        const resultServices = whitelabel.config.repair.solutions.submitRepairOrder.activate
            ? await getRepairServiceAssignments()
            : undefined
        const resultGuides = whitelabel.config.repair.solutions.diy.activate
            ? await getRepairGuideSuggestions()
            : undefined
        await Promise.all([resultServices, resultGuides])
        setupSolutionSteps()
        return
    }

    React.useEffect(() => {
        analytics.track(RepairRequestStepChangeEvent, {
            brandId: whitelabel.id,
            slide: getSlides()[slideIndex].name,
        })
    }, [slideIndex])

    return (
        <RepairMultiStepFormContext.Provider
            value={{
                ctx: {
                    whitelabel,
                    repairGuides: whitelabel.repairGuides,
                    repairServices: whitelabel.repairServices,
                    productCategoryTree: productCategoryTree,
                    getRepairEngineResult,
                    isIframe,
                    setSolutionPath: {
                        reset: () => {
                            resetSolutionSteps()
                        },
                        diy: () => {
                            resetSolutionSteps()
                            setupDiySolutionSteps()
                        },
                        storeFinder: () => {
                            resetSolutionSteps()
                            setupStoreFinderSolutionSteps()
                        },
                        requestRepairOffer: () => {
                            resetSolutionSteps()
                            setupRepairOfferSolutionSteps()
                        },
                        submitRepairOrder: ({ skipCostApprovalStep }) => {
                            resetSolutionSteps()
                            setupRepairOrderSolutionSteps({ skipCostApprovalStep })
                        },
                    },
                },
                props: {
                    currentStep,
                    maxSteps,
                    nextSlide,
                    prevSlide,
                    setSlide,
                    gotoEnd,
                    setSlideSkip,
                    direction,
                },
                form: {
                    setValue: form.setValue,
                    formValues,
                    submitForm,
                    resetForm,
                },
                meta: {
                    value: meta,
                    setMetaValue: setMeta,
                    resetMeta: () => {
                        setMeta({})
                    },
                },
                error: {
                    errors,
                    pushError,
                    popError,
                },
                uploadCareClient,
            }}
        >
            <MultiStepLayout
                logo={whitelabel.logo}
                progress={
                    getSlides()[slideIndex].obscureProgress
                        ? undefined
                        : currentStep / (maxSteps - 1)
                }
            >
                {getSlides()[slideIndex].Component()}
            </MultiStepLayout>
        </RepairMultiStepFormContext.Provider>
    )
}

export default RepairMultiStep
