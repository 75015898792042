import { Skeleton, styled } from '@mui/material'
import LanguageSwitcher from '../pages/service-desk/shared/components/language-switcher'
import useUrlQuery from '../mixins/use-url-query'
import React from 'react'

export const Page = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    width: 100%;
`

export const Header = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing(2)} 0;
    border-bottom: 1px solid
        ${({ theme }) => theme.palette.progressbarColor ?? theme.palette.secondary.main};
`

export const Content = styled('main')`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: ${({ theme }) => theme.spacing(1)} 0;
`

export const Footer = styled('div')<{ $isIframe?: boolean }>(
    ({ theme, $isIframe }) => `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5em 0;
    ${!$isIframe && `border-top: 1px solid ${theme.palette.primary.main};`}`
)

export const WidthContainer = styled('div')<{ $wide?: boolean }>`
    height: 100%;
    width: 100%;
    max-width: ${({ $wide }) => ($wide ? '1200px' : '650px')};
    padding: 0 ${({ theme }) => theme.spacing(2)};
    align-self: center;
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    flex-direction: inherit;
`
const BrandLogoWrapper = styled('div')`
    width: 128px;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const Logo = styled('img')`
    max-width: 100%;
    max-height: 100%;
`

export const BrandLogo = (props: any) => (
    <BrandLogoWrapper>
        <Logo {...props} />
    </BrandLogoWrapper>
)

export const PBB = styled('div')`
    font-size: 1em;
`
export const PoweredBy = styled('span')`
    font-size: 0.8em;
`
export const PoweredByBrakeable = styled('span')`
    font-family: Bebas Neue;
    letter-spacing: 0.05em;
`
export const ProgressBar = styled('div')<{ $progress: number }>`
    height: 8px;
    flex-shrink: 0;
    width: 100%;
    position: relative;
    transition: background 500ms;
    background: ${({ theme }) => theme.palette.grey[100]};

    ::before {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        background: ${({ theme }) =>
            theme.palette.progressbarColor ?? theme.palette.secondary.main};
        transform: scaleX(${({ $progress }) => $progress});
        transition: transform 0.5s;
        transform-origin: center left;
    }
`

export const MultiStepLayout: React.FC<{
    logo: string
    progress?: number
    children: React.ReactNode
}> = ({ children, logo, progress }) => {
    const [getIFrameQueryParam] = useUrlQuery('iframe')
    const isIframe = getIFrameQueryParam() === 'true'
    const pageRef = React.useRef<HTMLDivElement>(null)
    const observeRef = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        if (!isIframe) return

        const resizeObserver = new ResizeObserver((entries) => {
            if (!pageRef.current) return
            parent?.postMessage(
                {
                    type: 'inspector-dimensions-changed',
                    dimensions: {
                        width: pageRef.current.scrollWidth,
                        height: pageRef.current.scrollHeight,
                    },
                },
                '*'
            )
            for (let entry of entries) {
            }
        })

        // Observe the root element
        if (observeRef.current) {
            resizeObserver.observe(observeRef.current)
        }

        // Cleanup function to unobserve on component unmount
        return () => {
            if (observeRef.current) {
                resizeObserver.unobserve(observeRef.current)
            }
        }
    }, [])

    return (
        <Page ref={pageRef} sx={{ height: isIframe ? 'auto' : '100%' }}>
            {!isIframe && (
                <Header>
                    <WidthContainer sx={{ justifyContent: 'space-between', maxWidth: 1200 }}>
                        <BrandLogo src={logo} alt="logo" />
                        <LanguageSwitcher />
                    </WidthContainer>
                </Header>
            )}
            {progress !== undefined ? (
                <ProgressBar $progress={progress} />
            ) : (
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={8}
                    sx={{
                        background: (theme) =>
                            theme.palette.progressbarColor ?? theme.palette.secondary.main,
                    }}
                />
            )}
            <Content sx={{ mt: 1, mb: 4 }} ref={observeRef}>
                {children}
            </Content>
            <Footer $isIframe={isIframe}>
                <WidthContainer sx={{ justifyContent: 'flex-end', maxWidth: 1200 }}>
                    <PBB>
                        <PoweredBy>Powered by</PoweredBy>{' '}
                        <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                    </PBB>
                </WidthContainer>
            </Footer>
        </Page>
    )
}
