import React from 'react'
import { Stack, Typography } from '@mui/material'
import T from '../../../../components/typography/t'
import ContentCard from './content-card'

const ContactCard: React.FC<{ contactForm: string[][] }> = ({ contactForm }) => (
    <ContentCard>
        <T
            sx={{ color: 'primary.main' }}
            component="h2"
            variant="h6"
            i18nKey="serviceDesk.repair.requestRepairOfferStep.contactFields.title"
        />
        <Stack gap={0}>
            {contactForm.map((line, index) => (
                <Typography component="span" key={line.join(' ') + index}>
                    {line.join(' ')}
                </Typography>
            ))}
        </Stack>
    </ContentCard>
)

export default ContactCard
