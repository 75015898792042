import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, getBrandById } from 'wasp/client/operations'
import { Backdrop, CircularProgress } from '@mui/material'
import { useRollbar } from '@rollbar/react'
import { FrontendWhitelabel, Whitelabel } from '../../../../shared/data/whitelabel/types'
import mergeWhitelabel from '../../../../shared/brands/merge-whitelabel'

export const loadClientConfig = async (brandId: string): Promise<FrontendWhitelabel> => {
    const module = await import(`../../../../shared/data/whitelabel/${brandId}.ts`)
    return module.default
}

const useClientConfig = (brandId: string) => {
    const [resource, setResource] = React.useState<{
        status: string
        result: FrontendWhitelabel | null
        error: unknown | null
    }>({ status: 'pending', result: null, error: null })
    const rollbar = useRollbar()

    React.useEffect(() => {
        let isMounted = true // Track if the component is still mounted
        const maxWhitelabelRetries = 3
        const maxFallbackRetries = 3
        const maxRetries = maxWhitelabelRetries + maxFallbackRetries

        const fetchConfig = async () => {
            for (let attempt = 0; attempt < maxRetries; attempt++) {
                try {
                    const configToFetch =
                        attempt < maxWhitelabelRetries ? brandId : 'default-whitelabel'
                    const result = await loadClientConfig(configToFetch)
                    if (isMounted) {
                        setResource({ status: 'success', result, error: null })
                    }
                    return // Exit the function if successful
                } catch (error) {
                    console.error(error)
                    if (attempt >= maxWhitelabelRetries) {
                        rollbar.error('Error loading whitelabel', {
                            brandId,
                            error,
                        })
                    }
                    if (attempt === maxRetries - 1) {
                        // If this was the last attempt, set the error state
                        if (isMounted) {
                            setResource({ status: 'error', result: null, error })
                        }
                    }
                    // Optionally, you could add a delay before retrying
                    // await new Promise(resolve => setTimeout(resolve, 1000)); // 1 second delay
                }
            }
        }

        fetchConfig()

        return () => {
            isMounted = false // Cleanup to avoid setting state on unmounted component
        }
    }, [brandId])

    return resource
}

const queryKey = Math.random().toString()

const WhitelabelLoader = ({
    children,
}: {
    children: (props: { whitelabel: Whitelabel }) => React.ReactNode
}) => {
    const { company } = useParams()
    const companySplit = company?.split('-')
    const brandId = companySplit?.[companySplit.length - 1]
    if (!brandId) {
        window.location.href = '//www.brakeable.com'
        return null
    }
    const {
        data: backendWhitelabel,
        isFetching,
        error: apiError,
    } = useQuery(
        getBrandById,
        { id: brandId },
        {
            queryKey: [queryKey],
            refetchInterval: Infinity,
            gcTime: Infinity,
        }
    )

    const { status, result: frontendWhitelabel, error } = useClientConfig(company!)
    const rollbar = useRollbar()

    if (status === 'pending' || isFetching) {
        return (
            <Backdrop open={true}>
                <CircularProgress sx={{ color: 'black' }} />
            </Backdrop>
        )
    }
    if (apiError || !backendWhitelabel) {
        rollbar.error('Error loading whitelabel from API', {
            apiError,
        })
        window.location.href = '//www.brakeable.com'
        return null
    }

    if (status === 'error' || !frontendWhitelabel) {
        window.location.href = '//www.brakeable.com'
        return null
    }

    const whitelabel = mergeWhitelabel(frontendWhitelabel, backendWhitelabel)

    return children({ whitelabel })
}

export default WhitelabelLoader
