import { Navigate } from 'react-router-dom'
import { Role } from '../../../../shared/auth/role'
import useAuth from '../../../mixins/use-auth'
import {
    Page,
    Header,
    WidthContainer,
    BrandLogo,
    Content,
    Footer,
    PBB,
    PoweredBy,
    PoweredByBrakeable,
} from '../../../components/layout'
import brkWhitelabel from '../../../../shared/data/whitelabel/Brakeable-va5z1d'
import React from 'react'
import routeBuilder from '../../../routes'
import { ProductCategory, ProductTypeId } from '../../../../shared/data/products/types'
import {
    findProductTypeById,
    getAllProductsFromCategory,
} from '../../../../shared/data/products/helpers'
import { useForm } from 'react-hook-form'
import {
    InputLabel,
    Select,
    MenuItem,
    Stack,
    TextField,
    Box,
    Tab,
    Tabs,
    IconButton,
    Typography,
} from '@mui/material'
import { t } from 'i18next'
import { FieldControl } from '../../../components/form/input-field'
import Pinpoint from './pinpoint'
import CopyToClipboard from '../../../components/copy-to-clipboard'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Whitelabel } from '../../../../shared/data/whitelabel/types'
import mergeObjects from '../../../mixins/merge-object'
import WhitelabelLoader from '../../service-desk/shared/whitelabel-loader'

export type FeatureCoord = {
    x1: number
    y1: number
    x2: number
    y2: number
}

const createFeatureCode = (
    name: string,
    coords: { x1: number; y1: number; x2: number; y2: number }
) => {
    return `
{
name: '${name}',
features: [],
x1: ${Math.round(coords.x1 * 10000) / 10000},
x2: ${Math.round(coords.x2 * 10000) / 10000},
y1: ${Math.round(coords.y1 * 10000) / 10000},
y2: ${Math.round(coords.y2 * 10000) / 10000},
},`
}

function useLocalStorage<T>(key: string, initialValue: T) {
    const readValueFromLocalStorage = () => {
        try {
            const item = window.localStorage.getItem(key)
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            console.error(`Error reading localStorage key “${key}”:`, error)
            return initialValue
        }
    }

    const setValue = (value: T | ((val: T) => T)) => {
        const storedValue = readValueFromLocalStorage()
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value
            window.localStorage.setItem(key, JSON.stringify(valueToStore))
        } catch (error) {
            console.error(`Error setting localStorage key “${key}”:`, error)
        }
    }
    const storedValue = readValueFromLocalStorage()

    return [storedValue, setValue] as const
}

type FeatureMap = Record<ProductTypeId, Record<string, (FeatureCoord & { name: string })[]>>

type FormState = {
    productId: ProductTypeId
    productView: number
    coords: FeatureCoord
    features: FeatureMap
    highlightedFeatureIndex: number | undefined
    editingFeatureIndex: number | undefined
}

const Marker = ({ whitelabel }: { whitelabel: Whitelabel }) => {
    const [storedinLocalStorage, storeInLocalStorage] = useLocalStorage<Partial<FormState>>(
        'brk-admin-marker',
        {}
    )

    const allProducts = getAllProductsFromCategory(
        whitelabel.productCategoryTree as ProductCategory
    )
    const allRepairServices = whitelabel.repairServices
    const liveFeatures = allProducts.reduce((acc, product) => {
        acc[product.id] = product.views.reduce((acc, view) => {
            const label = t(view.label as any)
            acc[label] = [...(acc[label] ?? []), ...view.details]
            return acc
        }, {} as Record<string, (FeatureCoord & { name: string })[]>)
        return acc
    }, {} as FeatureMap)

    const defaultForm = {
        productId: -1,
        productView: -1,
        coords: { x1: 0, y1: 0, x2: 0, y2: 0 },
        features: liveFeatures,
        highlightedFeatureIndex: undefined,
        editingFeatureIndex: undefined,
    }

    const form = useForm<FormState>({
        defaultValues: mergeObjects(defaultForm, storedinLocalStorage),
    })

    const selectedProduct = findProductTypeById(
        form.watch('productId') as ProductTypeId,
        whitelabel.productCategoryTree
    )

    const allProductViews = selectedProduct?.views ?? []

    const selectedProductView = allProductViews[form.watch('productView')]

    const zoneChanged = (coords: { x1: number; y1: number; x2: number; y2: number }) => {
        if (form.watch('editingFeatureIndex') !== undefined) {
            // overwrite the coords
            const feature = form.watch(
                `features.${selectedProduct!.id}.${t(
                    selectedProductView!.label as any
                )}[${form.watch('editingFeatureIndex')}]` as any
            )
            form.setValue(
                `features.${selectedProduct!.id}.${t(
                    selectedProductView!.label as any
                )}[${form.watch('editingFeatureIndex')}]` as any,
                { ...feature, ...coords }
            )
            form.setValue('editingFeatureIndex', undefined)
            return
        }
        const features: FeatureCoord[] =
            form.watch(
                `features.${selectedProduct!.id}.${t(selectedProductView!.label as any)}` as any
            ) ?? []
        form.setValue(
            `features.${selectedProduct!.id}.${t(selectedProductView!.label as any)}` as any,
            [...features, { ...coords, name: '' }]
        )
    }

    const zones = selectedProduct
        ? form.watch('features')[selectedProduct.id]?.[t(selectedProductView.label as any)] ?? []
        : []

    const code = selectedProduct
        ? `${selectedProduct?.internalName}.${t(selectedProductView.label as any)}: [${zones
              .map((zone) => createFeatureCode(zone.name, zone))
              .join('')}\n]`
        : ''

    const currentFormState = form.watch()
    React.useEffect(() => {
        storeInLocalStorage(currentFormState)
    }, [currentFormState])

    return (
        <Page>
            <Header>
                <WidthContainer $wide sx={{ justifyContent: 'space-between' }}>
                    <a href={routeBuilder.base()}>
                        <BrandLogo
                            src="https://ucarecdn.com/9e0bb50d-8d9f-4779-ae66-f32c5b04d9ad/"
                            alt="Brakeable"
                        />
                    </a>
                </WidthContainer>
            </Header>
            <Content sx={{ justifyContent: 'center' }}>
                <WidthContainer $wide>
                    <FieldControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="productId">Product</InputLabel>
                        <Select
                            labelId="productId"
                            id="productId"
                            required
                            disabled={allProducts.length === 0}
                            value={form.watch('productId')}
                            {...form.register('productId')}
                            onChange={(e) => {
                                form.setValue('productView', 0)
                                form.setValue('productId', e.target.value as ProductTypeId)
                            }}
                        >
                            {allProducts.map((product) => {
                                return (
                                    <MenuItem key={product.id} value={product.id}>
                                        {product.internalName}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FieldControl>
                    <Stack spacing={2} direction="row" height="100%" width="100%" flexGrow={1}>
                        <Stack spacing={2} direction="column" width="50%" height="100%">
                            {selectedProduct && (
                                <Tabs
                                    value={form.watch('productView')}
                                    onChange={(_, view) => form.setValue('productView', view)}
                                    aria-label="Different product view tabs"
                                    sx={{ width: 'auto', alignSelf: 'center' }}
                                >
                                    {allProductViews.map((view) => (
                                        <Tab
                                            key={view.label}
                                            label={t(view.label as any)}
                                            id={`product-view-tabs-${view.label}`}
                                            aria-controls={`productview-tabpanel-${view.label}`}
                                        />
                                    ))}
                                </Tabs>
                            )}
                            {selectedProduct && selectedProductView && (
                                <Pinpoint
                                    productImageUrl={selectedProductView.sketchUrl ?? ''}
                                    onZoneChanged={zoneChanged}
                                    zones={zones}
                                    highlightedFeatureIndex={form.watch('highlightedFeatureIndex')}
                                    editingFeatureIndex={form.watch('editingFeatureIndex')}
                                />
                            )}
                            {selectedProduct &&
                                allRepairServices
                                    .filter((s) =>
                                        selectedProduct.applicableServices.includes(s.id)
                                    )
                                    .map((service) => (
                                        <Typography key={service.id}>
                                            {service.id} - {service.title}
                                        </Typography>
                                    ))}
                        </Stack>
                        <Stack
                            spacing={2}
                            direction="column"
                            sx={{
                                width: '50%',
                                height: '100%',
                                overflowY: 'auto',
                            }}
                        >
                            {selectedProduct && (
                                <>
                                    <Box
                                        sx={{
                                            border: '1px solid',
                                            borderColor: 'grey.300',
                                            padding: '0 1',
                                            borderRadius: 1,
                                        }}
                                    >
                                        <Stack
                                            spacing={1}
                                            direction="row"
                                            justifyContent="space-between"
                                            sx={{
                                                p: 1,
                                                borderBottom: '1px solid',
                                                borderColor: 'grey.300',
                                            }}
                                        >
                                            <Typography variant="h6">Features</Typography>
                                            <CopyToClipboard
                                                label="Copy code"
                                                copiedLabel="Copied!"
                                                link={code}
                                            />
                                        </Stack>
                                        <Stack spacing={1} direction="column">
                                            {zones.map((zone, index) => (
                                                <Stack
                                                    direction="row"
                                                    spacing={2}
                                                    key={
                                                        zone.x1 +
                                                        zone.y1 +
                                                        zone.x2 +
                                                        zone.y2 +
                                                        index +
                                                        selectedProduct.internalName
                                                    }
                                                    sx={{
                                                        ':nth-child(even)': {
                                                            background: 'rgba(0,0,0,0.05)',
                                                        },
                                                        backgroundColor:
                                                            form.watch('editingFeatureIndex') ===
                                                            index
                                                                ? 'orange !important'
                                                                : 'transparent',
                                                        padding: 2,
                                                        borderRadius: 1,
                                                    }}
                                                    justifyContent="space-between"
                                                    onMouseEnter={() =>
                                                        form.setValue(
                                                            'highlightedFeatureIndex',
                                                            index
                                                        )
                                                    }
                                                    onMouseLeave={() =>
                                                        form.setValue(
                                                            'highlightedFeatureIndex',
                                                            undefined
                                                        )
                                                    }
                                                >
                                                    <TextField
                                                        label="Name"
                                                        value={zone.name}
                                                        sx={{ flexGrow: 1 }}
                                                        onChange={(e) => {
                                                            form.setValue(
                                                                `features.${
                                                                    selectedProduct!.id
                                                                }.${t(
                                                                    selectedProductView!
                                                                        .label as any
                                                                )}[${index}].name` as any,
                                                                e.target.value
                                                            )
                                                        }}
                                                    />
                                                    <IconButton
                                                        sx={{ alignSelf: 'center' }}
                                                        onClick={() => {
                                                            // remove the whole zone
                                                            form.setValue(
                                                                `features.${
                                                                    selectedProduct!.id
                                                                }.${t(
                                                                    selectedProductView!
                                                                        .label as any
                                                                )}` as any,
                                                                zones.filter((_, i) => i !== index)
                                                            )
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        sx={{ alignSelf: 'center' }}
                                                        onClick={() =>
                                                            form.setValue(
                                                                'editingFeatureIndex',
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Stack>
                                            ))}
                                        </Stack>
                                    </Box>
                                    <TextField multiline maxRows={10} value={code} />
                                </>
                            )}
                        </Stack>
                    </Stack>
                </WidthContainer>
            </Content>
            <Footer>
                <WidthContainer $wide sx={{ justifyContent: 'flex-end' }}>
                    <PBB>
                        <PoweredBy>Powered by</PoweredBy>{' '}
                        <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                    </PBB>
                </WidthContainer>
            </Footer>
        </Page>
    )
}

const MarkerPage = () => (
    <WhitelabelLoader>{({ whitelabel }) => <Marker whitelabel={whitelabel} />}</WhitelabelLoader>
)

const RoleSwitch = () => {
    const { user } = useAuth()
    switch (user?.role) {
        case Role.Admin:
            return <MarkerPage />
        default:
            return <Navigate to="/" />
    }
}

export default RoleSwitch
