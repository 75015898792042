import React from 'react'
import { styled, useTheme, Box } from '@mui/material'
import createUploadcareImageSrc from '../../../mixins/create-uploadcare-image-src'
import Coord from '../../../../shared/types/common/coord'
import { useForm } from 'react-hook-form'
import { FeatureCoord } from '.'

const ImageWrapper = styled('div')`
    max-width: 100%;
    max-height: 100%;
    position: relative;
    min-height: 0; // to shrink element to fit image inside flexbox
    flex-shrink: 1;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: #f9f9f9;
    border: 1px solid #000;
    box-sizing: content-box;
`

const ProductImage = styled('img')`
    max-width: 100%;
    max-height: 100%;
    position: relative;
    cursor: pointer;
    transition: filter 0.1s;
`

const Crosshair = styled('div')`
    width: 60px;
    height: 60px;
    position: absolute;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 100;

    svg {
        width: 100%;
        height: 100%;
    }
`

const Pinpoint = ({
    productImageUrl,
    onZoneChanged,
    zones = [],
    highlightedFeatureIndex,
    editingFeatureIndex,
}: {
    productImageUrl: string
    onZoneChanged: (coord: FeatureCoord) => void
    zones: FeatureCoord[]
    highlightedFeatureIndex: number | undefined
    editingFeatureIndex: number | undefined
}) => {
    const theme = useTheme()
    const form = useForm({
        defaultValues: {
            mouseDown: false,
            x1: -1,
            y1: -1,
            x2: -1,
            y2: -1,
        },
    })
    const [imageSize, setImageSize] = React.useState<{ width: number; height: number }>({
        width: 0,
        height: 0,
    })

    const imageRef = React.useRef<HTMLImageElement>(null)

    const calculateCoordinates = (coord: Coord) => {
        if (imageRef.current === null) {
            return
        }
        const imageRect = imageRef.current.getBoundingClientRect()

        const calculatedCoordinates = {
            x: Math.floor(Math.min(Math.max(coord.x - imageRect.x, 0), imageRect.width)),
            y: Math.floor(Math.min(Math.max(coord.y - imageRect.y, 0), imageRect.height)),
        }

        const proportionalCoordinates = {
            x: calculatedCoordinates.x / imageRect.width,
            y: calculatedCoordinates.y / imageRect.height,
        }

        return proportionalCoordinates
    }

    const handleMouseDown = (e: any) => {
        e.preventDefault()
        form.setValue('mouseDown', true)
        const c = calculateCoordinates({ x: e.clientX, y: e.clientY })
        if (c) {
            form.setValue('x1', c.x)
            form.setValue('y1', c.y)
        }
    }

    const handleMouseMove = (e: any) => {
        e.preventDefault()
        if (!form.watch('mouseDown')) {
            return
        }
        const c = calculateCoordinates({ x: e.clientX, y: e.clientY })
        if (c) {
            form.setValue('x2', c.x)
            form.setValue('y2', c.y)
        }
    }

    const handleMouseUp = (e: any) => {
        if (form.watch('mouseDown') === false) {
            return
        }
        e.preventDefault()
        const coords = form.watch()
        onZoneChanged({
            x1: Math.min(coords.x1, coords.x2),
            y1: Math.min(coords.y1, coords.y2),
            x2: Math.max(coords.x1, coords.x2),
            y2: Math.max(coords.y1, coords.y2),
        })
        form.reset()
    }

    const updateImageSize = (e: any) => {
        if (imageRef.current === null) {
            return
        }

        setImageSize({
            width: imageRef.current.offsetWidth,
            height: imageRef.current.offsetHeight,
        })
    }

    React.useEffect(() => {
        window.addEventListener('resize', updateImageSize)
        window.addEventListener('pageshow', updateImageSize)
        window.addEventListener('focus', updateImageSize)
        window.addEventListener('visibilitychange', updateImageSize)

        if (imageRef.current === null) {
            return
        }

        document.addEventListener('mouseup', handleMouseUp, { passive: false })
        document.addEventListener('mousemove', handleMouseMove, { passive: false })
        imageRef.current.addEventListener('mousedown', handleMouseDown, { passive: false })
        imageRef.current.addEventListener('load', updateImageSize, { passive: false })

        return () => {
            window.removeEventListener('resize', updateImageSize)
            window.removeEventListener('pageshow', updateImageSize)
            window.removeEventListener('focus', updateImageSize)
            window.removeEventListener('visibilitychange', updateImageSize)

            if (imageRef.current === null) {
                return
            }
            imageRef.current.removeEventListener('mousedown', handleMouseDown)
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', handleMouseUp)
            imageRef.current.removeEventListener('load', updateImageSize)
        }
    }, [
        imageRef,
        imageRef.current,
        updateImageSize,
        handleMouseDown,
        handleMouseMove,
        handleMouseUp,
    ])

    const pinpointSet = form.watch('x2') >= 0

    const c = form.watch()

    return (
        <>
            <ImageWrapper
                style={{
                    width: imageSize.width > 0 ? imageSize.width + 'px' : '100%',
                    height: imageSize.height > 0 ? imageSize.height + 'px' : '100%',
                }}
            >
                <ProductImage
                    data-testid="pinpoint-image"
                    ref={imageRef}
                    onLoad={updateImageSize}
                    src={createUploadcareImageSrc(productImageUrl, {
                        format: undefined,
                        quality: undefined,
                        fill: undefined,
                    })}
                    key={productImageUrl}
                />
                {pinpointSet && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: Math.min(c.y1, c.y2) * 100 + '%',
                            left: Math.min(c.x1, c.x2) * 100 + '%',
                            height: Math.abs(c.y2 - c.y1) * 100 + '%',
                            width: Math.abs(c.x2 - c.x1) * 100 + '%',
                            border: '1px solid red',
                            pointerEvents: 'none',
                            fontSize: '.25em',
                        }}
                    ></Box>
                )}
                {zones.map((z, index) => (
                    <Box
                        key={index}
                        sx={{
                            position: 'absolute',
                            top: Math.min(z.y1, z.y2) * 100 + '%',
                            left: Math.min(z.x1, z.x2) * 100 + '%',
                            height: Math.abs(z.y2 - z.y1) * 100 + '%',
                            width: Math.abs(z.x2 - z.x1) * 100 + '%',
                            border: '1px solid blue',
                            borderColor: editingFeatureIndex === index ? 'orange' : 'blue',
                            backgroundColor:
                                editingFeatureIndex === index
                                    ? 'rgba(255,165,0,0.2)'
                                    : highlightedFeatureIndex === index
                                    ? 'rgba(0,0,255,0.2)'
                                    : 'transparent',
                            pointerEvents: 'none',
                            fontSize: '.25em',
                        }}
                    ></Box>
                ))}
            </ImageWrapper>
        </>
    )
}

export default Pinpoint
