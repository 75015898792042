import {
    Page,
    Header,
    WidthContainer,
    BrandLogo,
    Content,
    Footer,
    PBB,
    PoweredBy,
    PoweredByBrakeable,
} from '../../../components/layout'
import React from 'react'
import { Whitelabel } from '../../../../shared/data/whitelabel/types'
import { OrderType } from '../../../../shared/types/service-desk/order-type'
import { createTheme, ThemeProvider } from '@mui/material'
import { Stack } from '@mui/material'
import routeBuilder from '../../../routes'
import { LinktreeEntryLink } from '../shared/components/linktree-entry'
import mergeObjects from '../../../mixins/merge-object'
import T from '../../../components/typography/t'
import WhitelabelLoader from '../shared/whitelabel-loader'

const ServiceDeskHub = ({
    whitelabel,
    orderType,
}: {
    whitelabel: Whitelabel
    orderType: OrderType
}) => {
    return (
        <ThemeProvider
            theme={(theme: any) =>
                createTheme({
                    ...theme,
                    palette: {
                        ...theme.palette,
                        ...whitelabel?.theme.palette,
                    },
                    components: mergeObjects(theme.components, whitelabel?.theme.components),
                } as any)
            }
        >
            <Page>
                <Header>
                    <WidthContainer $wide sx={{ justifyContent: 'space-between' }}>
                        <BrandLogo src={whitelabel.logo} alt={whitelabel.name} />
                    </WidthContainer>
                </Header>
                <Content sx={{ justifyContent: 'center' }}>
                    <WidthContainer>
                        <T
                            i18nKey="serviceDesk.hub.title"
                            variant="h4"
                            component="h1"
                            color="primary"
                            mb={4}
                        ></T>
                        <Stack gap={2}>
                            {whitelabel.config.repair && (
                                <LinktreeEntryLink
                                    color="primary"
                                    href={routeBuilder.serviceDesk(
                                        whitelabel.id,
                                        whitelabel.name,
                                        'repair',
                                        orderType
                                    )}
                                >
                                    <T
                                        variant="h3"
                                        mb={1}
                                        i18nKey={`serviceDesk.hub.services.repair.title`}
                                    />
                                    <T
                                        variant="body1"
                                        i18nKey={`serviceDesk.hub.services.repair.subtitle`}
                                    />
                                </LinktreeEntryLink>
                            )}
                            {whitelabel.config.warranty && (
                                <LinktreeEntryLink
                                    color="primary"
                                    href={routeBuilder.serviceDesk(
                                        whitelabel.id,
                                        whitelabel.name,
                                        'warranty',
                                        orderType
                                    )}
                                >
                                    <T
                                        variant="h3"
                                        mb={1}
                                        i18nKey={`serviceDesk.hub.services.warranty.title`}
                                    />
                                    <T
                                        variant="body1"
                                        i18nKey={`serviceDesk.hub.services.warranty.subtitle`}
                                    />
                                </LinktreeEntryLink>
                            )}
                            {whitelabel.config.alterations && (
                                <LinktreeEntryLink
                                    color="primary"
                                    href={routeBuilder.serviceDesk(
                                        whitelabel.id,
                                        whitelabel.name,
                                        'alteration',
                                        orderType
                                    )}
                                >
                                    <T
                                        variant="h3"
                                        mb={1}
                                        i18nKey={`serviceDesk.hub.services.alteration.title`}
                                    />
                                    <T
                                        variant="body1"
                                        i18nKey={`serviceDesk.hub.services.alteration.subtitle`}
                                    />
                                </LinktreeEntryLink>
                            )}
                        </Stack>
                    </WidthContainer>
                </Content>
                <Footer>
                    <WidthContainer $wide sx={{ justifyContent: 'flex-end' }}>
                        <PBB>
                            <PoweredBy>Powered by</PoweredBy>{' '}
                            <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                        </PBB>
                    </WidthContainer>
                </Footer>
            </Page>
        </ThemeProvider>
    )
}

export const ServiceDeskHubB2B = () => (
    <WhitelabelLoader>
        {({ whitelabel }) => <ServiceDeskHub whitelabel={whitelabel} orderType={OrderType.B2B} />}
    </WhitelabelLoader>
)

export const ServiceDeskHubB2C = () => (
    <WhitelabelLoader>
        {({ whitelabel }) => <ServiceDeskHub whitelabel={whitelabel} orderType={OrderType.B2C} />}
    </WhitelabelLoader>
)
