import { FieldPath, type FieldValues, type UseFormSetValue } from 'react-hook-form'
import { StepProps } from './helper/use-slider'
import { UploadClient } from '@uploadcare/upload-client'

export type Form<FormType extends FieldValues> = {
    setValue: UseFormSetValue<FormType>
    formValues: FormType
    submitForm: () => Promise<void>
    resetForm: (ignoreFields?: FieldPath<FormType>[]) => void
}

export type Meta = {
    value?: any
    setMetaValue: (value: any) => void
    resetMeta: () => void
}

export type MultiStepFormError = {
    id?: string
    message: string
    action?: {
        label: string
        onClick: () => void
    }
}

export type ErrorCtx = {
    pushError: (error: MultiStepFormError) => void
    popError: () => void
    errors: MultiStepFormError[]
}

export type MultiStepFormContextType<StepCtx, FormValues extends FieldValues> = {
    ctx: StepCtx
    props: StepProps
    form: Form<FormValues>
    meta: Meta
    error: ErrorCtx
    uploadCareClient: UploadClient
}

export const getDefaultContextValues = <StepCtx, FormValues extends FieldValues>() => ({
    ctx: {} as StepCtx,
    props: {} as StepProps,
    form: {} as Form<FormValues>,
    meta: {} as Meta,
    error: {} as ErrorCtx,
    uploadCareClient: {} as UploadClient,
})
