import React from 'react'
import { useTranslation } from 'react-i18next'
import { RepairMultiStepFormContext } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import InspectorStepTitle from '../../../../components/typography/inspector-step-title'
import { Button, Stack } from '@mui/material'
import T from '../../../../components/typography/t'
import ContactCard from '../../shared/components/contact-card'
import createFormField from '../../shared/helper/create-form-field'

const RequestRepairOfferStep: React.FC<{
    contactForm: string[][]
    onSubmit: () => void
}> = ({ contactForm, onSubmit }) => {
    const [t] = useTranslation()
    return (
        <Stack gap={6} width="100%">
            <InspectorStepTitle i18nKey="serviceDesk.repair.requestRepairOfferStep.title" />
            <ContactCard contactForm={contactForm} />
            <Button variant="contained" fullWidth onClick={onSubmit}>
                <T i18nKey="serviceDesk.repair.requestRepairOfferStep.submitAction" />
            </Button>
        </Stack>
    )
}

const RequestRepairOfferStepContext = () => {
    const { form, ctx, props } = React.useContext(RepairMultiStepFormContext)
    const [isLoading, setIsloading] = React.useState(false)

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                hideNextButton: true,
            }}
            isLoading={isLoading}
        >
            <RequestRepairOfferStep
                contactForm={ctx.whitelabel.config.repair.contactForm.fields
                    .map((line) =>
                        line
                            .map(createFormField(form.formValues.contactFormValues))
                            .filter((cell) => !cell.excludeFromSummary)
                            .map((cell) => {
                                const value = form.formValues.contactFormValues[cell.key]
                                return typeof value === 'string'
                                    ? value
                                    : value.print ?? value.value
                            })
                    )
                    .filter((line) => line.length > 0)}
                onSubmit={async () => {
                    try {
                        setIsloading(true)
                        await form.submitForm()
                        setIsloading(false)
                        props.nextSlide()
                    } catch (error) {
                        console.error(error)
                    } finally {
                        setIsloading(false)
                    }
                }}
            />
        </StepFrameworkWrapper>
    )
}

export default RequestRepairOfferStepContext
