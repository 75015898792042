import React from 'react'
import { UploadClient } from '@uploadcare/upload-client'
import {
    FormValues,
    AlterationsMultiStepFormContext,
    WhitelabelWithAlterationsConfig,
} from './context'
import { MultiStepLayout } from '../../../components/layout'
import { useTranslation } from 'react-i18next'
import RetailerValidationStep from './steps/b2b/retailer-validation'
import ProductSelectionStep from './steps/product-selection'
import AlterationSelectionStep from './steps/alteration-selection'
import ContactStep from './steps/contact'
import RequestAlterationsStep from './steps/request-alterations'
import OutroStep from './steps/outro/index'
import DetailsStep from './steps/details'
import useSlider, { type Slide } from '../shared/helper/use-slider'
import useMultiStepForm from '../shared/helper/use-multi-step-form'
import useMultiStepError from '../shared/helper/use-multi-step-error'
import { useAnalytics } from 'use-analytics'
import { WarrantyRequestStepChangeEvent } from '../../../../shared/analytics'
import useUrlQuery from '../../../mixins/use-url-query'
import { CustomInformationStepType } from '../../../../shared/data/whitelabel/types'
import PurchaseConfirmationStep from './steps/purchase-confirmation'
import { OrderType } from '../../../../shared/types/service-desk/order-type'

const AlterationsMultiStep = ({
    onSubmit,
    uploadCareClient,
    whitelabel,
    orderType,
}: {
    onSubmit: (formValues: FormValues) => Promise<void>
    uploadCareClient: UploadClient
    whitelabel: WhitelabelWithAlterationsConfig
    orderType: OrderType
}) => {
    const [getIFrameQueryParam] = useUrlQuery('iframe')
    const isIframe = getIFrameQueryParam() === 'true'
    const activateContactScreenIfB2C = orderType === OrderType.B2C
    const slideDefinition: Slide[] = [
        {
            name: 'RetailerValidationStep',
            Component: () => <RetailerValidationStep />,
            skip: orderType !== OrderType.B2B,
        },
        { name: 'ProductSelectionStep', Component: () => <ProductSelectionStep />, skip: false },
        {
            name: 'AlterationSelectionStep',
            Component: () => <AlterationSelectionStep />,
            skip: false,
        },
        ...(whitelabel.config.alterations.alterationInformationSteps?.map((step) => ({
            name: 'AlterationInformationStep-' + step.fieldKey,
            Component: () =>
                step.type === CustomInformationStepType.Photo ? (
                    <PurchaseConfirmationStep {...step} />
                ) : (
                    <DetailsStep {...step} />
                ),
            skip: false,
        })) ?? []),
        {
            name: 'ContactStep',
            Component: () => <ContactStep />,
            skip: !activateContactScreenIfB2C,
        },
        {
            name: 'RequestAlterationsStep',
            Component: () => <RequestAlterationsStep />,
            skip: false,
        },
        { name: 'OutroStep', Component: () => <OutroStep />, skip: false },
    ]

    const analytics = useAnalytics()
    const [t] = useTranslation()
    const [meta, setMeta] = React.useState<any>({})
    const {
        getSlides,
        slideIndex,
        nextSlide,
        prevSlide,
        setSlide,
        gotoEnd,
        currentStep,
        maxSteps,
        setSlideSkip,
        direction,
    } = useSlider(slideDefinition, 0)

    const { errors, pushError, popError } = useMultiStepError()
    const formDefaultValues: FormValues = {
        selectedProduct: undefined,
        selectedProductView: 0,
        description: undefined,
        selectedAlterations: [],
        alterationInformationForm: {},
        contactFormValues: {},
        retailerCode: undefined,
        retailerForm: {},
        requestIndividualSupport: false,
        individualSupportForm: {},
    }
    const { form, resetForm, submitForm, formValues } = useMultiStepForm<FormValues>(
        formDefaultValues,
        onSubmit
    )

    const productCategoryTree =
        whitelabel.config.alterations.alterationProductTree || whitelabel.productCategoryTree

    React.useEffect(() => {
        analytics.track(WarrantyRequestStepChangeEvent, {
            brandId: whitelabel.id,
            slide: getSlides()[slideIndex].name,
        })
    }, [slideIndex])

    return (
        <AlterationsMultiStepFormContext.Provider
            value={{
                ctx: {
                    whitelabel,
                    productCategoryTree,
                    isIframe,
                },
                props: {
                    currentStep,
                    maxSteps,
                    nextSlide,
                    prevSlide,
                    setSlide,
                    gotoEnd,
                    setSlideSkip,
                    direction,
                },
                form: {
                    setValue: form.setValue,
                    formValues,
                    submitForm,
                    resetForm,
                },
                meta: {
                    value: meta,
                    setMetaValue: setMeta,
                    resetMeta: () => {
                        setMeta({})
                    },
                },
                error: {
                    errors,
                    pushError,
                    popError,
                },
                uploadCareClient,
            }}
        >
            <MultiStepLayout logo={whitelabel.logo} progress={currentStep / (maxSteps - 1)}>
                {getSlides()[slideIndex].Component()}
            </MultiStepLayout>
        </AlterationsMultiStepFormContext.Provider>
    )
}

export default AlterationsMultiStep
