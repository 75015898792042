import Typography from '@mui/material/Typography'
import { OverridableTypeMap, OverrideProps } from '@mui/material/OverridableComponent'
import { Trans } from 'react-i18next'

export type TProps = {
    children?: React.ReactNode
} & Parameters<typeof Trans>[0] &
    OverrideProps<OverridableTypeMap, React.ElementType>

export type I18nKey = TProps['i18nKey']

const T = ({ i18nKey, count = 1, children, values, components, ...props }: TProps) => {
    const child = (
        <Trans
            i18nKey={i18nKey as any}
            count={count}
            values={values}
            components={components}
        ></Trans>
    )

    if (Object.keys(props).length > 0) {
        return (
            <Typography {...props}>
                {child}
                {children}
            </Typography>
        )
    }

    return child
}

export default T
