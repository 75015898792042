import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardTitle from '../../../../components/typography/card-title'
import {
    ContactRequest as ContactRequestT,
    ContactRequests as ContactRequestsT,
} from '../../../../../shared/types/user-management/contact-requests'
import HeaderBar from '../../inspector/products/components/header-bar'
import { UncontrolledBoard, KanbanBoard, Card as CardT } from '@caldwell619/react-kanban'
import SupportState from '../../../../../shared/support/support-state'
import '@caldwell619/react-kanban/dist/styles.css'
import './kanban-board.scss'
import InspectionImageTile from '../../common/inspection-image-tile'
import routeBuilder from '../../../../routes'
import CloseIcon from '@mui/icons-material/Close'
import SubmissionDialog from '../../common/submission-detail/submission-dialog'
import styled from 'styled-components'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import { useIsMobile } from '../../../../mixins/media-query'
import SubmissionId from '../../../../../shared/types/submission/id'
import { useTranslation } from 'react-i18next'
import createUploadcareImageSrc from '../../../../mixins/create-uploadcare-image-src'
import T from '../../../../components/typography/t'

const TitleBar = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledTitle = styled(DialogTitle)`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: calc(100% - 64px);
`

interface SupportCard extends CardT, ContactRequestT {}

const ContactRequests: React.FC<{
    contactRequests: ContactRequestsT
    updateSupportRequestBoard: (
        args: { submissionId: SubmissionId; state: SupportState; orderNo: number }[]
    ) => void
}> = ({ contactRequests, updateSupportRequestBoard }) => {
    const isMobile = useIsMobile()
    const [t] = useTranslation()
    const [openSupportRequest, setOpenSupportRequest] = React.useState<ContactRequestT | undefined>(
        undefined
    )

    const showSupportRequestDialog = (contactRequest: ContactRequestT) => {
        setOpenSupportRequest(contactRequest)
    }

    const closeSupportRequestDialog = () => {
        setOpenSupportRequest(undefined)
    }

    const getSupportRequestsForState = (state: SupportState): SupportCard[] =>
        contactRequests
            .filter((sr) => sr.submission.state === state)
            .map((sr) => ({
                id: sr.submission.id,
                ...sr,
            }))
            .sort((a, b) =>
                a.submission.orderNo === b.submission.orderNo
                    ? a.submission.id - b.submission.id
                    : a.submission.orderNo - b.submission.orderNo
            )

    const board: KanbanBoard<SupportCard> = {
        columns: [
            {
                id: SupportState.Open,
                title: t('company.support.supportRequests.kanban.open' as any),
                cards: getSupportRequestsForState(SupportState.Open),
            },
            {
                id: SupportState.InProgress,
                title: t('company.support.supportRequests.kanban.inProgress' as any),
                cards: getSupportRequestsForState(SupportState.InProgress),
            },
            {
                id: SupportState.Done,
                title: t('company.support.supportRequests.kanban.done' as any),
                cards: getSupportRequestsForState(SupportState.Done),
            },
        ],
    }

    const onDrag = (
        board: KanbanBoard<SupportCard>,
        card: SupportCard,
        source: any,
        destination: { toColumnId: SupportState }
    ) => {
        const col = board.columns.find((column) => column.id === destination!.toColumnId)
        const updatedCol = col!.cards.map((card, index) => ({
            submissionId: card.submission.id,
            state: destination!.toColumnId,
            orderNo: index,
        }))

        updateSupportRequestBoard(updatedCol)
    }

    return (
        <Box>
            <HeaderBar>
                <CardTitle>
                    <T i18nKey="company.support.supportRequests.title" />
                </CardTitle>
            </HeaderBar>

            <Card sx={{ overflowX: 'auto', width: '100%' }}>
                <UncontrolledBoard
                    initialBoard={board}
                    disableColumnDrag
                    allowRemoveCard={false}
                    allowRemoveColumn={false}
                    allowRenameColumn={false}
                    onCardDragEnd={onDrag as any}
                    renderCard={(card, { dragging }) => (
                        <Box sx={{ width: '100%', mb: 2, boxShadow: dragging ? 8 : 'none' }}>
                            <InspectionImageTile
                                onClick={() => showSupportRequestDialog(card)}
                                imageUrl={createUploadcareImageSrc(card.submission.image, {
                                    preview: '400x400',
                                    crop: '1:1',
                                    resize: '400x400',
                                })}
                                isUnseenByUser={card.submission.unseenByUser}
                                labels={card.submission.labels}
                                inspectionOrigin={card.submission.inspectionOrigin}
                            />
                        </Box>
                    )}
                ></UncontrolledBoard>
            </Card>
            <Dialog
                open={!!openSupportRequest}
                onClose={closeSupportRequestDialog}
                fullWidth
                maxWidth="md"
                fullScreen={isMobile}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        zIndex: 1,
                        right: 8,
                        top: 8,
                        background: '#fff',
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={closeSupportRequestDialog}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                {!!openSupportRequest && (
                    <>
                        <TitleBar>
                            <StyledTitle>
                                <Box mx={1} maxWidth="100%">
                                    <Link
                                        href={routeBuilder.productDashboardLink(
                                            openSupportRequest.product.id
                                        )}
                                        maxWidth="100%"
                                        display="inline-block"
                                        textAlign="center"
                                    >
                                        {openSupportRequest.product.name} #
                                        {openSupportRequest.submission.id}
                                    </Link>
                                    <Typography
                                        variant="subtitle2"
                                        color="grey.400"
                                        component="p"
                                        textAlign="center"
                                    >
                                        {openSupportRequest.submission.createdAt}
                                    </Typography>
                                </Box>
                            </StyledTitle>
                        </TitleBar>
                        <SubmissionDialog
                            submission={{
                                ...openSupportRequest.submission,
                                productId: openSupportRequest.product.id,
                                productName: openSupportRequest.product.name,
                                productImgUrl: openSupportRequest.product.imgUrl,
                            }}
                        ></SubmissionDialog>
                    </>
                )}
            </Dialog>
        </Box>
    )
}

export default ContactRequests
