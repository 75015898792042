import React from 'react'
import { ProductCategoryNode } from '../../../../../../shared/data/products/types'
import { useTheme } from '@mui/material'
import T from '../../../../../components/typography/t'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

type BreadcrumbProps = {
    currentNode: ProductCategoryNode
    onSelect: (node: ProductCategoryNode) => void
    productCategories: ProductCategoryNode
}

const Breadcrumbs: React.FC<BreadcrumbProps> = ({ currentNode, onSelect, productCategories }) => {
    const theme = useTheme()
    // Helper function to find the parent path to the current node
    const findParentPath = (
        node: ProductCategoryNode,
        root: ProductCategoryNode,
        path: ProductCategoryNode[] = []
    ): ProductCategoryNode[] | null => {
        // If the current node is the root, return the path
        if (node === root) {
            return path
        }

        // If the current node is a ProductCategory, check its subItems
        if ('subItems' in root) {
            for (const subItem of root.subItems) {
                // If the subItem is the current node, return the path
                if (subItem === node) {
                    return [...path, root] // Add the parent to the path
                }

                // Recursively search in subItems
                const result = findParentPath(node, subItem, [...path, root]) // Pass a copy of the path
                if (result) {
                    return result // Return the path if found
                }
            }
        }

        return null // Return null if not found
    }

    // Get the breadcrumb path
    const path = findParentPath(currentNode, productCategories) // Start from the root

    return (
        <nav aria-label="Breadcrumb">
            <ul
                style={{
                    listStyle: 'none',
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                }}
            >
                {path &&
                    path.map((node, index) => (
                        <li
                            key={node.id.toString()}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {index > 0 && (
                                <ArrowForwardIosIcon
                                    fontSize="small"
                                    sx={{
                                        verticalAlign: 'middle',
                                        color: 'grey',
                                        margin: '0 .5em ',
                                        fontSize: '.9em',
                                    }}
                                />
                            )}
                            <button
                                onClick={() => onSelect(node)}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    color: theme.palette.primary.main,
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    margin: '0',
                                    fontSize: '.9em',
                                    textWrap: 'nowrap',
                                }}
                            >
                                <T i18nKey={node.name as any} />
                            </button>
                        </li>
                    ))}
                {/* Render the current node without a link */}
                <li>
                    <ArrowForwardIosIcon
                        fontSize="small"
                        sx={{
                            verticalAlign: 'middle',
                            color: 'grey',
                            margin: '0 .5em ',
                            fontSize: '.9em',
                        }}
                    />
                    <span
                        style={{
                            fontWeight: '400',
                            textWrap: 'nowrap',
                        }}
                    >
                        <T i18nKey={currentNode.name as any} />
                    </span>
                </li>
            </ul>
        </nav>
    )
}

export default Breadcrumbs
