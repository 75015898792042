import { FieldPath, type FieldValues, type DefaultValues, useForm } from 'react-hook-form'

const useMultiStepForm = <FormType extends FieldValues>(
    defaultValues: DefaultValues<FormType>,
    onSubmit: (data: FormType) => Promise<void>
) => {
    const form = useForm<FormType>({
        defaultValues: defaultValues,
    })
    const resetForm = (ignoreFields: FieldPath<FormType>[] = []) => {
        const fields = Object.keys(form.getValues()) as FieldPath<FormType>[]
        const fieldsToReset = fields.filter((field) => !ignoreFields.includes(field))
        fieldsToReset.forEach((field) => {
            form.setValue(field, defaultValues[field])
        })
    }

    const submitForm = async () => {
        await form.handleSubmit(onSubmit)()
    }
    const formValues = (Object.keys(defaultValues) as FieldPath<FormType>[]).reduce(
        (acc, key) => ({ ...acc, [key]: form.watch(key) }),
        {}
    ) as FormType

    return {
        form,
        resetForm,
        submitForm,
        formValues,
    }
}

export default useMultiStepForm
