import React from 'react'
import { Stack, styled, Typography, IconButton, Box } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
    GuideImage,
    GuideStep,
    mediaIsImage,
    VideoData,
} from '../../../../shared/data/repair-guides/types'

const HeroImgWrapper = styled('div')`
    position: relative;
`

const HeroImg = styled('img')`
    border-radius: 8px;
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: contain;
    background: ${({ theme }) => theme.palette.grey[100]};
`

const HeroGalleryNavigation = styled('div')`
    position: absolute;
    bottom: 0.5em;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 0 0.5em;
    align-items: center;
`

const HeroGalleryButton = styled(IconButton)`
    color: #fff;
    background: rgba(0, 0, 0, 0.5);

    &:focus {
        background: rgba(0, 0, 0, 0.5);
    }
`

const HeroVid = styled('video')`
    border-radius: 8px;
    width: 100%
    height: auto;
    max-height: 300px;
    object-fit: cover;
`

const HeroVideo = ({ video }: { video: VideoData }) => {
    return (
        <HeroVid controls={true} poster={video.image.url} loop={false} sx={{ mb: 3 }}>
            {video.encodings.map((encoding) => (
                <source
                    src={encoding.url}
                    key={encoding.url}
                    type={`${encoding.mime}; codecs="${encoding.codecs}"`}
                />
            ))}
        </HeroVid>
    )
}

const HeroImage = ({ images }: { images: GuideImage[] }) => {
    const [selectedImg, setSelectedImg] = React.useState(0)
    const multipleImages = images.length > 1
    return (
        <>
            <HeroImgWrapper sx={{ mb: multipleImages ? 0 : 2 }}>
                <HeroImg
                    key={selectedImg}
                    src={images[selectedImg].url}
                    sx={{
                        aspectRatio:
                            (images[selectedImg].width ?? 1) / (images[selectedImg].height ?? 1),
                    }}
                />
                {multipleImages && (
                    <>
                        <HeroGalleryNavigation>
                            <HeroGalleryButton
                                disabled={selectedImg === 0}
                                onClick={() => setSelectedImg((i) => Math.max(0, i - 1))}
                            >
                                <ArrowBackIosIcon
                                    sx={{
                                        transform: 'translateX(17.5%)',
                                    }}
                                />
                            </HeroGalleryButton>
                            <HeroGalleryButton
                                disabled={selectedImg === images.length - 1}
                                onClick={() =>
                                    setSelectedImg((i) => Math.min(images.length - 1, i + 1))
                                }
                            >
                                <ArrowForwardIosIcon
                                    sx={{
                                        transform: 'translateX(7.5%)',
                                    }}
                                />
                            </HeroGalleryButton>
                        </HeroGalleryNavigation>
                    </>
                )}
            </HeroImgWrapper>
            {multipleImages && (
                <Stack direction="row" gap={1} justifyContent="center" mt={1} mb={2}>
                    {images.map((img, i) => (
                        <Box
                            key={img.url}
                            component="a"
                            onClick={() => setSelectedImg(i)}
                            sx={{
                                background: (theme) => theme.palette.primary.main,
                                display: 'block',
                                width: 8,
                                height: 8,
                                borderRadius: '50%',
                                boxShadow: 2,
                                opacity: selectedImg === i ? 1 : 0.3,
                            }}
                        ></Box>
                    ))}
                </Stack>
            )}
        </>
    )
}

const GuideStepPage: React.FC<{
    step: GuideStep
    index: number
}> = ({ step, index }) => {
    return (
        <>
            {mediaIsImage(step.media) ? (
                <HeroImage images={step.media.data} key={step.stepid} />
            ) : (
                <HeroVideo video={step.media.data as VideoData} />
            )}
            {!!step.title && (
                <Typography variant="h5" component="h1" fontSize={16} fontWeight="600" mb={1}>
                    {step.title}
                </Typography>
            )}
            <ul>
                {step.lines.map((line) => (
                    <Typography
                        variant="body1"
                        component="li"
                        mb={2}
                        key={step.stepid + line.textMarkup.slice(0, 10)}
                        data-key={'line.lineid'}
                        ml={2 * line.level}
                        dangerouslySetInnerHTML={{ __html: line.textMarkup }}
                    ></Typography>
                ))}
            </ul>
        </>
    )
}

export default GuideStepPage
