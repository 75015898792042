// @ts-ignore
import KiteIcon from '../../../../../assets/img/product-category-icons/kite.svg?react'
// @ts-ignore
import KiteBarIcon from '../../../../../assets/img/product-category-icons/kite-bar.svg?react'
// @ts-ignore
import WingIcon from '../../../../../assets/img/product-category-icons/wing.svg?react'
// @ts-ignore
import ApparelIcon from '../../../../../assets/img/product-category-icons/apparel.svg?react'
// @ts-ignore
import BackpackIcon from '../../../../../assets/img/product-category-icons/backpack.svg?react'
// @ts-ignore
import BeanieIcon from '../../../../../assets/img/product-category-icons/beanie.svg?react'
// @ts-ignore
import BikiniIcon from '../../../../../assets/img/product-category-icons/bikini.svg?react'
// @ts-ignore
import CampingChairIcon from '../../../../../assets/img/product-category-icons/camping-chair.svg?react'
// @ts-ignore
import FlipflopIcon from '../../../../../assets/img/product-category-icons/flipflop.svg?react'
// @ts-ignore
import GloveIcon from '../../../../../assets/img/product-category-icons/glove.svg?react'
// @ts-ignore
import HikingPoleIcon from '../../../../../assets/img/product-category-icons/hiking-pole.svg?react'
// @ts-ignore
import HikingShoeIcon from '../../../../../assets/img/product-category-icons/hiking-shoe.svg?react'
// @ts-ignore
import JacketIcon from '../../../../../assets/img/product-category-icons/jacket.svg?react'
// @ts-ignore
import MattressIcon from '../../../../../assets/img/product-category-icons/mattress.svg?react'
// @ts-ignore
import PantsIcon from '../../../../../assets/img/product-category-icons/pants.svg?react'
// @ts-ignore
import PulloverIcon from '../../../../../assets/img/product-category-icons/pullover.svg?react'
// @ts-ignore
import SkiingPantsIcon from '../../../../../assets/img/product-category-icons/skiing-pants.svg?react'
// @ts-ignore
import SleepingBagIcon from '../../../../../assets/img/product-category-icons/sleeping-bag.svg?react'
// @ts-ignore
import SneakerIcon from '../../../../../assets/img/product-category-icons/sneaker.svg?react'
// @ts-ignore
import SnowBootIcon from '../../../../../assets/img/product-category-icons/snow-boot.svg?react'
// @ts-ignore
import SportsShoeIcon from '../../../../../assets/img/product-category-icons/sports-shoe.svg?react'
// @ts-ignore
import SupIcon from '../../../../../assets/img/product-category-icons/sup.svg?react'
// @ts-ignore
import TentIcon from '../../../../../assets/img/product-category-icons/tent.svg?react'
// @ts-ignore
import TShirtIcon from '../../../../../assets/img/product-category-icons/t-shirt.svg?react'
// @ts-ignore
import LeggingIcon from '../../../../../assets/img/product-category-icons/legging.svg?react'
// @ts-ignore
import SweaterIcon from '../../../../../assets/img/product-category-icons/sweater.svg?react'
// @ts-ignore
import LongsleeveIcon from '../../../../../assets/img/product-category-icons/long-sleeve.svg?react'
// @ts-ignore
import TanktopIcon from '../../../../../assets/img/product-category-icons/tanktop.svg?react'
// @ts-ignore
import TopIcon from '../../../../../assets/img/product-category-icons/top.svg?react'
// @ts-ignore
import ShortsIcon from '../../../../../assets/img/product-category-icons/shorts.svg?react'
// @ts-ignore
import BikeBagIcon from '../../../../../assets/img/product-category-icons/ortlieb/bikebag.svg?react'
// @ts-ignore
import BikeBagWithMagneticClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/bikebag-with-magnetic-closure.svg?react'
// @ts-ignore
import BikeBagWithBuckleClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/bikebag-with-buckle-closure.svg?react'
// @ts-ignore
import BikeBagWithHookClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/bikebag-with-hook-closure.svg?react'
// @ts-ignore
import BikeBagWithRollClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/bikebag-with-roll-closure.svg?react'
// @ts-ignore
import BikeBagOfficeBagIcon from '../../../../../assets/img/product-category-icons/ortlieb/bikebag-officebag.svg?react'
// @ts-ignore
import BikeBagWithQuicksealIcon from '../../../../../assets/img/product-category-icons/ortlieb/bikebag-with-quickseal.svg?react'
// @ts-ignore
import BikeBagWithLidIcon from '../../../../../assets/img/product-category-icons/ortlieb/bikebag-with-lid-closure.svg?react'
// @ts-ignore
import LuggageRackIcon from '../../../../../assets/img/product-category-icons/ortlieb/luggage-rack.svg?react'
// @ts-ignore
import OrtliebBackpackIcon from '../../../../../assets/img/product-category-icons/ortlieb/backpack.svg?react'
// @ts-ignore
import TravelBagsIcon from '../../../../../assets/img/product-category-icons/ortlieb/travel-bags.svg?react'
// @ts-ignore
import BikeBagFrontBackIcon from '../../../../../assets/img/product-category-icons/ortlieb/bikebag-front-back.svg?react'
// @ts-ignore
import BackpackWithLidClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/backpack-with-lid-closure.svg?react'
// @ts-ignore
import BackpackWithRollClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/backpack-with-roll-closure.svg?react'
// @ts-ignore
import BackpackWithZipClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/backpack-with-zip-closure.svg?react'
// @ts-ignore
import BikerackBagIcon from '../../../../../assets/img/product-category-icons/ortlieb/bikerack-bag.svg?react'
// @ts-ignore
import BikerackWithRollClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/bikerack-bag-with-roll-closure.svg?react'
// @ts-ignore
import BikerackWithLidClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/bikerack-bag-with-lid-closure.svg?react'
// @ts-ignore
import DuffleIcon from '../../../../../assets/img/product-category-icons/ortlieb/duffle.svg?react'
// @ts-ignore
import DuffleWithRollsIcon from '../../../../../assets/img/product-category-icons/ortlieb/duffle-with-rolls.svg?react'
// @ts-ignore
import FrameBagWithRollClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/frame-bag-with-roll-closure.svg?react'
// @ts-ignore
import FrameBagWithLidClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/frame-bag-with-lid-closure.svg?react'
// @ts-ignore
import FrameBagWithZipClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/frame-bag-with-zip-closure.svg?react'
// @ts-ignore
import FrameBagIcon from '../../../../../assets/img/product-category-icons/ortlieb/frame-bag.svg?react'
// @ts-ignore
import HandlebarBagWithLidClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/handlebar-bag-with-lid-closure.svg?react'
// @ts-ignore
import HandlebarBagWithRollClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/handlebar-bag-with-roll-closure.svg?react'
// @ts-ignore
import HandlebarBagIcon from '../../../../../assets/img/product-category-icons/ortlieb/handlebar-bag.svg?react'
// @ts-ignore
import LuggageRackQuickrackIcon from '../../../../../assets/img/product-category-icons/ortlieb/luggagerack-quickrack.svg?react'
// @ts-ignore
import LuggageRackRackIcon from '../../../../../assets/img/product-category-icons/ortlieb/luggagerack-rack.svg?react'
// @ts-ignore
import PacksackIcon from '../../../../../assets/img/product-category-icons/ortlieb/packsack.svg?react'
// @ts-ignore
import SaddleBagWithSeatlockIcon from '../../../../../assets/img/product-category-icons/ortlieb/saddle-bag-with-seatlock.svg?react'
// @ts-ignore
import SaddleBagWithVelcroClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/saddle-bag-with-velcro-closure.svg?react'
// @ts-ignore
import SaddleBagIcon from '../../../../../assets/img/product-category-icons/ortlieb/saddle-bag.svg?react'
// @ts-ignore
import BackpackWithBuckleClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/backpack-with-buckle-closure.svg?react'
// @ts-ignore
import BackpackWithHookClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/backpack-with-hook-closure.svg?react'
// @ts-ignore
import BackpackWithVelcroClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/backpack-with-velcro-closure.svg?react'
// @ts-ignore
import BaristaIcon from '../../../../../assets/img/product-category-icons/ortlieb/barista.svg?react'
// @ts-ignore
import DuffleWithRollClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/duffle-with-roll-closure.svg?react'
// @ts-ignore
import DuffleWithZipClosureIcon from '../../../../../assets/img/product-category-icons/ortlieb/duffle-with-zip-closure.svg?react'
// @ts-ignore
import DuffleWithTelescopePoleIcon from '../../../../../assets/img/product-category-icons/ortlieb/duffle-with-telescope-pole.svg?react'
// @ts-ignore
import DuffleWitouthTelescopePoleIcon from '../../../../../assets/img/product-category-icons/ortlieb/duffle-without-telescope-pole.svg?react'
// @ts-ignore
import EGlowIcon from '../../../../../assets/img/product-category-icons/ortlieb/e-glow.svg?react'
// @ts-ignore
import FrameBagToptubeIcon from '../../../../../assets/img/product-category-icons/ortlieb/frame-bag-toptube.svg?react'
// @ts-ignore
import FrameBagToptubeRCIcon from '../../../../../assets/img/product-category-icons/ortlieb/frame-bag-toptube-rc.svg?react'
// @ts-ignore
import FrameBagFramepackIcon from '../../../../../assets/img/product-category-icons/ortlieb/frame-bag-framepack.svg?react'
// @ts-ignore
import FrameBagFramepackRCIcon from '../../../../../assets/img/product-category-icons/ortlieb/frame-bag-framepack-rc.svg?react'
// @ts-ignore
import HandlebarBagPackIcon from '../../../../../assets/img/product-category-icons/ortlieb/handlebar-bag-pack.svg?react'
// @ts-ignore
import HandlebarBagPackQR from '../../../../../assets/img/product-category-icons/ortlieb/handlebar-bag-qr.svg?react'
// @ts-ignore
import TrunkbagRackpackIcon from '../../../../../assets/img/product-category-icons/ortlieb/trunk-bag-rackpack.svg?react'
// @ts-ignore
import OrtliebOtherIcon from '../../../../../assets/img/product-category-icons/ortlieb/ortlieb-other-products.svg?react'
// @ts-ignore
import TrunkBagRcIcon from '../../../../../assets/img/product-category-icons/ortlieb/trunk-bag-rc.svg?react'
// @ts-ignore
import TrunkBagUptownIcon from '../../../../../assets/img/product-category-icons/ortlieb/trunk-bag-uptown.svg?react'
// @ts-ignore
import TrunkBagIcon from '../../../../../assets/img/product-category-icons/ortlieb/trunk-bag.svg?react'
// @ts-ignore
import UltimateIcon from '../../../../../assets/img/product-category-icons/ortlieb/ultimate.svg?react'
// @ts-ignore
import UptownIcon from '../../../../../assets/img/product-category-icons/ortlieb/uptown.svg?react'
// @ts-ignore
import VarioIcon from '../../../../../assets/img/product-category-icons/ortlieb/vario.svg?react'
// @ts-ignore
import BikeRackIcon from '../../../../../assets/img/product-category-icons/bike-rack.svg?react'
// @ts-ignore
import TravelBagIcon from '../../../../../assets/img/product-category-icons/travel-bag.svg?react'
// @ts-ignore
import FoilIcon from '../../../../../assets/img/product-category-icons/foil.svg?react'
// @ts-ignore
import KiteboardIcon from '../../../../../assets/img/product-category-icons/kite-board.svg?react'
// @ts-ignore
import ShoulderBagIcon from '../../../../../assets/img/product-category-icons/freitag/shoulder-bag.svg?react'
// @ts-ignore
import ToteBagIcon from '../../../../../assets/img/product-category-icons/freitag/tote-bag.svg?react'
// @ts-ignore
import FreitagTravelBagIcon from '../../../../../assets/img/product-category-icons/freitag/travel-bag.svg?react'
// @ts-ignore
import MessengerBagIcon from '../../../../../assets/img/product-category-icons/freitag/messenger-bag.svg?react'
// @ts-ignore
import LaptopBagIcon from '../../../../../assets/img/product-category-icons/freitag/laptop-bag.svg?react'
// @ts-ignore
import FreitagBagsIcon from '../../../../../assets/img/product-category-icons/freitag/bags.svg?react'
// @ts-ignore
import FreitagAccessoriesIcon from '../../../../../assets/img/product-category-icons/freitag/accessories.svg?react'
// @ts-ignore
import VictorinoxWatchIcon from '../../../../../assets/img/product-category-icons/victorinox/watch.svg?react'
// @ts-ignore
import VictorinoxPocketKnifeIcon from '../../../../../assets/img/product-category-icons/victorinox/pocket-knife.svg?react'
// @ts-ignore
import VictorinoxMultiToolIcon from '../../../../../assets/img/product-category-icons/victorinox/multi-tool.svg?react'
// @ts-ignore
import VictorinoxLuggageIcon from '../../../../../assets/img/product-category-icons/victorinox/luggage.svg?react'
// @ts-ignore
import VictorinoxKnifeIcon from '../../../../../assets/img/product-category-icons/victorinox/knife.svg?react'
// @ts-ignore
import VictorinoxCardIcon from '../../../../../assets/img/product-category-icons/victorinox/card.svg?react'

import createUploadcareImageSrc from '../../../../../mixins/create-uploadcare-image-src'

const allIcons: { [index: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
    KiteIcon,
    KiteBarIcon,
    ApparelIcon,
    BackpackIcon,
    BeanieIcon,
    BikiniIcon,
    CampingChairIcon,
    FlipflopIcon,
    GloveIcon,
    HikingPoleIcon,
    HikingShoeIcon,
    JacketIcon,
    MattressIcon,
    PantsIcon,
    PulloverIcon,
    SkiingPantsIcon,
    SleepingBagIcon,
    SneakerIcon,
    SnowBootIcon,
    SportsShoeIcon,
    SupIcon,
    TentIcon,
    TShirtIcon,
    WingIcon,
    LeggingIcon,
    SweaterIcon,
    LongsleeveIcon,
    TanktopIcon,
    TopIcon,
    ShortsIcon,
    BikeBagIcon,
    BikeRackIcon,
    TravelBagIcon,
    KiteboardIcon,
    FoilIcon,
    ShoulderBagIcon,
    ToteBagIcon,
    FreitagTravelBagIcon,
    FreitagBagsIcon,
    MessengerBagIcon,
    LaptopBagIcon,
    FreitagAccessoriesIcon,
    VictorinoxWatchIcon,
    VictorinoxPocketKnifeIcon,
    VictorinoxMultiToolIcon,
    VictorinoxLuggageIcon,
    VictorinoxKnifeIcon,
    VictorinoxCardIcon,
    BikeBagWithMagneticClosureIcon,
    BikeBagWithBuckleClosureIcon,
    BikeBagWithHookClosureIcon,
    BikeBagWithRollClosureIcon,
    BikeBagOfficeBagIcon,
    BikeBagWithQuicksealIcon,
    BikeBagWithLidIcon,
    LuggageRackIcon,
    OrtliebBackpackIcon,
    TravelBagsIcon,
    BikeBagFrontBackIcon,
    BikerackBagIcon,
    BikerackWithRollClosureIcon,
    BikerackWithLidClosureIcon,
    DuffleIcon,
    DuffleWithRollsIcon,
    FrameBagWithRollClosureIcon,
    FrameBagWithLidClosureIcon,
    FrameBagWithZipClosureIcon,
    FrameBagIcon,
    HandlebarBagWithRollClosureIcon,
    HandlebarBagWithLidClosureIcon,
    HandlebarBagIcon,
    LuggageRackQuickrackIcon,
    LuggageRackRackIcon,
    PacksackIcon,
    SaddleBagWithSeatlockIcon,
    SaddleBagWithVelcroClosureIcon,
    SaddleBagIcon,
    BackpackWithLidClosureIcon,
    BackpackWithRollClosureIcon,
    BackpackWithZipClosureIcon,
    BackpackWithBuckleClosureIcon,
    BackpackWithHookClosureIcon,
    BackpackWithVelcroClosureIcon,
    BaristaIcon,
    DuffleWithRollClosureIcon,
    DuffleWithZipClosureIcon,
    DuffleWithTelescopePoleIcon,
    DuffleWitouthTelescopePoleIcon,
    EGlowIcon,
    FrameBagToptubeIcon,
    FrameBagToptubeRCIcon,
    FrameBagFramepackIcon,
    FrameBagFramepackRCIcon,
    HandlebarBagPackIcon,
    HandlebarBagPackQR,
    TrunkbagRackpackIcon,
    OrtliebOtherIcon,
    TrunkBagRcIcon,
    TrunkBagUptownIcon,
    TrunkBagIcon,
    UltimateIcon,
    UptownIcon,
    VarioIcon,
}

export default (icon: string) => {
    const Fallback = (props: any) => (
        <img
            {...props}
            src={icon.length > 0 ? createUploadcareImageSrc(icon, { preview: '200x200' }) : ''}
        />
    )
    const Icon = allIcons[icon] ?? Fallback
    return Icon
}
