import React from 'react'
import AlterationsMultiStep from './alterations-multi-step'
import uploadcareClient from '../../../mixins/uploadcare-client'
import { ThemeProvider, createTheme } from '@mui/material'
import mergeObjects from '../../../mixins/merge-object'
import { useAnalytics } from 'use-analytics'
import { submitAlterationRequest } from 'wasp/client/operations'
import { AlterationsRequestPageViewEvent } from '../../../../shared/analytics'
import { useIsMobile } from '../../../mixins/media-query'
import parseQueryString from '../../../mixins/parse-query-string'
import { FormValues, WhitelabelWithAlterationsConfig } from './context'
import { useRollbar } from '@rollbar/react'
import { OrderType } from '../../../../shared/types/service-desk/order-type'
import WhitelabelLoader from '../shared/whitelabel-loader'

const AlterationsModule = ({
    whitelabel,
    orderType,
}: {
    whitelabel: WhitelabelWithAlterationsConfig
    orderType: OrderType
}) => {
    const rollbar = useRollbar()
    const utmData = parseQueryString()
    const analytics = useAnalytics()
    const isMobile = useIsMobile()

    React.useEffect(() => {
        analytics.track(AlterationsRequestPageViewEvent, {
            isMobile,
            brandId: whitelabel.id,
            utmData,
        })
    }, [])

    const handleSubmit = async (formValues: FormValues) => {
        try {
            await submitAlterationRequest({
                companyId: whitelabel.id,
                product: formValues.selectedProduct,
                contactForm: formValues.contactFormValues,
                customInformationForm: formValues.alterationInformationForm,
                utmData: utmData,
                anonymousUserId: analytics.user().anonymousId,
                userSelectedAlterationServices: formValues.selectedAlterations,
                orderType,
                retailerCode: formValues.retailerCode,
                retailerForm: formValues.retailerForm,
                requestIndividualSupport: formValues.requestIndividualSupport,
                individualSupportForm: formValues.individualSupportForm,
            })
        } catch (error) {
            rollbar.error('Error submitting alterations request', {
                error,
                formValues,
                companyId: whitelabel.id,
                utmData: utmData,
                anonymousUserId: analytics.user().anonymousId,
            })
        }
    }

    return (
        <ThemeProvider
            theme={(theme: any) =>
                createTheme({
                    ...theme,
                    palette: {
                        ...theme.palette,
                        ...whitelabel?.theme.palette,
                    },
                    components: mergeObjects(theme.components, whitelabel?.theme.components),
                } as any)
            }
        >
            <AlterationsMultiStep
                uploadCareClient={uploadcareClient}
                whitelabel={whitelabel as WhitelabelWithAlterationsConfig}
                onSubmit={handleSubmit}
                orderType={orderType}
            />
        </ThemeProvider>
    )
}

export const AlterationsPageB2B = () => (
    <WhitelabelLoader>
        {({ whitelabel }) => (
            <AlterationsModule
                whitelabel={whitelabel as WhitelabelWithAlterationsConfig}
                orderType={OrderType.B2B}
            />
        )}
    </WhitelabelLoader>
)

export const AlterationsPageB2C = () => (
    <WhitelabelLoader>
        {({ whitelabel }) => (
            <AlterationsModule
                whitelabel={whitelabel as WhitelabelWithAlterationsConfig}
                orderType={OrderType.B2C}
            />
        )}
    </WhitelabelLoader>
)
