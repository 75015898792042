import React from 'react'
import { useTranslation } from 'react-i18next'
import { RepairMultiStepFormContext } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import RepairService from '../../../../../shared/data/repair-services/types'
import {
    styled,
    Stack,
    Switch,
    Typography,
    alpha,
    Box,
    DialogTitle,
    Dialog,
    DialogContent,
} from '@mui/material'
import T from '../../../../components/typography/t'
import InspectorStepTitle from '../../../../components/typography/inspector-step-title'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LabelledDelimiter from '../../shared/components/labelled-delimiter'
import HelpIcon from '@mui/icons-material/Help'
import useDialog from '../../../../mixins/use-dialog'

const TotalCosts = styled(Typography, {
    shouldForwardProp: (prop) => prop !== '$blink',
})<{ $blink?: boolean; component: string }>(
    ({ theme, $blink }) => `
    color: ${$blink ? theme.palette.primary.main : theme.palette.text.primary};
    text-shadow: ${$blink ? `1px 1px 1px ${alpha(theme.palette.primary.main, 0.5)}` : 'none'};
    transition: color 0.2s ease-in-out, text-shadow 0.2s ease-in-out;
`
)

const Price: React.FC<{ price: number[] }> = ({ price }) => {
    const isRange = price.length > 1 && price[0] !== price[1]
    return (
        <>
            {isRange ? `${price[0].toFixed(2)} - ${price[1].toFixed(2)}` : price[0].toFixed(2)}
            &nbsp;€
        </>
    )
}

const CostApprovalStep: React.FC<{
    selectedServices: RepairService[]
    minCost: number
    approvedCosts: number
    shippingCosts: number
    setApprovedCosts: (costs: number) => void
}> = ({ selectedServices, minCost, approvedCosts, shippingCosts, setApprovedCosts }) => {
    const helpDialog = useDialog()
    const includedServices = selectedServices.filter((service) =>
        service.price.every((price) => price <= minCost)
    )
    const excludedServices = selectedServices.filter(
        (service) => !includedServices.includes(service)
    )
    const getApprovedServices = () => {
        return excludedServices.filter((service) => Math.max(...service.price) <= approvedCosts)
    }
    const { t, i18n } = useTranslation()
    const [blink, setBlink] = React.useState(false)
    const [approvedServices, setApprovedServices] = React.useState<RepairService[]>(
        getApprovedServices()
    )

    const language = i18n.language.slice(0, 2)
    const getRepairServiceTitle = (repairService: RepairService) => {
        if (repairService.translations && repairService.translations[language]) {
            return repairService.translations[language]
        }
        return repairService.title
    }

    const handleServiceApprovalToggle = (service: RepairService) => {
        setApprovedServices((prev) => {
            const newServices = prev.includes(service)
                ? excludedServices.filter((s) => Math.max(...s.price) < Math.max(...service.price))
                : excludedServices.filter((s) => Math.max(...s.price) <= Math.max(...service.price))
            setApprovedCosts(Math.max(...newServices.flatMap((s) => s.price), minCost))
            startBlink()
            return newServices
        })
    }

    const startBlink = () => {
        setBlink(true)
        setTimeout(() => setBlink(false), 500)
    }

    return (
        <Stack gap={4}>
            {' '}
            {/* 6 on dekstop, 4 on mobile */}
            <InspectorStepTitle i18nKey="serviceDesk.repair.costApprovalStep.title" />
            <Stack gap={1}>
                <T
                    i18nKey="serviceDesk.repair.costApprovalStep.minCost.title"
                    component="h2"
                    variant="h6"
                    sx={{ lineHeight: 1 }}
                />
                <T
                    i18nKey="serviceDesk.repair.costApprovalStep.minCost.lead"
                    values={{ minCost }}
                    components={{
                        strong: <strong />,
                    }}
                    mb={includedServices.length > 0 ? 2 : 0}
                />
                {includedServices.length > 0 && (
                    <Accordion
                        sx={{
                            border: (theme) => `1px solid ${theme.palette.primary.main}`,
                            boxShadow: 0,
                        }}
                        defaultExpanded={excludedServices.length === 0}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                                minHeight: '48px',
                                '&.Mui-expanded': { minHeight: '48px' },
                                '.MuiAccordionSummary-content.Mui-expanded': { my: 1 },
                            }}
                        >
                            <T
                                i18nKey="serviceDesk.repair.costApprovalStep.includedServices.title"
                                fontWeight={500}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack gap={1} pl={1}>
                                {includedServices
                                    .sort((a, b) => a.price[0] - b.price[0])
                                    .map((service) => (
                                        <Stack
                                            key={service.id}
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            gap={1}
                                        >
                                            <Typography>
                                                {getRepairServiceTitle(service)}
                                            </Typography>
                                            <Typography>
                                                <Price price={service.price} />
                                            </Typography>
                                        </Stack>
                                    ))}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                )}
            </Stack>
            <LabelledDelimiter $primary />
            {excludedServices.length > 0 && (
                <>
                    <Stack gap={1}>
                        <T
                            i18nKey="serviceDesk.repair.costApprovalStep.excludedServices.title"
                            component="h2"
                            variant="h6"
                            sx={{ lineHeight: 1 }}
                        />
                        <T
                            i18nKey="serviceDesk.repair.costApprovalStep.excludedServices.lead"
                            values={{ minCost }}
                        />
                        <Stack pl={1}>
                            {excludedServices
                                .sort((a, b) => a.price[0] - b.price[0])
                                .map((service) => (
                                    <Stack
                                        key={service.id}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography>{getRepairServiceTitle(service)}</Typography>
                                        <Stack direction="row" gap={1} alignItems="center">
                                            <Typography>
                                                <Price price={service.price} />
                                            </Typography>
                                            <Switch
                                                checked={approvedServices.includes(service)}
                                                onChange={() =>
                                                    handleServiceApprovalToggle(service)
                                                }
                                            />
                                        </Stack>
                                    </Stack>
                                ))}
                        </Stack>
                    </Stack>
                    <LabelledDelimiter $primary />
                </>
            )}
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                <Box position="relative">
                    <T
                        component="h2"
                        variant="h6"
                        i18nKey="serviceDesk.repair.costApprovalStep.total"
                        sx={{ lineHeight: 1 }}
                    />
                    <T
                        component="span"
                        variant="body2"
                        color="text.secondary"
                        i18nKey="serviceDesk.repair.costApprovalStep.plusShipping"
                        values={{ shippingCosts }}
                        sx={{ lineHeight: 1 }}
                    />
                    <HelpIcon
                        onClick={() => helpDialog.open()}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            transform: 'translateX(120%)',
                            fontSize: '1em',
                        }}
                    />
                </Box>
                <TotalCosts component="p" variant="h6" $blink={blink} sx={{ lineHeight: 1 }}>
                    <Price price={[approvedCosts]} />
                </TotalCosts>
            </Stack>
            {helpDialog.isOpen && (
                <Dialog open={helpDialog.isOpen} onClose={() => helpDialog.close()} maxWidth="md">
                    <DialogTitle>
                        <T i18nKey="serviceDesk.repair.costApprovalStep.help.title" />
                    </DialogTitle>
                    <DialogContent>
                        <T i18nKey="serviceDesk.repair.costApprovalStep.help.content" />
                    </DialogContent>
                </Dialog>
            )}
        </Stack>
    )
}

const CostApprovalStepContext = () => {
    const { form, ctx, props } = React.useContext(RepairMultiStepFormContext)

    const selectedRepairServices = ctx.whitelabel.repairServices.filter((s) =>
        form.formValues.aiSelectedServices.includes(s.id)
    )

    const minRepairCost = Math.min(...selectedRepairServices.flatMap((service) => service.price))
    const minCost = Math.max(
        ctx.whitelabel.config.repair.solutions.submitRepairOrder.minCost,
        minRepairCost
    )

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: false,
            }}
        >
            <CostApprovalStep
                selectedServices={selectedRepairServices}
                minCost={minCost}
                approvedCosts={form.formValues.costApproval}
                setApprovedCosts={(costs) => form.setValue('costApproval', costs)}
                shippingCosts={
                    ctx.whitelabel.config.repair.solutions.submitRepairOrder.shippingCost
                }
            />
        </StepFrameworkWrapper>
    )
}

export default CostApprovalStepContext
