import { RepairGuide } from '../repair-guides/types'
import type RepairService from '../repair-services/types'
import { ProductCategoryNode } from '../products/types'
import { type ThemeOptions, type SxProps, type OutlinedTextFieldProps } from '@mui/material'
import { RepairPlugins } from '../../types/repair/plugins'
import { WarrantyPlugins } from '../../types/warranty/plugins'
import { AlterationPlugins } from '../../types/alteration/plugins'
import AlterationService from '../alteration-services/types'
import { I18nKey } from '../../../client/components/typography/t'
import { RegisterOptions } from 'react-hook-form'

export type ItemReferenceIdentification = {
    apiUrl: string
}

export type ServiceDeskContactForm = {
    fields: Field[][]
}

export enum FieldType {
    TextField,
    Autocomplete,
    Dropdown,
}

export type HelperPopup = {
    title: string
    text?: string
    imgUrl?: string
}

type FieldProps = Partial<
    {
        fieldControlSx: SxProps
    } & Omit<OutlinedTextFieldProps, 'variant'>
>

export type FieldParams = Partial<
    {
        disabled: boolean
        helperPopup: HelperPopup
    } & RegisterOptions<any, any>
>

type BaseField = {
    key: string
    label: string
    params?: FieldParams
    fieldProps?: FieldProps
    excludeFromSummary?: boolean
}

type DropdownOption =
    | {
          value: string
          [key: string]: any
      }
    | string

export type F =
    | (BaseField & {
          type: FieldType.TextField
      })
    | (BaseField & {
          type: FieldType.Autocomplete
          options: Array<DropdownOption>
      })
    | (BaseField & {
          type: FieldType.Dropdown
          options: Array<DropdownOption>
      })

export type Field = F | ((formValues: Record<string, any>) => F)
export enum WhitelabelStatus {
    InternalOnly = 'internal-only',
    ExternalDemoAccess = 'external-demo-access',
    Live = 'live',
}

export enum CustomInformationStepType {
    Text = 'Text',
    Photo = 'Photo',
}

export type CustomInformationTextStep = {
    fieldKey: string
    title: I18nKey
    type: CustomInformationStepType.Text
    fields: Field[][]
}

export type CustomInformationPhotoStep = {
    fieldKey: string
    type: CustomInformationStepType.Photo
    photo: {
        key: string
        label: string
        options?: Partial<{
            required: boolean
            helperPopup: HelperPopup
        }>
    }
    required: boolean
    alternativeFields?: Field[][]
    translations: {
        title: string
        photoTitle: string
        photoSubtitle: string
        textTitle: string
        textSubtitle: string
        notAvailableQuestion?: string
        notAvailableAction?: string
    }
}

export type SubmissionCheckbox = {
    name: string
    label: I18nKey
}

export type SubmissionDialog = {
    key: string
    title: I18nKey
    content: I18nKey
}

export type OutroConfig = {
    lead: string
    links: {
        href: string
        label: string
    }[]
}

export type RepairConfig = {
    contactForm: ServiceDeskContactForm
    repairInformationSteps?: Array<CustomInformationTextStep | CustomInformationPhotoStep>
    displayRepairOptions?: boolean
    outro: OutroConfig
    backendPlugins: RepairPlugins[]
    solutions: {
        diy: {
            activate: boolean
        }
        storeFinder:
            | {
                  activate: false
                  iframeUrl?: (args: { language: string }) => string
              }
            | {
                  activate: true
                  iframeUrl: (args: { language: string }) => string
              }
        requestRepairOffer: {
            activate: boolean
            activateAsFallback: boolean
        }
        submitRepairOrder: {
            activate: boolean
            minCost: number
            shippingCost: number
            submissionInfoTexts: I18nKey[]
            submissionCheckboxes: SubmissionCheckbox[]
            submissionDialogs: SubmissionDialog[]
        }
    }
}

export type WarrantyConfig = {
    contactForm: ServiceDeskContactForm
    warrantyInformationSteps?: Array<CustomInformationTextStep | CustomInformationPhotoStep>
    activateWarrantyEstimation: boolean
    outro: OutroConfig
    backendPlugins: WarrantyPlugins[]
    submitWarrantyOrder: {
        submissionInfoTexts: I18nKey[]
        submissionCheckboxes: SubmissionCheckbox[]
        submissionDialogs: SubmissionDialog[]
    }
}

export type AlterationConfig = {
    contactForm: ServiceDeskContactForm
    alterationInformationSteps?: Array<CustomInformationTextStep | CustomInformationPhotoStep>
    alterationProductTree?: ProductCategoryNode
    shippingCost: number
    outro: {
        lead: string
        links: {
            href: string
            label: string
        }[]
    }
    backendPlugins: AlterationPlugins[]
    submitAlterationOrder: {
        submissionInfoTexts: I18nKey[]
        submissionCheckboxes: SubmissionCheckbox[]
        submissionDialogs: SubmissionDialog[]
    }
}

export type UFixitConfig = {
    outro: {
        assistanceIframeUrl?: string
    }
}

export type B2BConfig = {
    fields: Field[][]
}

export type ServiceDeskConfiguration = {
    repair?: RepairConfig
    warranty?: WarrantyConfig
    alterations?: AlterationConfig
    ufixit?: UFixitConfig
    b2b?: B2BConfig
}

export type Whitelabel = FrontendWhitelabel &
    Omit<BackendWhitelabel, 'theme'> & {
        theme: Partial<ThemeOptions>
    }

export type BackendWhitelabel = {
    id: string
    name: string
    logo: string
    b2bActive: boolean
    status: WhitelabelStatus
    theme: {
        primary: { main: string; contrastText: string }
        secondary: { main: string; contrastText: string }
        crosshairColors: { stroke: string; fill: string }
        illustrationColors: { primary: string; accent: string }
        progressbarColor: string
        textColor: string
    }
    repairGuides: RepairGuide[]
    repairServices: RepairService[]
    alterationServices: AlterationService[]
}

export type FrontendWhitelabel = {
    id: string
    productCategoryTree: ProductCategoryNode
    config: ServiceDeskConfiguration
}
