import React from 'react'
import { validateRetailerCode } from 'wasp/client/operations'
import { RepairMultiStepFormContext } from '../../context'
import StepFrameworkWrapper from '../../../shared/components/step-framework-wrapper'
import { useForm } from 'react-hook-form'
import InputField from '../../../../../components/form/input-field'
import T from '../../../../../components/typography/t'
import { useTranslation } from 'react-i18next'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import { Stack } from '@mui/material'
import Field from '../../../shared/components/field'

const RetailerValidationStepContext = () => {
    const { t } = useTranslation()
    const { form, ctx, props } = React.useContext(RepairMultiStepFormContext)
    const [isLoading, setIsloading] = React.useState(false)
    const retailCodeForm = useForm({
        defaultValues: {
            retailerCode: '',
        },
    })

    return (
        <StepFrameworkWrapper
            isLoading={isLoading}
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: !retailCodeForm.watch('retailerCode'),
                onValidNextClick: async () => {
                    setIsloading(true)
                    const { retailerCode, ...formValues } = retailCodeForm.getValues()
                    try {
                        const result = await validateRetailerCode({
                            retailerCode,
                            companyId: ctx.whitelabel.id,
                        })
                        if (result) {
                            form.setValue('contactFormValues', result)
                            form.setValue('retailerCode', retailerCode)
                            form.setValue('retailerForm', formValues)
                            props.nextSlide()
                        } else {
                            retailCodeForm.setError('retailerCode', {
                                message: 'serviceDesk.shared.forms.validation.retailerCode',
                            })
                        }
                    } catch (error) {
                        console.error(error)
                    } finally {
                        setIsloading(false)
                    }
                },
            }}
        >
            <InspectorStepTitle i18nKey="serviceDesk.shared.retailerValidationStep.title" mb={4} />
            <Stack width="100%">
                <InputField
                    fullWidth
                    {...retailCodeForm.register('retailerCode')}
                    label={t('serviceDesk.shared.forms.retailerCode' as any)}
                    error={!!retailCodeForm.formState.errors.retailerCode}
                    helperText={
                        <T
                            i18nKey={retailCodeForm.formState.errors.retailerCode?.message as any}
                            sx={{ color: 'error.main' }}
                            component="span"
                        />
                    }
                />
                {ctx.whitelabel.config.b2b!.fields.map((cells, index) => (
                    <Stack
                        data-testid={`form-row-${index}`}
                        direction="row"
                        mb={index === ctx.whitelabel.config.b2b!.fields.length - 1 ? 0 : 1}
                        gap={2}
                        sx={{ width: '100%' }}
                        key={index}
                    >
                        {cells.map((cell, index) => (
                            <Field key={index} field={cell} useFormReturn={retailCodeForm} />
                        ))}
                    </Stack>
                ))}
            </Stack>
        </StepFrameworkWrapper>
    )
}

export default RetailerValidationStepContext
