import React from 'react'
import { RepairMultiStepFormContext } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import DIYStep from '../../shared/steps/diy-guides-step'

const DiyStepContext = () => {
    const { form, ctx, props } = React.useContext(RepairMultiStepFormContext)

    const repairGuides = ctx.repairGuides.filter((repairGuide) =>
        form.formValues.aiSelectedRepairGuides.includes(repairGuide.id)
    )
    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                hideNextButton: true,
            }}
        >
            <DIYStep
                repairGuides={repairGuides}
                company={`${ctx.whitelabel?.name}-${ctx.whitelabel?.id}`}
            />
        </StepFrameworkWrapper>
    )
}

export default DiyStepContext
