import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlterationsMultiStepFormContext, FormValues } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import InspectorStepTitle from '../../../../components/typography/inspector-step-title'
import {
    Stack,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    List,
    ListItem,
    Typography,
} from '@mui/material'
import T, { I18nKey } from '../../../../components/typography/t'
import ContactCard from '../../shared/components/contact-card'
import createFormField from '../../shared/helper/create-form-field'
import AlterationService from '../../../../../shared/data/alteration-services/types'
import ContentCard from '../../shared/components/content-card'
import LabelledDelimiter from '../../shared/components/labelled-delimiter'
import { useForm } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import { SubmissionCheckbox, SubmissionDialog } from '../../../../../shared/data/whitelabel/types'

const RequestAlterationsStep: React.FC<{
    contactForm: string[][]
    selectedAlterations: AlterationService[]
    shippingCosts: number
    submissionCheckboxes: SubmissionCheckbox[]
    submissionDialogs: SubmissionDialog[]
    formValues: FormValues
    submissionInfoTexts: I18nKey[]
    onSubmit: () => void
}> = ({
    contactForm,
    selectedAlterations,
    shippingCosts,
    onSubmit,
    submissionCheckboxes,
    submissionDialogs,
    formValues,
    submissionInfoTexts,
}) => {
    const [t] = useTranslation()

    const [dialogKey, setDialogKey] = React.useState<string | null>(null)
    const form = useForm({
        defaultValues: {
            ...submissionCheckboxes.reduce((acc, checkbox) => {
                acc[checkbox.name] = false
                return acc
            }, {} as any),
        },
    })

    const allChecked = submissionCheckboxes.every((checkbox) => form.watch(checkbox.name))

    const dialog = submissionDialogs.find((dialog) => dialog.key === dialogKey)

    const tComponents = {
        externalLink: <Link target="_blank" />,
        list: <List sx={{ listStyleType: 'disc', mx: 0 }} />,
        item: <ListItem sx={{ display: 'list-item', px: 0, mx: 1 }} />,
        dialogLink: (
            <Link
                onClick={(e: any) => {
                    setDialogKey(e.target.dataset.key)
                }}
            />
        ),
    }
    const totalCosts =
        selectedAlterations.reduce((acc, alteration) => acc + Math.max(...alteration.price), 0) +
        shippingCosts
    return (
        <Stack gap={6} width="100%">
            <InspectorStepTitle i18nKey="serviceDesk.warranty.requestWarrantyStep.title" />
            <Stack
                direction={{ md: 'row', sm: 'column' }}
                gap={2}
                justifyContent="center"
                alignSelf="center"
                width="100%"
            >
                <ContentCard sx={{ flexGrow: 1 }}>
                    <T
                        sx={{ color: 'primary.main' }}
                        component="h2"
                        variant="h6"
                        i18nKey="serviceDesk.alteration.submitAlterationOrderStep.alterationCosts.title"
                    />
                    <Stack gap={1} fontWeight={500} width="100%">
                        {selectedAlterations.map((alteration) => (
                            <Stack
                                key={alteration.id}
                                direction="row"
                                justifyContent="space-between"
                                gap={2}
                            >
                                <Typography>{alteration.title}</Typography>
                                <Typography>{alteration.price}&nbsp;€</Typography>
                            </Stack>
                        ))}
                        <Stack direction="row" justifyContent="space-between" gap={2}>
                            <Box>
                                <T
                                    i18nKey="serviceDesk.repair.submitRepairOrderStep.repairCosts.shippingCosts"
                                    component="p"
                                ></T>
                                <T
                                    i18nKey="serviceDesk.repair.submitRepairOrderStep.repairCosts.shippingCostsSubline"
                                    component="p"
                                    sx={{ fontSize: '0.7em', color: 'grey' }}
                                ></T>
                            </Box>
                            <Typography>{shippingCosts}&nbsp;€</Typography>
                        </Stack>
                        <LabelledDelimiter $primary />
                        <Stack direction="row" justifyContent="space-between" gap={1}>
                            <T
                                i18nKey="serviceDesk.alteration.submitAlterationOrderStep.alterationCosts.totalCosts"
                                component="p"
                            ></T>
                            <Typography>{totalCosts}&nbsp;€</Typography>
                        </Stack>
                    </Stack>
                </ContentCard>
                <ContactCard contactForm={contactForm} />
            </Stack>
            <Stack gap={2}>
                {submissionInfoTexts.map((text) => (
                    <T
                        component="span"
                        i18nKey={text as any}
                        values={{ form: formValues }}
                        key={text as string}
                        components={tComponents}
                    />
                ))}
                {submissionCheckboxes.map((checkbox) => (
                    <Stack key={checkbox.name} direction="row" gap={1} alignItems="center">
                        <Checkbox
                            checked={form.watch(checkbox.name)}
                            onClick={() => form.setValue(checkbox.name, !form.watch(checkbox.name))}
                        />
                        <span>
                            <T
                                i18nKey={checkbox.label as any}
                                values={{ form: formValues }}
                                components={tComponents}
                            />
                        </span>
                    </Stack>
                ))}
            </Stack>
            <Button variant="contained" fullWidth onClick={onSubmit} disabled={!allChecked}>
                <T i18nKey="serviceDesk.alteration.submitAlterationOrderStep.submitAction" />
            </Button>
            {dialog && (
                <Dialog open={true} onClose={() => setDialogKey(null)}>
                    <DialogTitle>
                        <T i18nKey={dialog.title as any} />
                        <IconButton
                            onClick={() => setDialogKey(null)}
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <T
                            i18nKey={dialog.content as any}
                            values={{ form: formValues }}
                            components={tComponents}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </Stack>
    )
}

const RequestAlterationStepContext = () => {
    const { form, ctx, props } = React.useContext(AlterationsMultiStepFormContext)
    const [isLoading, setIsloading] = React.useState(false)

    const selectedAlterations = ctx.whitelabel.alterationServices.filter((service) =>
        form.formValues.selectedAlterations.includes(service.id)
    )

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                hideNextButton: true,
            }}
            isLoading={isLoading}
        >
            <RequestAlterationsStep
                contactForm={ctx.whitelabel.config.alterations.contactForm.fields
                    .map((line) =>
                        line
                            .map(createFormField(form.formValues))
                            .filter((cell) => !cell.excludeFromSummary)
                            .map((cell) => {
                                const value = form.formValues.contactFormValues[cell.key]
                                return typeof value === 'object'
                                    ? value.print ?? value.value
                                    : value
                            })
                    )
                    .filter((line) => line.length > 0)}
                selectedAlterations={selectedAlterations}
                shippingCosts={ctx.whitelabel.config.alterations.shippingCost}
                onSubmit={async () => {
                    try {
                        setIsloading(true)
                        await form.submitForm()
                        setIsloading(false)
                        props.nextSlide()
                    } catch (error) {
                        console.error(error)
                    } finally {
                        setIsloading(false)
                    }
                }}
                submissionCheckboxes={
                    ctx.whitelabel.config.alterations.submitAlterationOrder.submissionCheckboxes
                }
                submissionDialogs={
                    ctx.whitelabel.config.alterations.submitAlterationOrder.submissionDialogs
                }
                formValues={form.formValues}
                submissionInfoTexts={
                    ctx.whitelabel.config.alterations.submitAlterationOrder.submissionInfoTexts
                }
            />
        </StepFrameworkWrapper>
    )
}

export default RequestAlterationStepContext
