const de = {
    common: {
        next: 'Weiter',
        back: 'Zurück',
        cancel: 'Abbrechen',
        submit: 'Absenden',
        go: 'Los',
        or: 'Oder',
        logout: 'Abmelden',
        loading: 'Laden...',
        error: 'Etwas ist schiefgelaufen',
        copied: 'Kopiert!',
        language: 'Sprache',
        requestTypes: {
            all: 'Alle',
            warranty: 'Garantie',
            repair: 'Reparatur',
        },
    },
    admin: {
        tatajuba: {
            title: 'Service Desk',
        },
        newCompanyRepresentative: {
            title: 'Neue*r Kund*in einladen',
            companyNameLabel: 'Firmenname',
            companyRepresentativeNameLabel: 'Mitarbeiter*in Name',
            companyRepresentativeEmailLabel: 'Mitarbeiter*in E-Mail',
            buttonLabel: 'Einladen',
        },
        unverifiedUsers: {
            title: 'Unverifizierte Benutzer*innen',
            table: {
                company: 'Firma',
                representative: 'Repräsentant',
                email: 'E-Mail',
                link: 'Einladungslink',
            },
        },
        companies: {
            title: 'Firmen',
            table: {
                id: 'Id',
                company: 'Firma',
                representative: 'Repräsentant',
                representativeEmail: 'E-Mail',
                apps: 'Apps',
                productCount: 'Anzahl Produkte',
            },
        },
    },
    company: {
        onboarding: {
            form: {
                welcome: 'Willkommen!',
                lead: 'Wir sind froh, euch an Bord zu haben.',
                subLead: 'Bitte aktiviere deinen Account:',
                email: {
                    label: 'E-Mail',
                    placeholder: 'E-Mail',
                    helperText:
                        'Bitte verwende die E-Mail, auf die du diesen Einladungslink erhalten hast.',
                },
                password: {
                    label: 'Passwort',
                    placeholder: 'Setze ein neues, sicheres Passwort',
                },
                requirements: {
                    title: 'Passwortanforderungen:',
                    length: 'Mindestens 8 Zeichen',
                    casing: 'Klein- und Grossbuchstaben',
                    number: 'Mindestens eine Zahl',
                    special: 'Mindestens ein Sonderzeichen',
                },
                action: 'Account erstellen',
            },
            introduction: {
                title: 'Willkommen auf der Inspector Platform. Ihre Reise zu einer verbesserten Produktqualität und bereicherten Nutzererlebnissen beginnt hier. Wir freuen uns, Sie an Bord zu haben, und freuen uns darauf, Ihren Erfolg zu unterstützen.',
                action: 'Los geht’s',
            },
        },
        common: {
            filterbar: {
                search: {
                    label: 'Suche',
                    placeholder:
                        "Suche nach E-Mail, Beschreibung oder Datum (Bsp. '11. November' oder 'Januar')",
                },
                unseen: {
                    showOnlyUnseen: 'Zeige nur neue Inspections',
                    showAll: 'Zeige alle Inspections',
                },
                share: 'Teilen',
            },
        },
        inspector: {
            home: {
                products: {
                    title: 'Produkte',
                    action: 'Neues Produkt anlegen',
                    productList: {
                        openFeatureTest: 'Feature Test',
                        openRainTest: 'Rain Test',
                        copyInspectorLink: {
                            copyDesktop: 'Inspector-Link kopieren',
                            copyMobile: 'Inspector-Link',
                            copied: 'Inspector-Link kopiert',
                        },
                        deleteProduct: {
                            headline: 'Bist du sicher?',
                            confirmText:
                                'Willst du das Produkt <strong>"{{productName}}"</strong> wirklich <strong>unwiderruflich</strong> löschen? Alle Daten werden gelöscht und können nicht wiederhergestellt werden.',
                            cancel: 'Abbrechen',
                            delete: 'Löschen',
                        },
                    },
                    newProduct: {
                        title: 'Neues Produkt anlegen',
                        form: {
                            name: {
                                label: 'Produktname',
                                required: 'Produktname ist erforderlich',
                            },
                            cancelButton: {
                                label: 'Abbrechen',
                            },
                            saveButton: {
                                label: 'Produkt anlegen',
                                saving: 'Speichern...',
                                validation: {
                                    nameRequired: 'Produktname ist erforderlich',
                                    imageRequired: 'Produktbild ist erforderlich',
                                },
                            },
                            photoStep: {
                                title: 'Produktbild hochladen',
                                subtitle:
                                    'Nimm ein Foto mit deiner Kamera auf oder lade ein Foto aus deiner Galerie hoch.',
                                takePhotoButton: 'Foto aufnehmen',
                                uploadPhotoButton: 'Foto hochladen',
                            },
                            infoStep: {
                                title: 'Produktinformationen',
                                subtitle: 'Gib deinem Produkt einen Namen.',
                                name: {
                                    label: 'Produktname*',
                                    placeholder: 'Produktname',
                                },
                                brand: {
                                    label: 'Marke',
                                    placeholder: 'Marke',
                                },
                                gender: {
                                    label: 'Geschlecht',
                                    men: 'Männer',
                                    women: 'Frauen',
                                    diverse: 'Divers',
                                },
                                age: {
                                    label: 'Alter',
                                    placeholder: 'Alter',
                                },
                                laminate: {
                                    label: 'Laminat',
                                    two: '2 Layer',
                                    three: '3 Layer',
                                    twoLiner: '2 + Liner',
                                    otter: 'Otter',
                                },
                            },
                            summaryStep: {
                                title: 'Zusammenfassung',
                                subtitle:
                                    'Bitte überprüfe die Angaben und bestätige die Erstellung des Produktes.',
                                loading:
                                    'Wir optimieren gerade dein Produktbild, warte bitte einen Moment...',
                                unhappy: 'Nicht zufrieden?',
                                useOriginalBtn: 'Originalbild stattdessen verwenden',
                                useOptimizedBtn: 'Optimiertes Bild verwenden',
                                nextBtn: 'Anlegen',
                            },
                        },
                    },
                },
                share: {
                    exportProductList: 'Produktliste exportieren',
                    printProductCards: 'Produktkarten drucken',
                    orderCompanyCard: 'Firmenkarte bestellen',
                    copyCompanyLink: 'Firmenübersichtslink kopieren',
                    copyCompanySupportLink: 'Firmen Reparaturlink kopieren',
                    companyLinkCopied: 'Link kopiert',
                },
                onboarding: {
                    title: 'Produkte',
                    action: 'Produkt anlegen',
                },
            },
            productDashboard: {
                backToOverview: 'Zurück zur Übersicht',
                configuration: {
                    gender: 'Geschlecht',
                    age: 'Alter',
                    laminate: 'Laminat',
                },
                keyFacts: {
                    pageViews: 'Seitenaufrufe',
                    problems: 'Probleme',
                    happySubmissionFeedback: 'Keine Anmerkung',
                    conversion: 'Conversion',
                },
                noDescription: 'Keine Beschreibung vorhanden',
                aiFilter: 'AI Filter',
                originFilter: 'Herkunft',
                detail: {
                    defectDescription: 'Defektbeschreibung',
                    contextDescription: 'Produktnutzung',
                    contact: 'Kontakt E-Mail für Rückfragen:',
                },
                noSubmissions: {
                    title: 'Noch keine Inspections',
                    subTitle:
                        'Sobald deine Nutzer:innen eine Inspection durchgeführt haben, werden sie hier angezeigt.',
                    copyInspectionLink: 'Inspector-Link kopieren',
                    copied: 'Link kopiert',
                },
            },
        },
        report: {
            title: 'Inspections',
        },
        userManagement: {
            users: {
                title: 'Users',
                action: 'Neuer User anlegen',
                userList: {
                    subtitle: 'Produkte: {{ productCount }} | Inspections: {{ inspectionCount }}',
                },
            },
        },
        support: {
            supportRequests: {
                title: 'Supportanfragen',
                kanban: {
                    open: 'Offen',
                    inProgress: 'In Bearbeitung',
                    done: 'Erledigt',
                },
                noSupportRequestsFallback: 'Du hast noch keine Supportanfragen. Gut gemacht!',
            },
        },
        companySettings: {
            account: {
                title: 'Account',
                newEmployeeForm: {
                    name: 'Name',
                    email: 'E-Mail',
                    button: 'Einladen',
                },
                logoForm: {
                    title: 'Firmenlogo hochladen',
                    helperText: 'Idealerweise ein Logo ohne Abstand zum Rand',
                    loading: 'Datei wird hochgeladen...',
                    success: 'Logo erfolgreich hochgeladen',
                    failed: 'Irgendetwas ist schiefgelaufen',
                },
            },
            settings: {
                title: 'Einstellungen',
                basicSettings: {
                    photoOrigin: {
                        title: 'Fotoherkunft',
                        lead: 'Sollen die User Fotos nur mit der Kamera aufnehmen oder auch aus der Galerie hochladen können?',
                        cameraOnly: 'Nur Kamera möglich',
                        cameraAndGallery: 'Kamera und Galerie möglich',
                    },
                    emailMandatory: {
                        title: 'E-Mail als Pflichfeld',
                        lead: 'Sollen die User ihre E-Mail-Adresse angeben müssen oder soll dies freiwillig sein?',
                        mandatory: 'E-Mail ist ein Pflichtfeld',
                        optional: 'E-Mail ist freiwillig',
                    },
                    emailNotifications: {
                        title: 'E-Mail Benachrichtigung',
                        lead: 'Willst du eine E-Mail erhalten, wenn eine neue Inspection abgegeben wurde?',
                        enabled: 'E-Mail Benarichtigungen eingeschalten',
                        disabled: 'E-Mail Benarichtigungen ausgeschalten',
                    },
                },
                customerSettingsOnlyWarning:
                    'Diese Einstellungen können nur in der Customer Version der Inspector App verwendet werden.',
                customerSettings: {
                    postInspectionRedirect: {
                        title: 'Weiterleitung nach Inspection',
                        lead: 'Soll der User nach einer Inspection auf eine spezifische Seite weitergeleitet werden?',
                        label: 'Weiterleitung nach Inspection',
                        restart: 'Startbildschirm',
                        companyPage: 'Unternehmensseite',
                        companyPageValidation: {
                            required: 'Unternehmensseite muss angegeben werden.',
                            invalid: 'Bitte eine gültige Unternehmensseite angeben.',
                        },
                    },
                    allowHappySubmissions: {
                        title: '"Keine Anmerkung" erlauben',
                        lead: 'Sollen die User die Möglichkeit haben, eine Inspection ohne Anmerkung zu speichern?',
                        enabled: '"Keine Anmerkung" möglich',
                        disabled: '"Keine Anmerkung" nicht möglich',
                    },
                    allowRemindMeLater: {
                        title: 'Später erinnern" erlauben',
                        lead: 'Sollen die User die Möglichkeit haben, sich per E-Mail an ihre Inspection erinnern zu lassen?',
                        enabled: '"Später erinnern" möglich',
                        disabled: '"Später erinnern" nicht möglich',
                    },
                    allowContactRequests: {
                        title: 'Kontaktanfragen erlauben',
                        lead: 'Sollen die User die Möglichkeit haben, Kontaktanfragen zu beantragen, damit du mit ihnen in Kontakt treten kannst?',
                        enabled: 'Kontaktanfragen möglich',
                        disabled: 'Kontaktanfragen nicht möglich',
                    },
                    showDashboardAnalytics: {
                        title: 'Analysedaten anzeigen',
                        lead: 'Willst du im Dashboard Analysedaten sehen?',
                        enabled: 'Analysedaten anzeigen',
                        disabled: 'Analysedaten nicht anzeigen',
                    },
                },
                save: 'Speichern',
                saving: 'Speichern...',
                success: 'Einstellungen gespeichert',
                error: 'Beim Speichern der Einstellungen ist ein Fehler aufgetreten',
            },
        },
    },
    publicCompany: {
        profile: {
            title: 'Product Inspection',
            lead: 'Danke, dass du uns Hilfst, unsere Produkte langlebiger zu machen. Wähle unten das zu inspizierende Produkt aus:',
            help: 'Hilfe',
        },
        introCarousel: {
            title: 'Intro zu Inspector',
            s1: {
                title: 'Produkt auswählen',
                lead: 'Wähle das Produkt aus, für das du eine Inspection durchführen möchtest.',
            },
            s2: {
                title: 'Bereich markieren',
                lead: 'Markiere den Bereich des Defektes oder des Verbesserungsvorschlags.',
            },
            s3: {
                title: 'Foto aufnehmen',
                lead: 'Nimm ein Foto von der Problemstelle auf',
            },
            s4: {
                title: 'Problem beschreiben',
                lead: 'Beschreibe den Defekt oder Verbesserungsvorschlag.',
            },
            s5: {
                title: 'Geschichte erzählen',
                lead: 'Erzähle uns die Geschichte deines Produktes und des Problems.',
            },
        },
        support: {
            title: 'Reparaturanfrage',
            lead: 'Wenn du ein Problem mit deinem Produkt hast, starte eine Reparaturanfrage, damit wir gemeinsam eine Lösung finden können.',
        },
    },
    inspector: {
        desktop: {
            title: 'Bitte öffne die INSPECTION auf deinem Handy',
            description: 'Scanne hierzu den QR-Code mit deinem Handy',
        },
        mobile: {
            navigation: {
                from: 'von',
                submit: 'Absenden',
            },
            intro: {
                title: 'Produkt Inspection',
                description:
                    'Wenn du einen Verbesserungsvorschlag oder Defekt an deinem Produkt hast, mach eine  Inspection.',
                action: 'Inspection starten',
                nothingToReport: {
                    action: 'Keine Anmerkungen',
                },
            },
            pinpoint: {
                title: 'Problemstelle markieren',
                description:
                    'Klicke auf die entsprechende Stelle im Bild, um einen <strong>Verbesserungsvorschlag</strong> oder <strong>Defekt</strong> zu markieren',
            },
            photo: {
                title: 'Problem Aufnehmen',
                description: 'Mach ein <strong>Foto</strong> von der eben markierten Stelle.',
                preview: 'Vorschau',
                action: 'Foto aufnehmen',
                reAction: 'Neues Foto aufnehmen',
                itemNotAvailable: 'Produkt gerade nicht zur Hand',
                cancelled: {
                    description:
                        'Du hast die Aufnahme abgebrochen. Wenn es daran lag, dass du Schwarz gesehen hast, liegt das möglicherweise an den Berechtigungen. Unter folgendem Link kannst du sehen, wie du diese anpassen kannst.',
                    showMe: 'Zeig mir wie',
                    dismiss: 'Schliessen',
                },
                remindMeLater: {
                    title: 'Inspection später durchführen',
                    lead: 'Du hast das Produkt gerade nicht zur Hand? Kein Problem! Wir erinnern dich später per E-Mail daran, eine Inspection durchzuführen.',
                    emailLabel: 'E-Mail',
                    datetimeLabel: 'Erinnerungszeitpunkt',
                    outro: 'Deine E-Mail wird ausschliesslich für diesen Zweck verwendet.',
                    submit: 'Erinnere mich später',
                    cancel: 'Abbrechen',
                },
            },
            description: {
                title: 'Problem beschreiben',
                description:
                    'Beschreibe deinen <strong>Defekt</strong> oder <strong>Verbesserungsvorschlag</strong>.',
                label: 'Beschreibung',
                placeholder: 'Problembeschrieb',
            },
            contextDescription: {
                title: 'Mehr Informationen',
                description:
                    'Beschreibe die <strong>Geschichte</strong> deines <strong>Produktes</strong> und <strong>Problems</strong>.',
                label: 'Beschreibung',
                placeholder: 'Produkt-/Problemgeschichte',
            },
            contact: {
                title: 'Kontakt angeben',
                description:
                    'Bitte hinterlasse uns deine E-Mail, damit wir dich für <strong>Rückfragen zu deiner Inspection</strong> kontaktieren können.',
                label: 'E-Mail',
                placeholder: 'E-Mail',
                requestContact: 'Bitte kontaktiert mich mit einer Lösung für mein Problem',
                photoUploadLabel: 'Foto-Upload',
            },
            outro: {
                title: 'Dankeschön!',
            },
        },
    },
    support: {
        desktop: {
            title: 'Bitte öffne die Reparaturanfrage auf deinem Handy',
            description: 'Scanne hierzu den QR-Code mit deinem Handy',
        },
        mobile: {
            navigation: {
                from: 'von',
                submit: 'Absenden',
            },
            intro: {
                title: 'Reparaturanfrage',
                description:
                    'Wenn du ein Problem mit deinem Produkt hast, starte eine Reparaturanfrage.',
                action: 'Anfrage starten',
                nothingToReport: {
                    action: 'Keine Anmerkungen',
                },
            },
            pinpoint: {
                title: 'Problemstelle markieren',
                description:
                    'Klicke auf die entsprechende Stelle im Bild, um einen <strong>Defekt</strong> zu markieren',
            },
            photo: {
                title: 'Problem Aufnehmen',
                description: 'Mach ein <strong>Foto</strong> von der eben markierten Stelle.',
                preview: 'Vorschau',
                action: 'Foto aufnehmen',
                reAction: 'Neues Foto aufnehmen',
                itemNotAvailable: 'Produkt gerade nicht zur Hand',
                cancelled: {
                    description:
                        'Du hast die Aufnahme abgebrochen. Wenn es daran lag, dass du Schwarz gesehen hast, liegt das möglicherweise an den Berechtigungen. Unter folgendem Link kannst du sehen, wie du diese anpassen kannst.',
                    showMe: 'Zeig mir wie',
                    dismiss: 'Schliessen',
                },
                remindMeLater: {
                    title: 'Inspection später durchführen',
                    lead: 'Du hast das Produkt gerade nicht zur Hand? Kein Problem! Wir erinnern dich später per E-Mail daran, eine Inspection durchzuführen.',
                    emailLabel: 'E-Mail',
                    datetimeLabel: 'Erinnerungszeitpunkt',
                    outro: 'Deine E-Mail wird ausschliesslich für diesen Zweck verwendet.',
                    submit: 'Erinnere mich später',
                    cancel: 'Abbrechen',
                },
            },
            description: {
                title: 'Problem beschreiben',
                description: 'Beschreibe den <strong>Defekt</strong> deines Produkts.',
                label: 'Beschreibung',
                placeholder: 'Problembeschrieb',
            },
            orderReference: {
                title: 'Auftragsreferenz eingeben',
                description:
                    'Gib deine <strong>Bestellnummer</strong> (online) oder deine <strong>Auftragsnummer</strong> (im Laden) ein.',
                label: 'Auftragsreferenz',
                placeholder: 'Bestellnummer / Auftragsnummer',
            },
            contact: {
                title: 'Kontakt angeben',
                description:
                    'Bitte hinterlasse uns deine E-Mail, damit wir dich für <strong>Rückfragen zu deiner Anfrage</strong> kontaktieren können.',
                hint: 'Verwende wenn möglich die E-Mail Adresse, die du beim Kauf angebeben hast.',
                label: 'E-Mail',
                placeholder: 'E-Mail',
                requestContact: 'Bitte kontaktiert mich mit einer Lösung für mein Problem',
                photoUploadLabel: 'Foto-Upload',
            },
            outro: {
                title: 'Dankeschön!',
            },
        },
    },
    serviceDesk: {
        hub: {
            title: 'Self-Service Hub',
            services: {
                repair: {
                    title: 'Reparaturauftrag erstellen',
                    subtitle: 'Lasse dein Produkt reparieren',
                },
                warranty: {
                    title: 'Garantiefall melden',
                    subtitle: 'Melde einen Garantiefall für dein Produkt',
                },
                alteration: {
                    title: 'Umrüstauftrag erstellen',
                    subtitle: 'Lasse dein Produkt umrüsten oder erweitern',
                },
            },
        },
        repair: {
            productStep: {
                title: 'Was möchtest du reparieren?',
                productNotFoundLabel: 'Produkt nicht gefunden?',
                productNotFoundAction: 'Ein anderes Produkt',
            },
            pinpointStep: {
                progressResetWarning:
                    'Wenn Sie zurückgehen, werden alle Ihre eingegebenen Daten zurückgesetzt',
                backToOverview: 'Zurück zum Start',
            },
            purchaseInformationStep: {
                title: 'Kaufinformationen',
            },
            solutionStep: {
                linktree: {
                    title: 'Reparaturoptionen',
                    diyTitle: 'Entdecke unsere DIY-Lösungen',
                    diySubtitle: 'Versuche, das Problem selbst mit unseren Anleitungen zu beheben.',
                    servicesTitle: 'Reparatur beauftragen',
                    servicesSubtitle:
                        'Gebe einen maximalen Betrag für die anstehende Reparatur frei.',
                    contactFormTitle: 'Fordere ein Reparaturangebot an',
                    contactFormSubtitle: 'Wir werden uns bei dir mit einem Angebot melden.',
                    storeFinderTitle: 'Servicehändler in deiner Nähe',
                    storeFinderSubtitle:
                        'Hol dir Unterstützung vor Ort von einem unserer Serviceexperten',
                },
                waitingForPhotoUpload: 'Das Foto wird noch hochgeladen...',
            },
            costEstimationStep: {
                title: 'Kostenschätzung',
                costRange: {
                    lead: 'Die Kosten können sich je nach Umfang der Reparatur und Zustand deines Produktes in folgendem Bereich bewegen:',
                    lead_other:
                        'Die Kosten können sich je nach Umfang der Reparatur und Zustand deines Produktes in folgendem Bereich bewegen:',
                },
                singleRepair: {
                    lead: 'Basierend auf deinen Angaben ergeben sich voraussichtlich folgende Reparaturkosten:',
                },
                costApproval: {
                    title: 'Kostenfreigabe',
                    lead: 'Bis zu welcher Reparatur möchtest du uns eine Preisfreigabe erteilen, damit dein Produkt schnellstmöglich bearbeitet wird?',
                },
                approvalCheckbox:
                    'Ich bin mit Reparaturkosten bis maximal <bold>{{ maxPrice }} €</bold> und den <repairTerms>Reparaturbestimmungen</repairTerms> einverstanden.',
                diyAction: 'Ich bevorzuge eine DIY-Lösung.',
                repairAction: 'Ich möchte meinen Artikel reparieren lassen',
                customerSupportAction: 'Ich will mit dem Kundenservice in Kontakt treten',
            },
            costApprovalStep: {
                title: 'Deine Reparatur',
                minCost: {
                    title: 'Mindestkostenfreigabe',
                    lead: 'Für eine reibungslose Reparatur benötigen wir deine Zustimmung zu einer <strong>Kostenfreigabe bis {{ minCost }} €</strong>. Bis zu diesem Betrag führen wir die Reparatur ohne Rückfrage aus. Bei höheren Kosten senden wir dir ein persönliches Angebot zu.',
                },
                includedServices: {
                    title: 'Reparaturen innerhalb der minimalen Kostenfreigabe:',
                    lead: 'Diese Reparaturen sind in der minimalen Kostenfreigabe enthalten und würden bei Bedarf ohne Rückfrage durchgeführt werden:',
                },
                excludedServices: {
                    title: 'Der schnellste Weg zur Reparatur',
                    lead: 'Du kannst die Kostenfreigabe erhöhen, damit wir die folgenden Reparaturen bei Bedarf ebenfalls direkt, ohne Rückfrage durchführen können:',
                },
                total: 'Deine Kostenfreigabe:',
                plusShipping: 'zuzüglich {{ shippingCosts }} € Versandkosten',
                help: {
                    title: 'Kostenfreigabe',
                    content:
                        'Eine Kostenfreigabe ermöglicht uns eine schnelle und reibungslose Durchführung der Reparatur ohne, dass wir nochmals mit dir Rücksprache halten müssen. Sollten die Kosten deine Kostenfreigabe übersteigen, melden wir uns mit einem neuen Angebot bei dir, bevor zusätzliche Kosten entstehen. Sollten die Kosten deine Kostenfreigabe unterschreiten, stellen wir dir nur die tatsächlichen Kosten in Rechnung.',
                },
            },
            requestRepairOfferStep: {
                title: 'Zusammenfassung',
                contactFields: {
                    title: 'Kontaktangaben',
                },
                submitAction: 'Reparatur-Angebot Anfragen',
            },
            submitRepairOrderStep: {
                title: 'Auftragszusammenfassung',
                repairCosts: {
                    title: 'Reparaturkosten',
                    costApproval: 'Kostenfreigabe',
                    shippingCosts: 'Versandkosten',
                    shippingCostsSubline: 'für Hin- und Rückversand',
                    totalCosts: 'Maximale Kosten',
                    totalCostsSubline:
                        '(für Reparaturen, welche ohne Rücksprache durchgeführt werden)',
                },
                submitAction: 'Reparatur kostenpflichtig beauftragen',
            },
            contactStep: {
                title: 'Kontaktangaben',
                formTitle: 'Persönliche Angaben',
                requestContactForm: {
                    submit: 'Reparatur-Angebot Anfragen',
                },
                submitOrderForm: {
                    submit: 'Reparaturauftrag abschicken',
                },
            },
            outroStep: {
                title: 'Dankeschön!',
                subtitle: 'Wir werden uns bald mit einem Angebot bei dir melden.',
                subtitle2: 'Bringen Sie den zu reparierenden Kite bei uns im Shop vorbei.',
                links: {
                    backToStore: 'Zurück zum Shop',
                },
            },
        },
        warranty: {
            productStep: {
                title: 'Für welches Produkt willst du einen Garantiefall melden?',
                productNotFoundLabel: 'Produkt nicht gefunden?',
                productNotFoundAction: 'Garantieanfrage für ein anderes Produkt stellen',
            },
            purchaseConfirmationStep: {
                linktree: {
                    title: 'Kaufbestätigung nachweisen',
                    photoTitle: 'Foto des Kaufbelegs hochladen',
                    photoSubtitle: 'Füge eine Foto des Kaufbelegs an',
                    manualTitle: 'Bestellnummer manuell eingeben',
                    manualSubtitle:
                        'Diese findest du in deiner Onlinebestellung oder deinem Kaufbeleg',
                    notAvailable: {
                        delimiter: 'Du hast keine Kaufbestätigung?',
                        button: 'Ohne Kaufbestätigung fortfahren',
                    },
                },
                photo: {
                    title: 'Foto des Kaufbelegs hochladen',
                },
                form: {
                    title: 'Kaufnachweis eingeben',
                    orderReferenceLabel: 'Bestellnummer',
                    articleNumberLabel: 'Artikelnummer',
                    articleNumberWhereToFindTitle: 'Wo finde ich die Artikelnummer?',
                },
            },
            purchaseInformationStep: {
                title: 'Kaufinformationen',
            },
            serialNumberStep: {
                title: 'Foto der Seriennummer hochladen',
            },
            proofOfPurchaseStep: {
                title: 'Foto des Kaufbelegs hochladen',
            },
            contactStep: {
                title: 'Kontaktangaben',
                form: {
                    title: 'Persönliche Angaben',
                    submit: 'Garantiefall melden',
                },
            },
            outroStep: {
                title: 'Dankeschön!',
                subtitle: 'Wir werden uns bald mit dir in Verbindung setzen.',
            },
            solutionStep: {
                waitingForPhotoUpload: 'Die Fotos werden noch hochgeladen...',
            },
            requestWarrantyStep: {
                title: 'Zusammenfassung',
                contactFields: {
                    title: 'Kontaktangaben',
                },
                submitAction: 'Garantiefall melden',
            },
        },
        alteration: {
            productStep: {
                title: 'Für welches Produkt willst du eine Umrüstung anfragen?',
                productNotFoundLabel: 'Produkt nicht gefunden?',
                productNotFoundAction: 'Umrüstungsanfrage für ein anderes Produkt stellen',
            },
            alterationSelectionStep: {
                title: 'Umrüstung(en) auswählen',
                total: 'Total',
            },
            contactStep: {
                title: 'Kontaktangaben',
                form: {
                    title: 'Persönliche Angaben',
                    submit: 'Umrüstung bestellen',
                },
            },
            submitAlterationOrderStep: {
                title: 'Zusammenfassung',
                alterationCosts: {
                    title: 'Umrüstungskosten',
                    totalCosts: 'Totale Kosten',
                },
                submitAction: 'Umrüstung bestellen',
            },
        },
        shared: {
            retailerValidationStep: {
                title: 'Retailer Code eingeben',
            },
            productStep: {
                form: {
                    productReferenceLabel: 'Produktreferenz eingeben',
                },
                categories: {
                    all: 'Alle',
                    apparel: 'Bekleidung',
                    upperwear: 'Oberteile',
                    tshirts: 'T-Shirts',
                    tshirt: 'T-Shirt',
                    pullovers: 'Pullover',
                    pullover: 'Pullover',
                    hoodie: 'Hoodie',
                    longsleeve: 'Langarm Shirt',
                    tanktop: 'Tanktop',
                    top: 'Top',
                    jackets: 'Jacken',
                    jacket: 'Jacke',
                    bikini: 'Bikini',
                    pants: 'Hosen',
                    pant: 'Hose',
                    shorts: 'Shorts',
                    leggings: 'Leggings',
                    skiingPants: 'Skihosen',
                    skiingPant: 'Skihose',
                    accessories: 'Accessoires',
                    beanie: 'Mütze',
                    gloves: 'Handschuhe',
                    shoes: 'Schuhe',
                    hikingShoes: 'Wanderschuh',
                    snowBoots: 'Schneestiefel',
                    sportShoes: 'Sportschuh',
                    sneakers: 'Sneaker',
                    flipflop: 'Flip Flop',
                    hardware: 'Hardware',
                    camping: 'Camping',
                    tents: 'Zelte',
                    tent: 'Zelt',
                    campingChairs: 'Campingstühle',
                    campingChair: 'Campingstuhl',
                    mats: 'Matten und Matratzen',
                    mat: 'Matte oder Matratze',
                    sleepingBags: 'Schlafsäcke',
                    sleepingBag: 'Schlafsack',
                    hiking: 'Wandern',
                    backpacks: 'Rucksäcke',
                    backpack: 'Rucksack',
                    hikingPoles: 'Wanderstöcke',
                    hikingPole: 'Wanderstock',
                    watersport: 'Wassersport',
                    sup: 'SUP',
                    penguin: {
                        menShellPants: 'Männer Shell Hose',
                        womenShellPants: 'Frauen Shell Hose',
                        menShellJacket: 'Männer Shell Jacke',
                        womenShellJacket: 'Frauen Shell Jacke',
                    },
                    bags: 'Taschen',
                    shoulderBag: 'Schultertasche',
                    messengerBag: 'Messenger-Tasche',
                    laptopBag: 'Laptop-Tasche',
                    toteBag: 'Tasche',
                    freitagTravelBag: 'Reise- & Sporttasche',
                    victorinox: {
                        toolsAndKnives: 'Messer & Tools',
                        watch: 'Uhren',
                        pocketKnife: 'Taschenmesser',
                        multiTool: 'Swiss Tools',
                        luggage: 'Reisegepäck',
                        knife: 'Küchenmesser',
                        card: 'Swiss Cards',
                    },
                    ortlieb: {
                        bikeBag: 'Fahrradtasche',
                        travelBag: 'Reise- & Expeditionstasche',
                        backpack: 'Rucksack',
                        luggageRack: 'Gepäckträger',
                        handlebarBag: 'Lenker-Tasche',
                        frameBag: 'Rahmen-Tasche',
                        saddleBag: 'Satteltasche',
                        bikeBagBackFront: 'Radtasche hinten / vorne',
                        luggageRackBag: 'Gepäckträgertasche',
                        bagWithRollClosure: 'mit Rollverschluss',
                        bagWithLid: 'mit Deckel',
                        bagWithQuickSeal: 'mit Quickseal',
                        bagWithVelcroClosure: 'mit Klettverschluss',
                        bagWithHookClosure: 'mit Hakenverschluss',
                        bagWithBuckleClosure: 'mit Schnallenverschluss',
                        bagWithMagneticClosure: 'mit Magnetverschluss',
                        bagWithZipClosure: 'mit Reißverschluss',
                        seatlock: 'mit Seatlock',
                        officeBags: 'Office Bags',
                        trunkBag: 'Trunkbag',
                        trunkBagRc: 'Trunkbag RC',
                        rackPack: 'Rackpack',
                        ultimate: 'Ultimate',
                        barista: 'Barista',
                        uptown: 'Uptown',
                        handlebarPackQR: 'Handlebar Pack QR',
                        handlebarPack: 'Handlebar Pack',
                        accessoryPack: 'Accessory Pack',
                        eGlow: 'E-Glow',
                        framepackToptube: 'Framepack Toptube',
                        framepack: 'Framepack',
                        framepackRc: 'Framepack RC',
                        framepackToptubeRc: 'Framepack Toptube RC',
                        duffles: 'Duffles',
                        bagWithRolls: 'Tasche mit Rollen',
                        bagWithTelescopePole: 'Tasche mit Teleskopstange',
                        bagWithoutTelescopePole: 'Tasche ohne Teleskopstange',
                        packsack: 'Packsack',
                        vario: 'Vario',
                        quickRack: 'Quick Rack',
                        rackThree: 'Rack Three',
                        others: 'Andere',
                        motoBag: 'Motorradtasche',
                    },
                    duotone: {
                        kite: 'Kite',
                        boards: 'Boards',
                        kiteBar: 'Kite Bar',
                    },
                    zenitram: {
                        kite: 'Kite',
                        bar: 'Bar',
                        wing: 'Wing',
                        kiteBar: 'Kite Bar',
                        kiteWing: 'Kite Wing',
                    },
                },
            },
            pinpointStep: {
                title: 'Klicke auf die Problemstelle',
                views: {
                    front: 'Vorne',
                    frontOpen: 'Offen',
                    back: 'Hinten',
                    backA: 'Hinten A',
                    backB: 'Hinten B',
                    side: 'Seite',
                    bottom: 'Unten',
                    top: 'Oben',
                    inside: 'Innen',
                },
            },
            photoStep: {
                title: 'Foto des Problems hochladen',
                mobilePhotoInput: {
                    label: 'Foto aufnehmen',
                    description: 'Wähle vorzugsweise hochauflösende, klare Fotos des Problems',
                    takePhotoButton: 'Foto aufnehmen',
                    uploadPhotoButton: 'Foto hochladen',
                },
                photoDragDrop: {
                    label: 'Foto vom Computer hochladen',
                    description: 'Ein Hochauflösendes, klares Foto erleichtert uns die Verabeitung',
                    dropLabel: 'Platziere das Foto hier',
                    button: 'Foto auswählen',
                },
                qrPhotoUpload: {
                    title: 'Foto vom Handy hochladen oder aufnehmen',
                    text: 'Dazu einfach den QR-Code scannen und ein Foto hochladen',
                },
            },
            descriptionStep: {
                title: 'Problem beschreiben',
                label: 'Problembeschreibung',
                placeholder: '',
            },
            repairTypeStep: {
                title: 'Art der Reparatur',
                invisible: 'Unsichtbar',
                visible: 'Sichtbar',
                funky: 'Funky',
            },
            diyStep: {
                title: 'Reparaturanleitungen',
                nothingFoundSubtitle:
                    'Leider konnten wir keine passende Reparaturanleitung finden.',
                guideBy_one: 'Anleitung von',
                guideBy_other: 'Anleitungen von',
                checkoutGuideAction: 'Anleitung ansehen',
                notMatching: 'Keine passende Anleitung dabei?',
                checkoutRepairOfferAction: 'Stattdessen reparieren lassen',
            },
            contactStep: {
                form: {
                    title: 'Persönliche Angaben',
                    nameLabel: 'Name',
                    firstnameLabel: 'Vorname',
                    lastnameLabel: 'Nachname',
                    emailLabel: 'E-Mail',
                    phoneLabel: 'Telefon',
                    maxRepairCostLabel: 'Maximale Reparaturkosten',
                    addressLabel: 'Adresse',
                    zipCodeLabel: 'PLZ',
                    cityLabel: 'Ort',
                    countryLabel: 'Land',
                    commentLabel: 'Anmerkung',
                    purchaseLocationLabel: 'Kaufort',
                    purchaseDateLabel: 'Kaufdatum',
                    orderReferenceLabel: 'Bestellreferenz',
                    acceptTermsLabel:
                        'Ich bin mit einer Reparatur bis zur angegebenen Preisobergrenze einverstanden',
                    addMoreDefects: 'Weitere Reparatur hinzufügen',
                    submit: 'Reparatur-Angebot Anfragen',
                },
            },
            forms: {
                nameLabel: 'Name',
                firstnameLabel: 'Vorname',
                lastnameLabel: 'Nachname',
                emailLabel: 'E-Mail',
                phoneLabel: 'Telefon',
                maxRepairCostLabel: 'Maximale Reparaturkosten',
                addressLabel: 'Adresse',
                zipCodeLabel: 'PLZ',
                cityLabel: 'Ort',
                streetNoLabel: 'Straße und Hausnummer',
                zipAndCityLabel: 'PLZ und Ort',
                countryLabel: 'Land',
                commentLabel: 'Anmerkung',
                purchaseLocationLabel: 'Kaufort',
                purchaseDateLabel: 'Kaufdatum',
                orderReferenceLabel: 'Bestellreferenz',
                articleNumberLabel: 'Artikelnummer',
                serialnumberLabel: 'Seriennummer',
                serialNumberWhereToFindTitle: 'Wo finde ich die Seriennummer?',
                uploadFromComputer: 'Foto vom Computer hochladen',
                uploadFromPhone: 'Scannen um Foto mit dem Handy aufzunehmen',
                productNameLabel: 'Produktname',
                productSizeLabel: 'Produktgröße',
                productColorLabel: 'Produktfarbe',
                replacementProductLabel: 'Ersatzprodukt (optional)',
                validation: {
                    email: 'Bitte gib eine gültige E-Mail-Adresse ein',
                    required: 'Dieses Feld darf nicht leer sein',
                    zipCode: 'Ungültige PLZ',
                    retailerCode: 'Ungültiger Retailer Code',
                },
                selectPlaceholder: 'Bitte auswählen',
                retailerCode: 'Retailer Code',
                processNumber: 'Vorgangsnummer',
            },
        },
        mobilePhotoUpload: {
            retakeAction: 'Foto erneut aufnehmen',
        },
        ufixit: {
            intro: {
                toolsNeededLink: 'Werkzeuge, die für diesen Leitfaden benötigt werden',
                toolsNeededTitle: 'Benötigte Werkzeuge',
                sparePartsNeededLink: 'Ersatzteile, die für diesen Leitfaden benötigt werden',
                sparePartsNeededTitle: 'Benötigte Ersatzteile',
                startAction: 'Reparatur starten',
            },
            common: {
                backToRequest: 'Zurück zu Ihrer Anfrage',
                step: 'Schritt',
            },
            outro: {
                enjoy: 'Viel Spaß mit Ihrem neu reparierten Produkt!',
                didYouManage: 'Haben Sie Ihr Produkt repariert?',
                furtherAssistance: 'Nein, ich brauche weitere Hilfe',
                yesIAmHappy: 'Ja, ich bin zufrieden!',
                findNearestStore: 'Finde deinen nächsten Laden',
            },
        },
        brands: {
            ortlieb: {
                repair: {
                    replacementProductHelperText:
                        'Sollte eine Reparatur Deines Produkts nicht möglich sein, so bieten wir Dir an, das Produkt fachgerecht zu entsorgen und Dir ein neues ORTLIEB-Produkt mit einem 25%-Nachlass zu senden. Falls Du dieses Kulanzangebot nutzen möchtest, kannst du hier das gewünschte Ersatzprodukt angeben.',
                    submitRepairOrder: {
                        submissionInfoTexts: {
                            termsOfService:
                                'Durch Absenden des Auftrags stimmst du den <externalLink href="https://www.ortlieb.com/de_de/agb">allgemeinen Geschäftsbedingungen</externalLink>, den <dialogLink data-key="repairTerms">die Reparaturbedingungen</dialogLink> und den <externalLink href="https://www.ortlieb.com/de_de/datenschutz">die Datenschutzerklärung</externalLink> zu.',
                        },
                        checkboxes: {
                            cancellationPeriodLabel:
                                'Ich verlange ausdrücklich und stimme gleichzeitig zu, dass die ORTLIEB Sportartikel GmbH mit der in Auftrag gegebenen Dienstleistung vor Ablauf der gesetzlichen Widerrufsfrist beginnt. Ich weiß, dass mein Widerrufsrecht bei vollständiger Erfüllung des Vertrages erlischt.',
                        },
                        dialogs: {
                            repairTerms: {
                                title: 'Reparaturbestimmungen',
                                content: `<list>
                                <item>Die Übersendung eines Produktes an uns ist der Auftrag zur Durchführung der von uns für notwendig erachteten Reparaturen, soweit sich aus einer Erklärung des Übersenders nichts anderes ergibt.</item>
                                <item>Bitte beachte, dass wir deine Einsendung ohne ausreichende Fehlerbeschreibung leider nicht bearbeiten können.</item>
                                <item>Sollten wir von dir innerhalb von 2 Wochen nach Versand des Angebots keine Rückmeldung erhalten, sind wir berechtigt, das Produkt gegen Berechnung der Handlingpauschale von 5,00 Euro zzgl. der Versandpauschalen für Hin- und Rückversand in Höhe von jeweils 5,00 Euro an dich zurück zu senden.</item>
                                <item>Reparaturaufträge älterer Produkte werden nur unter Vorbehalt angenommen.</item>
                                <item>Im Falle einer Beschädigung deines Produktes während einer von uns durchgeführten Reparatur tauschen wir dir dieses selbstverständlich gegen ein im Bestand geführtes Produkt aus.</item>
                                <item>Bitte beachte, dass es bei hohen Aufkommen von Reparaturen auch zu einer längeren Bearbeitungsdauer deines Auftrages kommen kann. Wir bitten um dein Verständnis!</item>
                                <item>Alle Produkte die zur Reparatur eingeschickt werden, müssen im gesäuberten Zustand eingeschickt werden, da nur an sauberen Produkten eine optimale Reparatur durchgeführt werden kann. Bei verschmutzten Produkten müssen wir eine Reinigungspauschale in Höhe von 5,00 Euro berechnen (hierbei wird jedoch nur die zu reparierende Stelle gereinigt).</item>
                                <item>Um eine Übersicht über mögliche Kosten zu erhalten, schaue einfach in unsere Reparatur Preisliste. Hier findest du eine Übersicht über die häufigsten Reparaturen und deren Preise.</item>
                                <item>Die ORTLIEB Sportartikel GmbH beginnt mit der in Auftrag gegebenen Dienstleistung vor Ablauf der gesetzlichen Widerrufsfrist, sofern der Kunde dies ausdrücklich verlangt. Das Widerrufsrecht erlischt bei vollständiger Erfüllung des Vertrages.</item>
                                <item>Reparaturen bis zu einem Betrag von maximal {{ form.costApproval }} € werden ohne vorherige Rücksprache durchgeführt. Übersteigt der geschätzte Aufwand diesen Betrag, wird ein individuelles Angebot unterbreitet. Bei Ablehnung dieses Angebots wird lediglich eine Handlingpauschale in Höhe von € 5,- in Rechnung gestellt.</item>
                                <item>Es wird eine zuzügliche Versandpauschale von 5,00 Euro für Hin- und Rückversand sowie eine ggf. anfallende Reinigungspauschale von 5,00 Euro bei verschmutzten Produkten akzeptiert.</item>
                                </list>`,
                            },
                        },
                    },
                },
                warranty: {
                    replacementProductHelperText:
                        'Sollte die Reparatur Deines Produkts nicht möglich sein, so erhältst Du bei Anerkennung der 5-jährigen ORTLIEB-Garantie ein neues Produkt. Für den Fall, dass wir dieses nicht mehr im Sortiment oder auf Lager haben, kannst Du hier bereits angeben, welches gleichwertige Ersatzprodukt Dir stattdessen zugesendet werden soll. Machst Du hier keine Angaben, kontaktieren wir Dich.',
                },
                shared: {
                    outroStep: {
                        subtitle:
                            'Du hast eine E-Mail mit den Versandetiketten erhalten. Bitte sende uns dein Produkt damit zurück.',
                        links: {
                            backToStore: 'Zurück zum ORTLIEB Onlineshop',
                        },
                    },
                },
            },
            duotone: {
                warranty: {
                    requestWarrantyClaim: {
                        checkboxes: {
                            privacyPolicyLabel:
                                'Ich stimme den <externalLink href="https://www.duotonesports.com/de/privacy-policy">Datenschutzbestimmungen</externalLink> zu',
                        },
                    },
                },
            },
        },
    },
    analysator: {
        keyfacts: {
            pageViews: 'Seitenaufrufe',
            uniqueVisitors: 'Einzelne Besucher',
            submissions: 'Anfragen',
        },
    },
}

export type TranslationsT = typeof de

export default de
