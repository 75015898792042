import { styled, alpha } from '@mui/material'

const DropZone = styled('div')<{ $dragActive?: boolean }>`
    padding: ${({ theme }) => theme.spacing(8)};
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.textColor ?? theme.palette.primary.main};
    text-align: center;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;

    &:hover {
        background: ${({ theme }) => alpha(theme.palette.primary.main, 0.025)};
    }

    &::before {
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        border: 1px dashed ${({ theme }) => theme.palette.primary.main};
        border-radius: 8px;
        z-index: -1;
    }

    ${({ $dragActive, theme }) =>
        $dragActive &&
        `
        background: ${alpha(theme.palette.primary.main, 0.1)};
    `}
`

export default DropZone
